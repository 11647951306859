import React from 'react'
import StockComponent from '../Components/Stock/StockComponent'

function StockPage() {
  return (
   
    <> <StockComponent/></>
  )
}

export default StockPage