import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { VStack, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import * as CategoryService from '../../Services/Category/CategoryService'
import * as SubCategoryService from '../../Services/SubCategory/SubCategoryService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import { FiTag } from "react-icons/fi";
import ModalComponent from '../Modal/ModalComponent';
import { Tooltip } from '@chakra-ui/react'

function CategoryComponent() {
    const { handleSubmit: handleCategorySubmit, register: registerCategory, setValue: setValueCategory, reset: resetCategory, formState: { errors: errorCategory } } = useForm({ mode: "onBlur" });
    const { handleSubmit: handleSubCategorySubmit, register: registerSubCategory, setValue: setValueSubCategory, reset: resetSubCategory, formState: { errors: errorSubCategory } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isSubCategoryOpen, onOpen: onSubCategoryOpen, onClose: onSubCategoryClose } = useDisclosure();
    const [CategoryList, setCategory] = useState([]);
    const [SubCategoryList, setSubCategory] = useState([]);
    const [CategoryDetail, setCategoryDetail] = useState({});
    const [IsLoading] = useLoaderState();
    const [SelectedCategory, setCategoryId] = useState(0);

    useEffect(() => {
        LoadCategory();
    }, [])

    const LoadCategory = () => {
        CategoryService.GetCategoryList().then(data => {
            if (data.data.IsSuccess) {
                setCategory(data.data.Data);
            } else {
                //toast.error("Somthing went wrong.");
            }
        }).catch(error => {
            toast.error("Somthing went wrong.");
        })
    }

    const AddCategory = () => {
        resetCategory({});
        setCategoryId(0);
        onAddOpen();
    }

    const SaveCategory = (value) => {
        if (SelectedCategory > 0) {
            CategoryService.UpdateCategory(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    resetCategory({});
                    LoadCategory();
                } else {
                    toast.error("Somthing went wrong.")
                }
            }).catch(error => {
                toast.error("Somthing went wrong.")
            })
        } else {
            CategoryService.SaveCategory(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    resetCategory({});
                    LoadCategory();
                } else {
                    toast.error("Somthing went wrong.")
                }
            }).catch(error => {
                toast.error("Somthing went wrong.")
            })
        }
    }

    const saveSubCategory = (value) => {
        debugger;
        value.Category_Id = SelectedCategory;
        SubCategoryService.SaveSubCategory(value).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Success");
                resetSubCategory();
                ViewSubCategory(SelectedCategory);
            } else {
                toast.error("Somthing went wrong.")
            }
        }).catch(error => {
            toast.error("Somthing went wrong.")
        })
    }

    const ViewSubCategory = (id) => {
        setSubCategory([]);             
        setCategoryId(id);
        SubCategoryService.GetSubCategoryListByCategoryId(id).then(data => {
            onSubCategoryOpen();
            if (data.data.IsSuccess) {   
                setSubCategory(data.data.Data);
            }
        }).catch(error => {

        })
    }

    const EditCategory = (id) => {
        setCategoryId(id);
        CategoryService.GetCategoryById(id).then(data => {
            if (data.data.IsSuccess) {
                resetCategory(data.data.Data);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong.")
            }
        }).catch(error => {
            toast.error("Somthing went wrong.")
        })
    }

    const DeleteSubCategory = (id) => {
        SubCategoryService.DeleteSubCategoryById(id).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Success");
                ViewSubCategory(SelectedCategory);
            } else {
                toast.error("Somthing went wrong.")
            }
        }).catch(error => {
            toast.error("Somthing went wrong.")
        })
    }

    const DeleteCategory = (id, isActive) => {
        CategoryService.ActiveDeActiveCategoryDetail(id, isActive).then(data => {
            if (data.data.IsSuccess) {
                LoadCategory();
                toast.success("Success");
            } else {
                toast.error("Somthing went wrong.")
            }
        }).catch(error => {
            toast.error("Somthing went wrong.")
        })
    }

    return (
        <ContainerComponent Title="Category" Icon={<FiTag />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Category" AddNewAction={AddCategory}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Category Id</Th>
                            <Th>Category Name</Th>
                            <Th>SEO Category Name</Th>
                            <Th>Is Active</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {CategoryList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>CAT-{data.Id}</Td>
                                <Td>{data.Category_Name}</Td>
                                <Td>{data.Product_SEOCategory}</Td>
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                    
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Category Mapping' placement='auto-start'>
                                        <Button colorScheme='whatsapp' variant='solid' size={"xs"} onClick={() => ViewSubCategory(data.Id)}>
                                            <HamburgerIcon />
                                        </Button>
                                        </Tooltip>
                                        <Tooltip label='Edit Category' placement='auto-start'>
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditCategory(data.Id)}>
                                            <EditIcon />
                                        </Button>                                    
                                        </Tooltip>
                                        <Tooltip label='Edit Category' placement='auto-start'>
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => DeleteCategory(data.Id,data.Is_Active)}>
                                            <DeleteIcon />
                                        </Button>                                    
                                        </Tooltip>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {CategoryList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
            <ModalComponent Title="Add/Update Category" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleCategorySubmit(SaveCategory)}>
                <FormControl isRequired isInvalid={errorCategory.Category_Name}>
                    <FormLabel>Category Name</FormLabel>
                    <Input placeholder='Category Name' {...registerCategory("Category_Name", { required: "Category Name is Mandatory" })} />
                    <FormErrorMessage>
                        {errorCategory.Category_Name && errorCategory.Category_Name.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errorCategory.Product_SEOCategory}>
                    <FormLabel>SEO Category Name</FormLabel>
                    <Input placeholder='SEO Category Name' {...registerCategory("Product_SEOCategory", { required: "SEO Category Name is Mandatory" })} />
                    <FormErrorMessage>
                        {errorCategory.Product_SEOCategory && errorCategory.Product_SEOCategory.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errorCategory.Is_Active}>
                    <FormLabel>Is Active</FormLabel>
                        <Checkbox colorScheme='green' defaultChecked {...registerCategory("Is_Active", { required: "Selection is mandatory" })}>
                    </Checkbox>
                    <FormErrorMessage>
                        {errorCategory.Is_Active && errorCategory.Is_Active.message}
                    </FormErrorMessage>
                </FormControl>
            </ModalComponent>

            <ModalComponent Title="Sub Category" size={"xl"} isOpen={isSubCategoryOpen} onOpen={onSubCategoryOpen} onClose={onSubCategoryClose}>
                <FormControl isRequired isInvalid={errorSubCategory.SubCategory_Name}>
                    <FormLabel>Category Name</FormLabel>
                    <Input placeholder='Sub Category Name' {...registerSubCategory("SubCategory_Name", { required: "Sub Category Name is Mandatory" })} />
                    <FormErrorMessage>
                        {errorSubCategory.SubCategory_Name && errorSubCategory.SubCategory_Name.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errorSubCategory.Is_Active}>
                    <FormLabel>Is Active</FormLabel>
                    <Checkbox colorScheme='green' defaultChecked {...registerSubCategory("Is_Active", { required: "Selection is mandatory" })}>
                    </Checkbox>
                    <FormErrorMessage>
                        {errorSubCategory.Is_Active && errorSubCategory.Is_Active.message}
                    </FormErrorMessage>
                </FormControl>
                <Divider />
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center">
                    <ButtonGroup variant='outline' spacing='4' alignContent={"center"} paddingTop="2" paddingBottom="2">
                        <Button type='submit' onClick={handleSubCategorySubmit(saveSubCategory)} colorScheme="whatsapp">Save</Button>
                    </ButtonGroup>
                </Box>
                <Divider />
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Category Id</Th>

                                <Th>Category Name</Th>

                                <Th>Is Active</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {SubCategoryList.map((data, index) => {
                                return (<Tr key={index}>
                                    <Td>SCAT-{data.Id}</Td>
                                    <Td>{data.SubCategory_Name}</Td>
                                    <Td>
                                        <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                        </Checkbox>
                                    </Td>
                                    <Td>
                                        <ButtonGroup variant={"outline"} spacing="1">
                                            <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteSubCategory(data.Id)}>
                                                <DeleteIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </Td>
                                </Tr>)
                            })}
                        </Tbody>
                        {CategoryList.length <= 0 ? (
                            <Tfoot>
                                <Tr colSpan="3">
                                    <Td>
                                        No Data Available
                                    </Td>
                                </Tr>
                            </Tfoot>
                        ) : <></>
                        }

                    </Table>
                </TableContainer>
            </ModalComponent>
        </ContainerComponent>
    )
}

export default CategoryComponent