import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import * as LoginService from '../../Services/Login/LoginService'
import { FaCopyright, FaGoogle } from "react-icons/fa";
import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
    HStack,
} from '@chakra-ui/react';
import { thunk_set_user } from '../../Action/action'
import useLoaderState from '../../Common/useLoaderState';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { AlignJustifyIcon } from 'evergreen-ui';

function LoginComponent(props) {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    const [IsLoading] = useLoaderState();
    const history = useNavigate();
    const [ShowIsOTP, setShowIsOTP] = useState(false);
    const [MobileNo, setMobileNo] = useState("");
    const userotp = useRef();
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        let interval;
        if (ShowIsOTP && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 0) {
                        clearInterval(interval);
                        return 60; // Reset timer after 1 minute
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        } else {
            clearInterval(interval); // Clear the interval if OTP is not sent or component unmounts
        }

        return () => clearInterval(interval); // Cleanup on unmount
    }, [ShowIsOTP, timer]);

    const Load = () => {
        LoginService.GetGoogleAuthLoginURL().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                // window.location.href(data.data.Data);
                window.open(data.data.Data,"_self");
                // redirect(data.data.Data);
                // history.push(data.data.Data);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const ValidateOTP = () => {
        debugger;
        LoginService.ValidateUserOTP(MobileNo, userotp.current.value).then(data => {
            if (data.data.IsSuccess) {
                //debugger;
                props.dispatch(thunk_set_user(data.data.Data));
                toast.success("Login Success");
                setTimeout(() => {
                    history('/dashboard');
                }, 1000);

            } else {
                toast.error("Invalid User");
            }
        }).catch(error => {
            toast.error("Somthing went wrong. Please try again.");
        })
    }

    const ResendOTP = () => {
        debugger;
        LoginService.ResendOTP(MobileNo).then(data => {
            if (data.data.IsSuccess) {
                //debugger;
                setTimer(60);
            } else {
                toast.error("Invalid User");
            }
        }).catch(error => {
            toast.error("Somthing went wrong. Please try again.");
        })
    }

    const formSubmit = (value) => {
        debugger;
        // LoginService.GetIPAddress().then(data=>{
        //     console.log(data)
        // })
        LoginService.ValidateUser(value.UserName, value.Password).then(data => {
            if (data.data.IsSuccess) {
                setMobileNo(data.data.Data.User.Mobile_No);
                if (data.data.Data.User.Is_OTP_Enable) {
                    toast.success("OTP sent on "+ data.data.Data.User.Mobile_No);
                    setShowIsOTP(data.data.Data.User.Is_OTP_Enable);

                }
                else {
                    props.dispatch(thunk_set_user(data.data.Data));
                    toast.success("Login Success");
                    setTimeout(() => {
                        history('/dashboard');
                    }, 1000);
                }
            } else {
                toast.error("Invalid User");
            }
        }).catch(error => {
            toast.error("Somthing went wrong. Please try again.");
        })
    }

    return (
        <Stack
            bg={'gray.50'}
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: 'lg' }}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Stack spacing={4}>
                <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                    Product Admin Login
                    <Text
                        as={'span'}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        bgClip="text">
                        !
                    </Text>
                </Heading>
                <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                   Manage HiCare Products and Product Inventory
                </Text>
            </Stack>

            <Box as={'form'}>
                {/* <Stack spacing={4}>
                    <Input
                        placeholder="User Name"
                        readOnly={ShowIsOTP}
                        bg={'gray.100'}
                        border={0}
                        color={'gray.500'}
                        _placeholder={{
                            color: 'gray.500',
                        }}
                        {...register("UserName")}
                    />
                    {errors.UserName && <p style={{ color: 'red' }}>User Name is required</p>}
                    <Input
                        type="password"
                        placeholder="Password"
                        readOnly={ShowIsOTP}
                        bg={'gray.100'}
                        border={0}
                        color={'gray.500'}
                        _placeholder={{
                            color: 'gray.500',
                        }}
                        {...register("Password")}
                    />
                    {errors.Password && <p style={{ color: 'red' }}>Password is required</p>}
                </Stack>
                {ShowIsOTP ?
                    (<Stack>
                        <br />
                        <Input placeholder='Enter OTP' ref={userotp} />
                        {timer == 0 ? <></> : <p>Resend OTP in : {timer}</p>}
                        {timer == 0 ? <Button
                            fontFamily={'heading'}
                            mt={4}
                            w={"100%"}
                            bgGradient="linear(to-r, red.400,pink.400)"
                            color={'white'}
                            _hover={{
                                bgGradient: 'linear(to-r, red.400,pink.400)',
                                boxShadow: 'xl',
                            }}
                            onClick={() => ResendOTP()}>
                            Resend OTP
                        </Button>
                            : <Button
                                fontFamily={'heading'}
                                mt={4}
                                w={"100%"}
                                bgGradient="linear(to-r, red.400,pink.400)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400,pink.400)',
                                    boxShadow: 'xl',
                                }} onClick={() => ValidateOTP()}>
                                Validate OTP
                            </Button>}
                    </Stack>) : <Button
                        fontFamily={'heading'}
                        mt={8}
                        w={'full'}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, red.400,pink.400)',
                            boxShadow: 'xl',
                        }} onClick={handleSubmit(formSubmit)}>
                        Get OTP
                    </Button>
                } */}
                
                {/* <div style={{textAlign:'center',paddingTop:'20px'}}><span>-------------------------- OR --------------------------</span></div> */}
                <Button fontFamily={'heading'} mt={4} w={"100%"}  className="googleloginbtn"
                                onClick={() => Load()}>
                                <img src="https://static.xx.fbcdn.net/rsrc.php/yv/r/HwisNeTP7Qh.svg" alt=""  width="25px" height="25px" /> &nbsp; Log in with Google
                            </Button>
            </Box>

        </Stack>
    )
}

const matchStateToPros = state => {
    return {
        data: state
    }
}
export default connect(matchStateToPros)(LoginComponent);
