import axios from "axios";
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetOrderListByUserId = async (status, date, endDate) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `SalesOrder/GetOrderListByUserId?orderStatus=${status}&orderDate=${date}&endDate=${endDate}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const CheckPinCodeAvailablity = async (pincode) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `SalesOrder/ValidatePincode?pincode=${pincode}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetDeliveryCharges = async (pincode, productweight) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `SalesOrder/GetDeliveryCharges?pincode=${pincode}&productweight=${parseFloat(productweight)}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveSalesOrder = async (value) => {
    debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'SalesOrder/SaveSalesOrder', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const SendPaymentLink = async (value) => {
    debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Razorpay/CreatePaymentLink', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const CancelAndCreatePaymentLinkAsync = async (value) => {
    debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Razorpay/CancelAndCreatePaymentLink', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ReSendPaymentLink = async (paypaymentId) => {
    debugger;
    const response = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Razorpay/ResendPaymentLink?paymentLinkId=${paypaymentId}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const CheckPaymentStatus = async (paypaymentId) => {
    debugger;
    const response = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Razorpay/CheckPaymentLinkStatus?paymentLinkId=${paypaymentId}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdatePaymentDetail = async (value) => {
    debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'SalesOrder/UpdatePaymentDetail', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetActiveBookingSourceList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + 'SalesOrder/GetActiveBookingSourceList', CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetActiveTechnicianMappedinSC = async (scusername) => {
    debugger;
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `SalesOrder/GetActiveTechnicianMappedinSC?scusername=${scusername}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetActiveOrderBookingSourceList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + 'SalesOrder/GetActiveOrderBookingSourceList', CommonVariables.API_HEADER)).then(response => response);
    return result;
}

// export const ApplyVoucherByVoucherCode = async (voucherCode) => {
//     debugger;
//     var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `SalesOrder/ApplyVoucherByVoucherCode?voucherCode=${voucherCode}`, CommonVariables.API_HEADER)).then(response => response);
//     return result;
// }

export const ApplyVoucherByVoucherCode = async (salesOrderModel) => {
    debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'SalesOrder/ApplyVoucherByVoucherCode', JSON.stringify(salesOrderModel), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const DownloadOrderUploaderFileFormat = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + 'SalesOrder/DownloadProuctOrderExcelFormat', CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UploadProductOrder = async (file) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'SalesOrder/UploadProductOrder', file, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const DownloadOrderInvoiceUploaderFile = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + 'ProductOrder/DownloadInvoiceUploaderExcelFormat', CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UploadProductOrderInvoice = async (file) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductOrder/InvoiceUploader', file, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetAddressByMobileNumber = async (mobileNumber,pincode) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetAddressByMobileNumber?mobileNumber=${mobileNumber}&pincode=${pincode}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UploadProductDelieveryUploader = async (file) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductOrder/DelieveryUploader', file, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}