import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { HStack, Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as RelatedProductService from '../../Services/Product/RelatedProductService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus, FaUpload } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import * as ProductService from '../../Services/Product/ProductService'
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';

function RelatedProductComponent(props) {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { handleSubmit: handleRelatedProduct, register: registerRelatedProduct, setValue: setValueRelatedProduct, reset: resetRelatedProduct, formState: { errors: errorRelatedProduct } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isPinCodeOpen, onOpen: onPinCodeOpen, onClose: onPinCodeClose } = useDisclosure()
    const [RelatedProductList, setRelatedProductList] = useState([]);
    const [IsLoading] = useLoaderState();
    const [Product_Id, SetProductId] = useState(0);
    const [ProductList, setProduct] = useState([]);

    useEffect(() => {
        if (parseInt(props.Product_Id) > 0) {
            SetProductId(props.Product_Id)
            GetRelatedProductListByProductId();
        }
        GetProductListData();
    }, [])

    const GetProductListData = () => {
        ProductService.GetProductList().then(data => {
            if (data.data.IsSuccess) {
                setProduct(data.data.Data);
            } else {
                setProduct([]);
            }
        }).catch(error => {
            //toast.error("Somthing went wrong.");
        })
    }

    const GetRelatedProductListByProductId = () => {
        SetProductId(props.Product_Id);
        RelatedProductService.GetRelatedProductListByProductId(props.Product_Id).then(data => {
            if (data.data.IsSuccess) {
                setRelatedProductList(data.data.Data);
            }
            else {
                setRelatedProductList([])
                resetRelatedProduct()
            }
        }).catch(error => {

        });
    }

    const DeleteRelatedProductByProductId = (realatedId) => {
        //debugger;
        RelatedProductService.DeleteRelatedProductByRelatedtId(realatedId).then(data => {
            if (data.data.IsSuccess) {
                toast.success(data.data.ResponseMessage);
                GetRelatedProductListByProductId(props.Product_Id);
            }
            else {
                toast.error("Something went wrong")
            }
        }).catch(error => {

        });
    }

    const SaveRelatedProduct = (value) => {
        value.Id = 0;
        value.Product_Id = props.Product_Id;
        value.Related_Id = parseInt(value.Related_Id);
        RelatedProductService.SaveRelatedProduct(value).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Related Product Added Succesfully");
                resetRelatedProduct();
                GetRelatedProductListByProductId(props.Product_Id);
            }
            else {
                toast.error("Something went wrong")
            }
        }).catch(error => {

        });
    }



    return (
        <Stack>
        <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Box as="form">


                <HStack>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Related_Id}>
                            <FormLabel>Related Product</FormLabel>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Related_Id}>

                            <Input hidden  {...registerRelatedProduct("Id")} />

                            <Select className="form-control" {...registerRelatedProduct("Related_Id")} >
                                <option value="">Select Related Product</option>
                                {
                                    ProductList.map((data, index) => {
                                        return (
                                            <option value={data.Id} key={index}>{data.Product_Name}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormErrorMessage>
                                {errors.Related_Id && errors.Related_Id.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack>

                <HStack spacing={"2"} paddingTop={"5"} float="right">
                    <ButtonGroup variant='outline' spacing='4' >
                        <Button disabled={IsLoading} type='submit' onClick={handleRelatedProduct(SaveRelatedProduct)} colorScheme="whatsapp">Save</Button>
                    </ButtonGroup>
                </HStack>

            </Box>


            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Current Product</Th>
                            <Th>Related Product</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {RelatedProductList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Product_Name}</Td>
                                <Td>{data.Related_Product_Name}</Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Button disabled={IsLoading} colorScheme="blue" variant="solid" size={"xs"} onClick={() => DeleteRelatedProductByProductId(data.Id)}>
                                            <DeleteIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {RelatedProductList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="12">
                                <Td colSpan="12">
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
        </Stack >
    )
}





export default RelatedProductComponent