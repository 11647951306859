import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { HStack, Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as ProductService from '../../Services/Product/ProductService'
import * as SubCategoryService from '../../Services/SubCategory/SubCategoryService'
import * as CategoryService from '../../Services/Category/CategoryService';
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import ProductSetupComponent from './ProductSetupComponent';
import { Tooltip } from '@chakra-ui/react'

function ProductComponent() {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isProductSetupOpen, onOpen: onProductSetupOpen, onClose: onProductSetupClose } = useDisclosure()
    const [ProductList, setProduct] = useState([]);
    const [IsLoading] = useLoaderState();
    const [SelectedProduct, setProductId] = useState(0);
    const [CategoryDetail, setCategoryDetail] = useState([]);
    const [SubCategoryDetail, setSubCategoryDetail] = useState([]);
    const [CategoryId, setCategoryId] = useState(0);
    const [SubCategoryId, setSubCategoryId] = useState(0);

    useEffect(() => {
        GetProductList();
        GetCategoryList();
    }, [])

    const AddProduct = () => {
        reset({});
        onAddOpen();
        setValue('Id', 0);
    }

    const GetProductList = () => {
        ProductService.GetProductList().then(data => {
            if (data.data.IsSuccess) {
                setProduct(data.data.Data);
            } else {
                setProduct([]);
            }
        }).catch(error => {
            //toast.error("Somthing went wrong.");
        })
    }

    const SaveProduct = (value) => {

        if (parseInt(value.Id) > 0) {
            ProductService.UpdateProduct(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Product Update");
                    onAddClose();
                    GetProductList();
                }
                else {
                    toast.error("Something went wrong")
                }
            }).catch(error => {

            });
        }
        else {
            value.Sub_Category_Id = SubCategoryId;
            ProductService.SaveProduct(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Product Save");
                    onAddClose();
                    GetProductList();
                }
                else {
                    toast.error("Something went wrong")
                }
            }).catch(error => {

            });
        }
    }

    const EditProduct = (id) => {
        ProductService.GetProductById(id).then(data => {
            if (data.data.IsSuccess) {
                debugger;
                onAddOpen();
                reset(data.data.Data);
                setCategoryId(parseInt(data.data.Data.Category_Id));
                setSubCategoryId(parseInt(data.data.Data.Sub_Category_Id));
                onSubCategoryChange(parseInt(data.data.Data.Sub_Category_Id));
                onCategoryChange(data.data.Data.Category_Id);
            }
        }).catch(error => {

        })
    }

    const DeleteProduct = (id, IsActive) => {
        var action = window.confirm('Do you want to delete Product?');
        if (action == true) {
            ProductService.ActivateDeactivateProduct(id, !IsActive).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    GetProductList();
                }
            }).catch(error => {

            })
        }
    }

    const ViewProductConfig = (id) => {
        setProductId(parseInt(id));
        onProductSetupOpen();
    }

    const GetCategoryList = () => {
        CategoryService.GetCategoryList().then(data => {
            if (data.data.IsSuccess) {
                setCategoryDetail(data.data.Data);
            }
        }).catch(error => {

        })
    }

    const onCategoryChange = (value) => {
        // e.preventDefault();
        setCategoryId(parseInt((value)));
        SubCategoryService.GetSubCategoryListByCategoryId(value).then(data => {
            if (data.data.IsSuccess) {
                setSubCategoryDetail(data.data.Data);
                // setValue('Sub_Category_Id', parseInt(SubCategoryId));
            }
        }).catch(error => {

        })
    }

    const onSubCategoryChange = (value) => {
        // e.preventDefault();
        setSubCategoryId(parseInt((value)));
    }
    return (
        <ContainerComponent Title="Product List" Icon={<FaCopyright />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Product" AddNewAction={AddProduct}>
            {/* <TableContainer> */}
                <Table size="sm" variant='simple'>
                    <Thead>
                        <Tr>
                            <Th w="2%">Id</Th>
                            <Th>Product Code</Th>
                            <Th w="30%">Product Name</Th>
                            <Th>Category</Th>
                            <Th>SubCategory</Th>
                            <Th w="8%">Is Active</Th>
                            <Th w="13%" >Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ProductList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Product_Code}</Td>
                                <Td>{data.Product_Name}</Td>
                                <Td>{data.Category_Name}</Td>
                                <Td>{data.SubCategory_Name}</Td>
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Edit Product' placement='auto-start'>
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditProduct(data.Id)}>
                                            <EditIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>&nbsp;&nbsp;
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='View Product' placement='auto-start'>
                                        <Button colorScheme='whatsapp' variant='solid' size={"xs"} onClick={() => ViewProductConfig(data.Id)}>
                                            <HamburgerIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>   &nbsp;&nbsp;
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Delete Product' placement='auto-start'>
                                        <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteProduct(data.Id, data.Is_Active)}>
                                            <DeleteIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {ProductList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="12">
                                <Td colSpan="12">
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            {/* </TableContainer> */}

            <ModalComponent scroll="inside" Title="Product Configuration" isOpen={isProductSetupOpen} onOpen={onProductSetupOpen} onClose={onProductSetupClose} size="full" >
                <ProductSetupComponent Product_Id={SelectedProduct} ></ProductSetupComponent>
            </ModalComponent>

            <ModalComponent Title="Add/Update Product" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveProduct)} size="4xl">
                <Stack spacing={4}>
                    <HStack>
                        <Input hidden  {...register("Id", { setValueAs: v => parseInt(v) })} />
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Product_Name}>
                                <FormLabel>Product Name</FormLabel>
                                <Input placeholder='Product Name' {...register("Product_Name", { required: "Product Name is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Product_Name && errors.Product_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Product_Code}>
                                <FormLabel>Product Code</FormLabel>
                                <Input placeholder='Product Code' {...register("Product_Code", { required: "Product Code is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Product_Code && errors.Product_Code.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Product_SEO_Name}>
                                <FormLabel>Product SEO Name</FormLabel>
                                <Input placeholder='Product SEO Name' {...register("Product_SEO_Name", { required: "Product Display Name is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Product_SEO_Name && errors.Product_SEO_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Product_Display_Name}>
                                <FormLabel>Product Display Name</FormLabel>
                                <Input placeholder='Product Display Name' {...register("Product_Display_Name", { required: "Product Display Name is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Product_Display_Name && errors.Product_Display_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Category_Id}>
                                <FormLabel>Category</FormLabel>
                                <Select className="form-control" {...register("Category_Id", { required: "Category is Mandatory", setValueAs: v => parseInt(v) })} onChange={(e) => onCategoryChange(e.target.value)} >
                                    <option value="">Select Category</option>
                                    {
                                        CategoryDetail.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.Category_Name}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.Category_Id && errors.Category_Id.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Sub_Category_Id}>
                                <FormLabel>SubCategory</FormLabel>
                                <Select className="form-control" {...register("Sub_Category_Id", { required: "SubCategory is Mandatory", setValueAs: v => parseInt(v) })} onChange={(e) => onSubCategoryChange(e.target.value)} >
                                    <option value="">Select Category</option>
                                    {
                                        SubCategoryDetail.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.SubCategory_Name}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.Sub_Category_Id && errors.Sub_Category_Id.message}
                                </FormErrorMessage>
                            </FormControl>

                            <Checkbox hidden  {...register("Is_Active")} > </Checkbox >
                        </Box>
                    </HStack>
                    
                    <HStack>
                        <Box width={"full"}>
                            <FormControl>
                                <FormLabel>Product Show In List&nbsp;&nbsp;
                                {/* <Input placeholder='Product Show In List' {...register("Is_ShowInList", { required: "Show In List is Mandatory" })} /> */}
                                <Checkbox style={{top:"4px"}} colorScheme='green' {...register("Is_ShowInList")} />
                                </FormLabel>
                                
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Product_Seq_No}>
                                <FormLabel>Product Sequence No</FormLabel>
                                <Input placeholder='Product Sequence No' {...register("Product_Seq_No", { required: "Product Sequence No is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Product_Seq_No && errors.Product_Seq_No.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                </Stack>
            </ModalComponent>

        </ContainerComponent>
    )
}

export default ProductComponent