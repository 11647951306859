import axios from 'axios'
import { trackPromise } from 'react-promise-tracker';
import * as CommonVariables from '../../Common/CommonVariables'

export const ValidateUser = async (userName, password) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `User/ValidateUser?userName=${userName}&password=${password}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetIPAddress = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + 'User/GetIPADdress', CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const ValidateUserOTP = async (mobileno, otp) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `User/ValidateUserOTP?mobileno=${mobileno}&otp=${otp}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const ResendOTP = async (mobileno) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `User/ResendOTP?mobileno=${mobileno}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetGoogleAuthLoginURL = async (mobileno) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + 'User/GetGoogleAuthLoginURL', CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetOnLoadValidate = async (code) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `User/GetOnLoadValidate?code=${code}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}