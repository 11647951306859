import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';


export const GetRelatedProductListByProductId = async (productId) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `RelatedProduct/GetRelatedProductByProductId?productId=${productId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveRelatedProduct = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'RelatedProduct/SaveRelatedProduct', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const DeleteRelatedProductByRelatedtId = async (relatedId) => {
    
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `RelatedProduct/DeleteRelatedProducByRelatedId?relatedId=${relatedId}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
