import React, { useState, useRef, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { HStack, Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as CCOrderService from '../../Services/CCOrder/CCOrderService'
import * as OrderListService from '../../Services/OrderList/OrderListService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import CCOrderSetupComponent from './CCOrderSetupComponent';
import * as moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { store } from '../../Store/store';
import { Tooltip } from '@chakra-ui/react'

function CCOrderScreenComponent() {
    const { handleSubmit, register, setValue, getValues, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { register: stockregister, getValues: stockgetValue, setValue: stocksetValue, reset: stockreset, setError: stocksetError, handleSubmit: stockhandleSubmit, formState: { errors: stockerrors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddStockOpen, onOpen: onAddStockOpen, onClose: onAddStockClose } = useDisclosure();
    const { isOpen: isUpdatePayStatusOpen, onOpen: onUpdatePayStatusOpen, onClose: onUpdatePayStatusClose } = useDisclosure();
    const [OrderList, SetOrderList] = useState([]);
    const [IsLoading] = useLoaderState();
    const [OrderItemList, setOrderItemList] = useState([]);
    const [CurrentOrderId, setCurrentOrderId] = useState(0);
    const [PaymentLinkRequestForm, setPaymentLinkRequestForm] = useState({});//Payment
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const orderStatus = useRef();
    const orderNumber = useRef();
    const userInfo = store.getState();
    const [StockOrderId, setStockOrderId] = useState(0);
    const [OrderStockList, setOrderStockList] = useState([]);
    const [PayPayemntId, SetPayPayemntId] = useState('');

    useEffect(() => {
        GetOrderListByUserId();
    }, [])

    const GetOrderListByUserId = () => {
        CCOrderService.GetOrderListByUserId('', moment(StartDate).format("YYYY-MM-DD"), moment(EndDate).format("YYYY-MM-DD")).then(data => {
            if (data.data.IsSuccess) {
                SetOrderList(data.data.Data);
            } else {
                toast.error(data.data.ResponseMessage);
                SetOrderList([]);
            }
        }).catch(error => {
            // toast.error("Somthing went wrong.");
        })
    }

    const Download = () => {
        debugger;
        window.open(CommonVariables.API_DOMAIN + `SalesOrder/GetFilteredDownloadOrder?orderStatus=${orderStatus.current.value}&orderDate=${moment(StartDate).format("YYYY-MM-DD")}&endDate=${moment(EndDate).format("YYYY-MM-DD")}&userId=${parseInt(userInfo.userData.User_Id)}`, "_blank");

    }

    const SearchOrder = () => {
        CCOrderService.GetOrderListByUserId(orderStatus.current.value, moment(StartDate).format("YYYY-MM-DD"), moment(EndDate).format("YYYY-MM-DD")).then(data => {
            if (data.data.IsSuccess) {
                SetOrderList(data.data.Data);
            } else {
                //toast.error("Somthing went wrong");
                SetOrderList([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const AddOrder = () => {
        onAddOpen();
    }

    const ViewPaymentDetail = (id) => {
        setCurrentOrderId(id)
        OrderListService.GetOrderItemListByOrderId(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data.OrderSummary)
                setOrderItemList(data.data.Data.OrderItemList)
                onUpdatePayStatusOpen();
            }
            else {
                reset([])
                setOrderItemList([])
                onUpdatePayStatusOpen();
            }
        }).catch(error => {

        })
    }
    const UpdateRazorpayPaymentLinkId = (paymentlinkId, orderid) => {

        const values = getValues();
        values.Id = orderid;
        values.Payment_LinkId = paymentlinkId
        OrderListService.UpdateRazorpayPaymentLinkId(values).then(data => {
            if (data.data.IsSuccess) {

            }
            else {
                toast.error('Something went wrong')
            }
        }).catch(error => {

        })
    }

    const UpdatePaymentDetail = (value) => {
        debugger;
        if (value.Payment_Mode != "cash") {
            if (value.Payment_Status == "captured" && value.Payment_Id == "") {
                toast.error("Payment / Transaction Id cannot be empty")
                return false;
            }
            if (value.Payment_Status == "captured" && value.Payment_Mode == "") {
                toast.error("Payment Mode cannot be empty")
                return false;
            }
        }
        value.OrderId = CurrentOrderId;
        var action = window.confirm('Do you want to Update Order status as ' + value.Payment_Status + ' ?');
        if (action == true) {
            CCOrderService.UpdatePaymentDetail(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Payment Updated Succesfully")
                    onUpdatePayStatusClose();
                    GetOrderListByUserId();
                }
                else {
                    toast.error(data.data.ResponseMessage)
                    onUpdatePayStatusOpen();
                }
            }).catch(error => {

            })
        }
    }


    const SendPaymentLink = (e) => {
        e.preventDefault();
        debugger;

        const values = getValues();
        console.log(values);
        const PaymentLinkRequestModel = PaymentLinkRequestForm;
        PaymentLinkRequestModel.Amount = values.OrderValuePostDiscount;
        PaymentLinkRequestModel.ProductName = "HiCare Products";
        PaymentLinkRequestModel.CustomerName = values.Contact_Person_Name;
        PaymentLinkRequestModel.CustomerMobile = values.Contact_Person_Mobile;
        PaymentLinkRequestModel.CustomerEmail = values.Contact_Person_Email;
        PaymentLinkRequestModel.OrderId = CurrentOrderId;

        console.log(PaymentLinkRequestModel);
        CCOrderService.CancelAndCreatePaymentLinkAsync(PaymentLinkRequestModel).then(data => {
            if (data.data.IsSuccess && data.data.Data.id != null) {
                toast.success(data.data.ResponseMessage);
                SetPayPayemntId(data.data.Data.id);
                UpdateRazorpayPaymentLinkId(data.data.Data.id, CurrentOrderId);
            }
            else if (data.data.IsSuccess && data.data.Data == "Success") {
                toast.success(data.data.ResponseMessage);
            }
            else {
                toast.error("Something went worng!")
            }
        }).catch(error => {
            debugger;
            toast.error("Something went worng!")
        })

    }


    const ViewOrderStockDetail = (id,) => {
        stockreset([]);
        onAddStockOpen();
        setStockOrderId(id);
        OrderListService.ViewOrderStockDetail(id).then(data => {
            if (data.data.IsSuccess) {
                stocksetValue('Order_Number', data.data.Data[0].OrderNumber)
                stocksetValue('Tracking_URL', data.data.Data[0].Tracking_URL)
                stocksetValue('AWB_No', data.data.Data[0].AWB_No)
                stocksetValue('Delivery_Partner', data.data.Data[0].Delivery_Partner)
                onDeliveryPartnerChange(data.data.Data[0].Delivery_Partner);
                setOrderStockList(data.data.Data)
                onAddStockOpen();
            }
            else {
                reset()
                setOrderStockList([])
                onAddStockOpen();
            }
        }).catch(error => {

        })
    }

    const onDeliveryPartnerChange = (value) => {

        if (value == 'Trackon Courier')
            stocksetValue('Tracking_URL', 'https://trackon.in/')
        else if (value == 'Professional Courier')
            stocksetValue('Tracking_URL', 'https://trackcourier.io/professional-courier-tracking')
        else
            stocksetValue('Tracking_URL', '')
    }
    return (
        <ContainerComponent Title="Order" Icon={<FaCopyright />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Book Order" AddNewAction={AddOrder}>
            <HStack>
                <Box width={"250"} paddingTop={2} paddingLeft={5} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>Order Status</FormLabel>
                        <Select className="form-control" ref={orderStatus}>
                            <option value="">Select Order Status</option>
                            {
                                CommonVariables.CCorderStatus.map((data, index) => {
                                    return (
                                        <option value={data.Value} key={index}>{data.Key}</option>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingLeft={5} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>Start Date</FormLabel>

                        <ReactDatePicker
                            valueName="selected" // DateSelect value's name is selected
                            selected={StartDate}
                            onChange={(date) => setStartDate(date)}
                            name="ReactDatepicker"
                            className="text-center border-l-4 border-red-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                            placeholderText="Select start date"
                            dateFormat='dd-MM-yyyy'
                            popperPlacement=""
                            value={StartDate}
                        />
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>End Date</FormLabel>

                        <ReactDatePicker
                            valueName="selected" // DateSelect value's name is selected
                            selected={EndDate}
                            onChange={(date) => setEndDate(date)}
                            name="ReactDatepicker"
                            className="text-center border-l-4 border-red-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                            placeholderText="Select start date"
                            dateFormat='dd-MM-yyyy'
                            value={EndDate}
                        />
                    </FormControl>
                </Box>
                {/* <Box width={"150"} paddingTop={2} paddingBottom={10}>
                    <FormControl >
                        <FormLabel>Search Order No</FormLabel>
                        <Input placeholder='Enter Order No' ref={orderNumber} />
                    </FormControl>
                </Box> */}
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button colorScheme='red' onClick={() => SearchOrder()} marginTop={8}>
                        Search
                    </Button>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button colorScheme='red' onClick={() => Download()} marginTop={8}>
                        Download
                    </Button>
                </Box>
            </HStack>
            <Spacer />
            <TableContainer>
                <Table size="sm" variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Order Date</Th>
                            <Th>Customer Name</Th>
                            <Th>Mobile No</Th>
                            <Th>Order No</Th>
                            <Th>Payment Id</Th>
                            <Th>Amount</Th>
                            <Th>AWB No</Th>
                            <Th>Status</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {OrderList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.OrderId}</Td>
                                <Td>{moment(data.OrderDate).format("DD-MMM-YYYY")}</Td>
                                <Td>{data.Contact_Person_Name}</Td>
                                <Td>{data.Contact_Person_Mobile}</Td>
                                <Td>{data.OrderNumber}</Td>
                                <Td>{data.PaymentId}</Td>
                                <Td>{(data.OrderValuePostDiscount > 0) ? data.OrderValuePostDiscount : <span className='Free'>Free</span>}</Td>
                                <Td>{data.AWB_No}</Td>
                                <Td>{data.OrderStatus == 'Draft' ? <span className='Free'><b>{data.OrderStatus}</b></span> : data.OrderStatus}</Td>
                                <Td>
                                    {data.OrderStatus == 'Draft' ?
                                        <ButtonGroup variant={"outline"} spacing="1">
                                            <Tooltip label='View Payment Details' placement='auto-start'>
                                                <Button colorScheme='red' variant='solid' size={"xs"} onClick={() => ViewPaymentDetail(data.OrderId)}>
                                                    <ArrowForwardIcon />
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                        : <></>}
                                    {(data.OrderStatus != "Draft") ? (
                                        <ButtonGroup variant={"outline"} spacing="1">
                                            <Tooltip label='View Order Stock Details' placement='auto-start'>
                                                <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => ViewOrderStockDetail(data.OrderId)}>
                                                    <EditIcon />
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                    ) : (<></>)}
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {OrderList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="12">
                                <Td colSpan="12">
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>

            <ModalComponent scroll="inside" Title="Order Screen" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} size="full" >
                <CCOrderSetupComponent></CCOrderSetupComponent>
            </ModalComponent>

            <ModalComponent scroll="inside" Title="Update Payment Status" isOpen={isUpdatePayStatusOpen} onOpen={onUpdatePayStatusOpen} onClose={onUpdatePayStatusClose} size="4xl" >

                <HStack>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Customer Name</FormLabel>
                            <Input readOnly {...register("Contact_Person_Name")} />
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Customer Mobile</FormLabel>
                            <Input readOnly {...register("Contact_Person_Mobile")} />
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Customer Email</FormLabel>
                            <Input readOnly {...register("Contact_Person_Email")} />
                        </FormControl>
                    </Box>
                </HStack>
                <HStack>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Order Value</FormLabel>
                            <Input readOnly {...register("OrderValue")} />

                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Discounted Value</FormLabel>
                            <Input readOnly {...register("OrderValuePostDiscount")} />

                        </FormControl>
                    </Box>
                </HStack>

                <HStack>

                    <Box width={"50%"}>
                        <FormControl isRequired isInvalid={errors.Payment_Status}>
                            <FormLabel>Payment Status</FormLabel>
                            <Select className="form-control" {...register("Payment_Status", { required: "Payment Status is Mandatory" })} >
                                <option value="">Select Payment Status</option>
                                {
                                    CommonVariables.UpdatePayStatus.map((data, index) => {
                                        return (
                                            <option value={data.Value} key={index}>{data.Key}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormErrorMessage>
                                {errors.Payment_Status && errors.Payment_Status.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    {/* <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Status Reason</FormLabel>
                            <Input placeholder='Enter Status Reason' {...register("Status_Reason")} />

                        </FormControl>
                    </Box>

                    
                </HStack>

                <HStack > */}
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Payment Mode</FormLabel>
                            <Select className="form-control" {...register("Payment_Mode")} >
                                <option value="">Select Payment Mode</option>
                                {
                                    CommonVariables.PaymentMode.map((data, index) => {
                                        return (
                                            <option value={data.Value} key={index}>{data.Key}</option>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </HStack>

                <HStack >
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Payment / Transaction Id</FormLabel>
                            <Input autoComplete='Off' placeholder='Enter Payment / Transaction Id' {...register("Payment_Id")} />
                        </FormControl>
                    </Box>
                {/* </HStack>

                <HStack > */}
                    <Box width={"full"} spacing={"2"} paddingTop={"5"}>
                        <ButtonGroup variant='outline' spacing='4'  >
                            <Button disabled={IsLoading} type='submit' onClick={handleSubmit(UpdatePaymentDetail)} colorScheme="whatsapp">Update</Button>
                        </ButtonGroup> &nbsp;
                        <ButtonGroup spacing='4'  >
                            <Button disabled={IsLoading} type='button' onClick={(e) => SendPaymentLink(e)} colorScheme="whatsapp">Send Payment Link</Button>
                        </ButtonGroup>
                    </Box>
                </HStack>

                <HStack spacing={"2"} paddingTop={"5"} >

                </HStack>
                <br />
                <Divider />
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Sr.No</Th>
                                <Th>Product Name</Th>
                                <Th>Quantity</Th>
                                <Th>InstallationCharge</Th>
                                <Th>Price</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {OrderItemList.map((data, index) => {
                                return (<Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{data.Product_Name}</Td>
                                    <Td>{data.Quantity}</Td>
                                    <Td>{data.InstallationCharge}</Td>
                                    <Td>{data.Price}</Td>
                                </Tr>)
                            })}
                        </Tbody>
                        {OrderItemList.length <= 0 ? (
                            <Tfoot>
                                <Tr colSpan="3">
                                    <Td>
                                        No Data Available
                                    </Td>
                                </Tr>
                            </Tfoot>
                        ) : <></>
                        }

                    </Table>
                </TableContainer>
            </ModalComponent>

            <ModalComponent Title="Order Stock Summary" scroll="inside" size={"4xl"} isOpen={isAddStockOpen} onOpen={onAddStockOpen} onClose={onAddStockClose} >
                <Input hidden  {...register("OrderId")} />
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Order Number</FormLabel>
                                <Input readOnly placeholder='Order Number' {...stockregister("Order_Number")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Delivery Partner</FormLabel>
                                <Input readOnly placeholder='Delivery Partner' {...stockregister("Delivery_Partner")} />
                                {/* <Select readOnly className="form-control" {...stockregister("Delivery_Partner")} onChange={(e) => onDeliveryPartnerChange(e.target.value)}>
                                    <option value="">Select Order Status</option>
                                    {
                                        CommonVariables.DeliveryPartner.map((data, index) => {
                                            if (data.Value != 'Booked') {
                                                return (
                                                    <option value={data.Value} key={index}>{data.Key}</option>
                                                )
                                            }
                                        })
                                    }
                                </Select> */}
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>

                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Tracking URL</FormLabel>
                                <Input readOnly placeholder='Tracking URL' {...stockregister("Tracking_URL")} />
                            </FormControl>
                        </Box>
                        <Box width={"40%"}>
                            <FormControl >
                                <FormLabel>AWB No</FormLabel>
                                <Input readOnly placeholder='AWB No' {...stockregister("AWB_No")} />
                            </FormControl>
                        </Box>
                    </HStack>

                </Stack>
                <br />
                <Divider />
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Id</Th>
                                <Th>Stock Code</Th>
                                <Th>Product Name</Th>
                                {/* <Th>Price</Th> */}
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {OrderStockList.map((data, index) => {
                                return (<Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{data.Stock_Code}</Td>
                                    <Td>{data.Product_Name}</Td>
                                    {/* <Td>{data.Price}</Td> */}
                                    <Td>{data.Stock_Status}</Td>
                                </Tr>)
                            })}
                        </Tbody>
                        {OrderStockList.length <= 0 ? (
                            <Tfoot>
                                <Tr colSpan="3">
                                    <Td>
                                        No Data Available
                                    </Td>
                                </Tr>
                            </Tfoot>
                        ) : <></>
                        }

                    </Table>
                </TableContainer>
            </ModalComponent>

        </ContainerComponent >
    )
}

export default CCOrderScreenComponent