import axios from "axios";
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetBannerSectionList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BannerSection/GetBannerSectionList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetBannerSectionById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BannerSection/GetBannerSectionById?id=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveBannerSection = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BannerSection/SaveBannerDetail`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdateBannerSection = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BannerSection/UpdateBannerDetail`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const ActiveDeActiveBannerSection = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BannerSection/ActivateDeactivateBannerSection?sectionId=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
