import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast';
import * as BannerService from '../../Services/Banner/BannerService'
import * as ProductService from '../../Services/Product/ProductService'
import * as BannerSectionService from '../../Services/BannerSection/BannerSectionService'
import { useForm } from 'react-hook-form'
import { FormControl, Select, FormLabel, HStack, FormErrorMessage, Input, Checkbox, useDisclosure, Stack, Table, ButtonGroup, Tfoot, Box, TableContainer, Button, Thead, Tbody, Td, Tr, Th } from '@chakra-ui/react'
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import { FiGrid } from "react-icons/fi"
import ModalComponent from '../Modal/ModalComponent';
import FilePicker from 'chakra-ui-file-picker';
import * as AttachmentService from '../../Services/Attachment/AttachmentService'
import { Tooltip } from '@chakra-ui/react'

function BannerComponent() {
    const { register, watch, handleSubmit, setError, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const [BannerList, setBannerList] = useState([]);
    const [BannerSectionList, setBannerSectionList] = useState([]);
    const [ProductList, setProductList] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedBanner, setBannerId] = useState(0);
    const showButton = watch("Show_Button", false);
    const bannerType = watch("Banner_Type", "");
    useEffect(() => {
        LoadBanner();
        LoadProductList();
        LoadBannerSection();
    }, [])

    const LoadBanner = () => {
        BannerService.GetBannerList().then(data => {
            if (data.data.IsSuccess) {
                setBannerList(data.data.Data);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    const AddBanner = () => {
        reset({});
        LoadProductList();
        LoadBannerSection();
        setBannerId(0);
        onOpen();
    }
    const EditBanner = (id) => {
        setBannerId(id);
        BannerService.GetBannerById(id).then(data => {
            if (data.data.IsSuccess) {
                onOpen();
                reset(data.data.Data);
            } else {
                toast.error("Somthing went wrong.");
            }
        }).catch(error => {
            toast.error("Somthing went wrong.");
        })
    }

    const LoadBannerSection = () => {
        BannerSectionService.GetBannerSectionList().then(data => {
            if (data.data.IsSuccess) {
                setBannerSectionList(data.data.Data);
            }
        }).catch(error => {

        })
    }

    const LoadProductList = () => {
        ProductService.GetProductList().then(data => {
            if (data.data.IsSuccess) {
                setProductList(data.data.Data);
            }
        }).catch(error => {

        })
    }

    const SaveBanner = (value) => {
        console.log(value);
        if (value.Banner_Web_Image == undefined || value.Banner_Web_Image == null || value.Banner_Web_Image.length <= 0) {
            setError('Banner_Web_Image', { types: { required: "Web Banner is Mandatory" } })
        }
        if (value.Banner_Mobile_Image == undefined || value.Banner_Mobile_Image == null || value.Banner_Mobile_Image.length <= 0) {
            setError('Banner_Mobile_Image', { types: { required: "Mobile Banner is Mandatory" } })
        }
        debugger;
        console.log(value);
        if (selectedBanner <= 0) {
            BannerService.SaveBanner(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    LoadBanner();
                    onClose();
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            BannerService.UpdateBanner(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    LoadBanner();
                    onClose();
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }

    const FileUploadToS3 = (fileList) => {
        if (fileList != null && fileList.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', fileList[0])

            AttachmentService.FileUploadToS3(formdata).then(response => {
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                    setValue('Banner_Web_Image', response.data.Data)
                }
                else {
                    // setWhatsApp_Attachment("");
                    setValue('Banner_Web_Image', "")
                    setError('Banner_Web_Image', { types: { required: "Web Banner is Mandatory" } })
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
                setError('Banner_Web_Image', { types: { required: "Web Banner is Mandatory" } })
            });
        }
        else {
            toast.error('Please choose Image First!')
            setError('Banner_Web_Image', { types: { required: "Web Banner is Mandatory" } })
        }
    }

    const MobileFileUploadToS3 = (fileList) => {
        if (fileList != null && fileList.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', fileList[0])

            AttachmentService.FileUploadToS3(formdata).then(response => {
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                    setValue('Banner_Mobile_Image', response.data.Data)
                }
                else {
                    // setWhatsApp_Attachment("");
                    setValue('Banner_Mobile_Image', "")
                    setError('Banner_Mobile_Image', { types: { required: "Mobile Banner is Mandatory" } })
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
                setError('Banner_Mobile_Image', { types: { required: "Mobile Banner is Mandatory" } })
            });
        }
        else {
            toast.error('Please choose Image First!')
            setError('Banner_Mobile_Image', { types: { required: "Mobile Banner is Mandatory" } })
        }
    }

    const DeleteBanner = (id, IsActive) => {
        BannerService.ActiveDeActiveBannerDetail(id, !IsActive).then(data => {
            if (data.data.IsSuccess) {
                toast.success(data.data.ResponseMessage);
                LoadBanner();
            } else {
                toast.error("Something went wrong")
            }
        }).catch(error => {

        })
    }

    return (
        <ContainerComponent Title="Banner" Icon={<FiGrid />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Banner" AddNewAction={AddBanner}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Banner Id</Th>
                            <Th>Title</Th>
                            <Th>Type</Th>
                            <Th>Seq No</Th>
                            <Th>Is Active</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {BannerList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Banner_Title}</Td>
                                <Td>{data.Banner_Type}</Td>
                                <Td>{data.Banner_Sequence}</Td>
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Edit Banner' placement='auto-start'>
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditBanner(data.Id)}>
                                            <EditIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>&nbsp;&nbsp;
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Delete Banner' placement='auto-start'>
                                        <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteBanner(data.Id, data.Is_Active)}>
                                            <DeleteIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {BannerList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
            <ModalComponent Title="Add/Update Banner" size={"3xl"} isOpen={isOpen} onOpen={onOpen} onClose={onClose} onSave={handleSubmit(SaveBanner)}>
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            {/* <FormControl isRequired isInvalid={errors.Banner_Title}> */}
                            <FormControl >
                                <FormLabel>Banner Title</FormLabel>
                                <Input placeholder='Banner Title' {...register("Banner_Title")} />
                                {/* <FormErrorMessage>
                                    {errors.Banner_Title && errors.Banner_Title.message}
                                </FormErrorMessage> */}
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Section_Id}>
                                <FormLabel>Banner Section</FormLabel>
                                <Select placeholder='Select option' {...register("Section_Id", { required: "Banner Section is Mandatory", setValueAs: v => parseInt(v) })}>
                                    {
                                        BannerSectionList.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.Screen_Name + " - " + data.Banner_Section}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.Section_Id && errors.Section_Id.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Banner_Web_Image}>
                                <FormLabel>Banner Web Image</FormLabel>

                                <FilePicker
                                    onFileChange={(fileList) => { FileUploadToS3(fileList) }}
                                    placeholder="Web Image"
                                    clearButtonLabel="Clear"
                                    multipleFiles={false}
                                    accept="application/png"
                                    hideClearButton={false}
                                />
                                <FormErrorMessage>
                                    {errors.Banner_Web_Image && errors.Banner_Web_Image.message}
                                </FormErrorMessage>
                            </FormControl>
                            <span className='NoteImageSize'> Note : Size 1349.50*450</span>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Banner_Mobile_Image}>
                                <FormLabel>Banner Mobile Image</FormLabel>
                                <FilePicker
                                    onFileChange={(fileList) => { MobileFileUploadToS3(fileList) }}
                                    placeholder="Mobile Image"
                                    clearButtonLabel="Clear"
                                    multipleFiles={false}
                                    accept="application/png"
                                    hideClearButton={false}
                                />
                                <FormErrorMessage>
                                    {errors.Banner_Mobile_Image && errors.Banner_Mobile_Image.message}
                                </FormErrorMessage>
                            </FormControl>
                            <span className='NoteImageSize'> Note : Size 450*450</span>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"40%"}>
                            <FormControl isRequired isInvalid={errors.Banner_Type}>
                                <FormLabel>Banner Type</FormLabel>
                                <Select placeholder='Select option' {...register("Banner_Type", { required: "Banner Type is Mandatory" })}>
                                    <option value='Custom'>Custom</option>
                                    <option value='Product'>Product</option>
                                </Select>
                                <FormErrorMessage>
                                    {errors.Banner_Type && errors.Banner_Type.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            {bannerType == "Product" ?
                                (<FormControl isRequired isInvalid={errors.Product_Id}>
                                    <FormLabel>Product</FormLabel>
                                    <Select placeholder='Select Product' {...register("Product_Id", { required: "Product is Mandatory", setValueAs: v => parseInt(v) })}>
                                        {
                                            ProductList.map((data, index) => {
                                                return (
                                                    <option key={index} value={data.Id}> {data.Product_Name}</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormErrorMessage>
                                        {errors.Product_Id && errors.Product_Id.message}
                                    </FormErrorMessage>
                                </FormControl>) : <></>}
                            {bannerType == "Custom" ?
                                (<FormControl isRequired isInvalid={errors.Page_Link}>
                                    <FormLabel>Custom Link</FormLabel>
                                    <Input placeholder='Banner Link' {...register("Page_Link", { required: "Link is Mandatory" })} />
                                    <FormErrorMessage>
                                        {errors.Page_Link && errors.Page_Link.message}
                                    </FormErrorMessage>
                                </FormControl>) : <></>}
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"50%"}>
                            <FormControl isRequired isInvalid={errors.Banner_Sequence}>
                                <FormLabel>Banner Sequence</FormLabel>
                                <Input type="number" placeholder='Banner Sequence' {...register("Banner_Sequence", { required: "Banner Sequence is Mandatory", setValueAs: v => parseInt(v) })} />
                                <FormErrorMessage>
                                    {errors.Banner_Sequence && errors.Banner_Sequence.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"40%"} paddingLeft={5}>
                            <FormControl isRequired isInvalid={errors.Show_Button}>
                                <FormLabel>Show Button</FormLabel>
                                <Checkbox colorScheme='green' defaultChecked={false} {...register("Show_Button")}>
                                </Checkbox>
                                <FormErrorMessage>
                                    {errors.Show_Button && errors.Show_Button.message}
                                </FormErrorMessage>
                            </FormControl>
                            {/* <FormControl isRequired isInvalid={errors.Is_Active}>
                                <FormLabel>Is Active</FormLabel>
                                <Checkbox colorScheme='green' defaultChecked {...register("Is_Active", { required: "Selection is mandatory" })}>
                                </Checkbox>
                                <FormErrorMessage>
                                    {errors.Is_Active && errors.Is_Active.message}
                                </FormErrorMessage>
                            </FormControl> */}
                        </Box>
                        <Box width={"full"}>
                            {showButton ? (
                                <FormControl isRequired isInvalid={errors.Button_Text}>
                                    <FormLabel>Button Title</FormLabel>
                                    <Input placeholder='Banner Title' {...register("Button_Text", { required: "Button Text is Mandatory" })} />
                                    <FormErrorMessage>
                                        {errors.Button_Text && errors.Button_Text.message}
                                    </FormErrorMessage>
                                </FormControl>
                            ) : <></>}
                        </Box>
                    </HStack>
                </Stack>
            </ModalComponent>
        </ContainerComponent>
    )
}

export default BannerComponent