import axios from "axios";
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetOrderList = async (userId) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetOrderListByServiceCenterId?userId=${userId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetFilteredOrderList = async (orderNumber,status,date,endDate) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetFilteredOrderListByServiceCenterId?orderStatus=${status}&orderDate=${date}&endDate=${endDate}&orderNumber=${orderNumber}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetFilteredDownloadOrder = async (orderNumber,status,date,endDate) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetFilteredDownloadOrder?orderStatus=${status}&orderDate=${date}&endDate=${endDate}&orderNumber=${orderNumber}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetOrderItemListByOrderId = async (orderId) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetOrderItemListByOrderId?orderId=${orderId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const ViewOrderStockDetail = async (orderId) => {
    debugger;
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetOrderStockListByOrderId?orderId=${orderId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

// export const ChangeOrderStatusByOrderId = async (orderId,status,trackingURL,deliveryPartner,awbNo,invoiceNo,noOfBox,statusreason,invoicedate) => {
//     var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/ChangeOrderStatusByOrderId?status=${status}&orderId=${orderId}&trackingUrl=${trackingURL}&deliveryPartner=${deliveryPartner}&awbNo=${awbNo}&invoiceNo=${invoiceNo}&noOfBox=${noOfBox}&statusreason=${statusreason}&invoicedate=${invoicedate}`, CommonVariables.API_HEADER)).then(response => response);
//     return result;
// }

export const ChangeOrderStatusByOrderId = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductOrder/ChangeOrderStatusByOrderId', JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const PrintOrderInvoice = async (orderId) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `OrderInvoice/GenerateInvoiceByOrderId?orderid=${orderId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const PrintOrderReceipt = async (orderId) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductDelivery/Generatelabelpdf?orderid=${orderId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UniversalSearchByOrderNoMobileNo = async (search) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/UniversalSearchByOrderNoMobileNo?search=${search}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetOrderDetailsforAddressEdit = async (orderId) => {
    debugger;
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetOrderDetailsforAddressEdit?orderId=${orderId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdateCustomerAddressAdmin = async (value) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductOrder/UpdateCustomerAddressAdmin', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateRazorpayPaymentLinkId = async (value) => {
    
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductOrder/UpdateRazorpayPaymentLinkId', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetNavigenOrderReport = async (orderNumber,status,date,endDate) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductOrder/GetFilteredDownloadOrder?orderStatus=${status}&orderDate=${date}&endDate=${endDate}&orderNumber=${orderNumber}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}


//Dashboard Delivery Service

export const GetDashboardDeliveryStatusReportData = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `DashBoard/GetDashboardDeliveryStatusReportData`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const GetDelieveryStatusReportByStatusandDays = async (status, daydifference) => {
    debugger;
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `DashBoard/GetDelieveryStatusReportByStatusandDays?status=${status}&daydifference=${daydifference}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

