import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as ProductService from '../../Services/Product/ProductService'
import useLoaderState from '../../Common/useLoaderState';
import ProductFAQComponent from '../Product/ProductFAQComponent';
import ProductGalleryComponent from '../Product/ProductGalleryComponent';
import ProductConfigurationComponent from '../Product/ProductConfigurationComponent';
import ProductDetailComponent from '../Product/ProductDetailComponent';
import RelatedProductComponent from '../Product/RelatedProductComponent';
import ProductSpecificationComponent from '../Product/ProductSpecificationComponent';
import ProductTestimonialComponent from '../Product/ProductTestimonialComponent';

function ProductSetupComponent(props) {
    const { handleSubmit, register, setValue, getValues, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const [IsLoading] = useLoaderState();
    const [Product_Id, SetProductId] = useState(0);


    useEffect(() => {
        //debugger;
        SetProductId(props.Product_Id)
        GetProductRelatedDetails();
    }, [])

    const GetProductRelatedDetails = () => {
        ProductService.GetProductById(props.Product_Id).then(data => {
            //debugger;
            if (data.data.IsSuccess) {
                console.log(data.data.Data);
                // setValue('Product_Name', data.data.Data.Product_Name);
            }
        }).catch(error => {
            //toast.error("Somthing went wrong.");
        })
    }


    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>Information</Tab>
                    <Tab>Discount & Detail</Tab>
                    <Tab>Specification</Tab>
                    <Tab>Related Products</Tab>
                    <Tab>FAQ</Tab>
                    <Tab>Gallery</Tab>
                    <Tab>Testimonial</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <ProductConfigurationComponent Product_Id={ props.Product_Id } ></ProductConfigurationComponent>
                    </TabPanel>
                    <TabPanel>
                        <ProductDetailComponent Product_Id={ props.Product_Id} ></ProductDetailComponent>
                    </TabPanel>
                    <TabPanel>
                        <ProductSpecificationComponent Product_Id={ props.Product_Id} ></ProductSpecificationComponent>
                    </TabPanel>
                    <TabPanel>
                        <RelatedProductComponent Product_Id={ props.Product_Id} ></RelatedProductComponent>
                    </TabPanel>
                    <TabPanel>
                        <ProductFAQComponent Product_Id={ props.Product_Id } ></ProductFAQComponent>
                    </TabPanel>
                    <TabPanel>
                        <ProductGalleryComponent Product_Id={ props.Product_Id } ></ProductGalleryComponent>
                    </TabPanel>
                    <TabPanel>
                        <ProductTestimonialComponent Product_Id={ props.Product_Id } ></ProductTestimonialComponent>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default ProductSetupComponent