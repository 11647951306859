import React from 'react';
import {
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTag,
    FiTruck,
    FiBox,
    FiMapPin,
    FiGrid,
    FiLayout,
    FiDatabase,
    FiShoppingCart,
    FiFileText,
    FiAirplay,
    FiAlertCircle,
    FiUser,
    FiLink
} from 'react-icons/fi';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { store } from '../../Store/store';
import { FaMicroblog } from 'react-icons/fa';


export default function LeftMenuComponent(props) {
    const navigate = useNavigate();

    return (
        <>
            <SidebarContent
                onClose={() => props.onClose()}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={props.isOpen}
                placement="left"
                onClose={props.onClose()}
                returnFocusOnClose={false}
                onOverlayClick={props.onClose()}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={() => props.onClose()} />
                </DrawerContent>
            </Drawer>
        </>
    )
}

const SidebarContent = ({ onClose, ...rest }) => {
    let LinkItems = [];
    const userInfo = store.getState();
    
    if (userInfo.userData.Role_Name == "Admin") {
        
        LinkItems = [
            { name: 'Home', icon: FiHome, url: '/dashboard' },
            { name: 'Category', icon: FiTag, url: '/category' },
            { name: 'Product List', icon: FiBox, url: '/product' },
            { name: 'Pincode', icon: FiMapPin, url: '/pincode' },
            { name: 'Service Center', icon: FiTruck, url: '/servicecenter' },
            { name: 'Banner', icon: FiGrid, url: '/banner' },
            { name: 'Banner Section', icon: FiLayout, url: '/bannersection' },
            { name: 'Campaign', icon: FiAirplay, url: '/campaign' },
            { name: 'Complaint', icon: FiAlertCircle, url: '/complaint' },
            { name: 'Order List', icon: FiFileText, url: '/orderlist' },
            { name: 'Book Order', icon: FiShoppingCart, url: '/ccorder' },
            { name: 'Stock Upload', icon: FiDatabase, url: '/stock' },
            { name: 'User List', icon: FiUser, url: '/user' },
            { name: 'Blog List', icon: FaMicroblog, url: '/blog' },
            { name: 'Meta List', icon: FiTag, url: '/meta' }
        ];
    }
    else if (userInfo.userData.Role_Name == "Manager") {
        
        LinkItems = [
            { name: 'Home', icon: FiHome, url: '/dashboard' },
            { name: 'Order List', icon: FiFileText, url: '/orderlist' },
            { name: 'Book Order', icon: FiShoppingCart, url: '/ccorder' },
            { name: 'Complaint', icon: FiAlertCircle, url: '/complaint' },
            { name: 'User List', icon: FiUser, url: '/user' }
        ];
    }
    else if (userInfo.userData.Role_Name == "CallCenter") {
        
        LinkItems = [
            { name: 'Home', icon: FiHome, url: '/dashboard' },
            { name: 'Order List', icon: FiFileText, url: '/orderlist' },
            { name: 'Book Order', icon: FiShoppingCart, url: '/ccorder' },
            { name: 'Complaint', icon: FiAlertCircle, url: '/complaint' }
        ];
    }
    else {
        
        LinkItems = [
            { name: 'Home', icon: FiHome, url: '/dashboard' },
            { name: 'Book Order', icon: FiShoppingCart, url: '/ccorder' },
            { name: 'Complaint', icon: FiAlertCircle, url: '/complaint' }
        ];
    }

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 48 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    <img src='https://hicare.in/media/logo/stores/1/hicare-logo.png' style={{ height: "58px" }} />
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            <div>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} url={link.url}>
                    {link.name}
                </NavItem>
            ))}
            </div>
        </Box>
    );
};

const NavItem = ({ icon, children, url, ...rest }) => {
    return (
        <Link  as={RouterLink} to={url} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="3"
                mx="3"
                borderRadius="md"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                padding="5px"
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="14"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};
