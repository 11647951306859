import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { HStack, Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as AttachmentService from '../../Services/Attachment/AttachmentService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import FilePicker from 'chakra-ui-file-picker';


function StockComponent() {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()

    const [IsLoading] = useLoaderState();

    const [SelectedFile, setSelectedFile] = useState([]);

    useEffect(() => {

    }, [])



    const onStockFileChange = (fileList) => {
        setSelectedFile(fileList);
    }


    const UploadFile = () => {
        debugger;
        if (SelectedFile != null && SelectedFile.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', SelectedFile[0])

            AttachmentService.UploadStock(formdata).then(response => {
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);

                }
                else {
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
            });
        }
        else {
            toast.error('Please select a file first!')
        }
    }

    const DownloadFile = () => {
       window.open('https://d2cu8x18fy3ls6.cloudfront.net/ec2ab045-618b-4f37-b51d-814d1035a6bf.xlsx')
    }



    return (
        <ContainerComponent Title="Stock Upload" Icon={<FaCopyright />} ShowDownloadAction="false" ShowAction="false" >

            <Box as="form">

                <HStack>
                    <Box width={"60%"}>
                        <FormControl isRequired>
                            <FormLabel>Select Stock File</FormLabel>

                            <FilePicker
                                onFileChange={(fileList) => { onStockFileChange(fileList) }}
                                placeholder="Select File"
                                clearButtonLabel="Clear"
                                multipleFiles={false}
                                accept=".xls,.xlsx,.csv"
                                hideClearButton={false}
                            />

                        </FormControl>
                    </Box>
                    <Box width={"20%"} paddingTop='8' paddingLeft='5'>
                        <ButtonGroup variant='outline' spacing='4' >
                            <Button type='button' onClick={(e) => UploadFile(e)} colorScheme="whatsapp">Upload</Button>
                        </ButtonGroup>
                    </Box>
                    <Box width={"full"} paddingTop='8' >
                        <ButtonGroup variant='outline' spacing='4' >
                            <Button type='button' onClick={(e) => DownloadFile(e)} colorScheme="whatsapp">Download</Button>
                        </ButtonGroup>&nbsp;&nbsp;
                        <span className='NoteImageSize'>Note : All columns contains alphanumeric character, except price.  </span>
                    </Box>
                </HStack>
            </Box>
            <br />
        </ContainerComponent>
    )
}

export default StockComponent