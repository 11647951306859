import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Grid,
  GridItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Flex,
  Stack,
  Heading,
  Table,
  ButtonGroup,
  Tfoot,
  Box,
  TableContainer,
  TableCaption,
  Spacer,
  Button,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Divider,
} from "@chakra-ui/react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import * as CCOrderService from "../../Services/CCOrder/CCOrderService";
import useLoaderState from "../../Common/useLoaderState";
import CCProductDetailComponent from "./CCProductDetailComponent";
import { FaMinus, FaPlus, FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import * as CommonVariables from "../../Common/CommonVariables";
import ContainerComponent from "../Container/ContainerComponent";
import Moment from "react-moment";
import { store } from "../../Store/store";
import LoadingSpinnerComponent from "../Loader/LoadingSpinnerComponent";
function CCOrderSetupComponent() {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleProductSubmit,
    register: registerProduct,
    getValues: getValuesProduct,
    setValue: setValueProduct,
    reset: resetProduct,
    formState: { errors: errorProduct },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleCustomerSubmit,
    register: registerCustomer,
    watch,
    getValues: getValuesCustomer,
    setValue: setValueCustomer,
    reset: resetCustomer,
    formState: { errors: errorCustomer },
  } = useForm({ mode: "onBlur" });
  // const { handleSubmit: handleShipCustomerSubmit, register: registerCustomer, getValues: getValuesShipCustomer, setValue: setValueShipCustomer, reset: resetShipCustomer, formState: { errors: errorShipCustomer } } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handlePaymentSubmit,
    register: registerPayment,
    getValues: getValuesPayment,
    setValue: setValuePayment,
    reset: resetPayment,
    formState: { errors: errorPayment },
  } = useForm({ mode: "onBlur" });
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const [IsLoading] = useLoaderState();
  const [SalesOrderForm, setSalesOrderForm] = useState({}); //SalesOrderForm
  const [PaymentLinkRequestForm, setPaymentLinkRequestForm] = useState({}); //Payment
  const [PaymentLinkResponsetForm, setPaymentLinkResponseForm] = useState({}); //Payment
  const [ProductList, setProductList] = useState([]); //ProductTab
  const [ProductTATList, setProductTATList] = useState([]);
  const [CartAmount, SetCartAmount] = useState(0); //ProductTab
  const [PayableAmount, setPayableAmount] = useState(0); //ProductTab
  const [DiscountAmount, setDiscountAmount] = useState(0); //ProductTab
  const [CartDiscountAmount, setCartDiscountAmount] = useState(0); //ProductTab
  const [ProductCartListWeight, SetProductCartListWeight] = useState(0); //ProductTab
  const [DelieveryCharges, setDelieveryCharges] = useState(0); //ProductTab
  const [InstallationCharges, SetInstallationCharges] = useState(0); //ProductTabb
  const [ShowBillingAddress, SetShowBillingAddress] = useState(false);
  const [ResendLink, ShowResendLink] = useState(false);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [ShowPaymentTab, setShowPaymentTab] = useState(false);
  const [CustomerTab, SetCustomerTab] = useState(false);
  const [PayPayemntId, SetPayPayemntId] = useState("");
  const [PaymentId, setOrderPaymentId] = useState("");
  const [OrderPaymentDetails, setOrderPaymentDetails] = useState({});

  const [IsPaymentTab, setIsPaymentTab] = useState(true);

  const [ActiveBookingSourceList, setActiveBookingSourceList] = useState([]); //Paymentd
  const [FilteredBookingSourceList, setFilteredBookingSourceList] = useState(
    {}
  ); //Payment
  const [HidePaymentLinkAction, setHidePaymentLinkAction] = useState(false); //Payment

  const [ActiveTechListInSC, setActiveTechListInSC] = useState([]); //TechList
  const userInfo = store.getState();

  const [ActiveOrderBookingSourceList, setActiveOrderBookingSourceList] =
    useState([]); //
  const [HideVoucherApplyButton, setHideVoucherApplyButton] = useState(false);
  const [MobileNumber, setMobileNumber] = useState("");
  const [AddressList, setAddressList] = useState([]);
  const [ShowAddressList, setShowAddressList] = useState(false);
  const [DisableAddressFeilds, setDisableAddressFeilds] = useState(false);

  useEffect(() => { }, []);

  const CheckPinCodeAvailablity = () => {
    debugger;
    var Pincode = getValuesProduct("ChkPincode");
    if (Pincode != "") {
      CCOrderService.CheckPinCodeAvailablity(Pincode)
        .then((data) => {
          if (data.data.IsSuccess) {
            console.log(data.data.Data);
            setProductList(data.data.Data.response);
            setProductTATList(data.data.Data.pincodelisttat);
            setValueCustomer("Pincode", Pincode);
          } else {
            setProductList([]);
            setProductTATList([]);
            toast.error(
              "We are Sorry we are not serving this pincode. Try other pincode."
            );
          }
        })
        .catch((error) => { });
    } else {
      toast.error("Enter Pincode First");
    }
  };

  const AddSubtractProductCounter = (e, data, action, index) => {
    e.preventDefault();
    var tempData = ProductList;
    debugger;
    if (
      (tempData[index].Counter >= 0 && action == "plus") ||
      (tempData[index].Counter > 0 && action == "minus")
    ) {
      if (action == "plus") {
        tempData[index] = {
          ...tempData[index],
          Counter: tempData[index].Counter + 1,
        };
        tempData[index] = {
          ...tempData[index],
          StockCounterAmount:
            tempData[index].Counter * tempData[index].Discounted_Price,
          Installation_Charges:
            tempData[index].Counter * tempData[index].Installation_Charges,
          Product_Weight:
            tempData[index].Counter * tempData[index].Product_Weight,
          Price_Per_QuantityAmount:
            tempData[index].Counter * tempData[index].Price_Per_Quantity,
        };
      } else {
        tempData[index] = {
          ...tempData[index],
          Counter: tempData[index].Counter - 1,
        };
        tempData[index] = {
          ...tempData[index],
          StockCounterAmount:
            tempData[index].Counter * tempData[index].Discounted_Price,
          Installation_Charges:
            tempData[index].Counter * tempData[index].Installation_Charges,
          Product_Weight:
            tempData[index].Counter * tempData[index].Product_Weight,
          Price_Per_QuantityAmount:
            tempData[index].Counter * tempData[index].Price_Per_Quantity,
        };
      }
      // if (tempData[index].Counter >= 0) {
      setProductList(tempData);

      let Amount = 0;
      let Weight = 0;
      let cartAmount = 0;
      let installationcharges = 0;
      ProductList.forEach((d, index) => {
        if (d.StockCounterAmount > 0) {
          Amount = Amount + d.StockCounterAmount;
        }
        if (d.Product_Weight > 0) {
          Weight = Weight + d.Product_Weight;
        }
        if (d.Price_Per_QuantityAmount > 0) {
          cartAmount = cartAmount + d.Price_Per_QuantityAmount;
        }
        if (d.StockCounterAmount > 0) {
          installationcharges = installationcharges + d.Installation_Charges;
        }
      });

      SetCartAmount(cartAmount);
      setPayableAmount(Amount);
      setCartDiscountAmount(cartAmount - Amount);
      SetProductCartListWeight(Weight);
      SetInstallationCharges(installationcharges);
      setDiscountAmount(0);
      console.log(Amount);
      setIsPaymentTab(Amount > 0 ? false : true);
    } else {
      toast.error("Quantity cannot be less than 0");
    }
  };

  const OnVoucherChange = (e) => {
    e.preventDefault();
    debugger;
    if (e.target.value == "100% OFF") {
      setDiscountAmount((100 * (CartAmount - CartDiscountAmount)) / 100);
      setPayableAmount(0);
      setHidePaymentLinkAction(true);
    } else if (e.target.value == "50% OFF") {
      setDiscountAmount(
        CartAmount -
        CartDiscountAmount -
        (50 * (CartAmount - CartDiscountAmount)) / 100
      );
      setPayableAmount((50 * (CartAmount - CartDiscountAmount)) / 100);
    } else if (e.target.value == "25% OFF") {
      setDiscountAmount((25 * (CartAmount - CartDiscountAmount)) / 100);
      setPayableAmount(
        CartAmount -
        CartDiscountAmount -
        (25 * (CartAmount - CartDiscountAmount)) / 100
      );
    }
    // else if (e.target.value == "99% OFF") {
    //     setDiscountAmount(CartAmount-(CartAmount-1));
    //     setPayableAmount(CartAmount-(CartAmount-1));
    // }
    else {
      setDiscountAmount(0);
      setPayableAmount(CartAmount - CartDiscountAmount);
    }
  };

  const OnBookingSourceChange = (e) => {
    e.preventDefault();
    debugger;
    setShowPaymentTab(true);
    setValuePayment("Voucher", "");
    setDiscountAmount(0);
    setPayableAmount(CartAmount - CartDiscountAmount);
    if (e.target.value != "") {
      var FilteredBookingSource = ActiveBookingSourceList.filter(
        (d) => d.Source_Name == e.target.value
      );

      if (!FilteredBookingSource[0].Send_Payment_Link) {
        setHidePaymentLinkAction(true);
        setOrderPaymentId(FilteredBookingSource[0].Source_Name);
      } else {
        setOrderPaymentId("");
        setHidePaymentLinkAction(false);
      }
    } else {
      setOrderPaymentId("");
      setHidePaymentLinkAction(false);
    }
  };

  const SaveSalesCustomerDetails = (e) => {
    e.preventDefault();
    debugger;
    if (ValidateForm()) {
      const values = getValuesCustomer();
      const SalesOrderModel = SalesOrderForm;
      SalesOrderModel.CustomerAddress = values;
      SalesOrderModel.HomeProduct = ProductList.filter((d) => d.Counter > 0);
      SalesOrderModel.Pincode = getValuesProduct("ChkPincode");
      SalesOrderModel.CartAmount = CartAmount;
      SalesOrderModel.PayableAmount = PayableAmount;
      SalesOrderModel.DiscountAmount = DiscountAmount + CartDiscountAmount;
      SalesOrderModel.DelieveryCharges = DelieveryCharges;
      SalesOrderModel.InstallationCharges = InstallationCharges;
      SalesOrderModel.VoucherCode = (getValuesPayment("Voucher") == "" || getValuesPayment("Voucher") == null || getValuesPayment("Voucher") == undefined) ? getValuesPayment("VoucherCode") : getValuesPayment("Voucher");
      SalesOrderModel.PaymentId = PaymentId ?? "";
      SalesOrderModel.PayAmount = OrderPaymentDetails.amount ?? 0;
      SalesOrderModel.PayMethod = OrderPaymentDetails.method ?? "";
      SalesOrderModel.PayStatus = OrderPaymentDetails.status ?? "";
      SalesOrderModel.SFDC_OrderNo = getValuesPayment("SFDC_OrderNo") ?? "";
      SalesOrderModel.Booking_Source = getValuesPayment("Source_Id") ?? "";
      SalesOrderModel.Referred_By_Technician =
        getValuesPayment("Referred_By_Technician") ?? "";
      SalesOrderModel.Order_Source =
        userInfo.userData.Role_Name != "Service Center"
          ? getValuesPayment("Order_Source") ?? ""
          : "Service Center";
      SalesOrderModel.Payment_LinkId = PayPayemntId;
      debugger;
      var data = ProductTATList.filter(a => a.Is_Selected == true);

      SalesOrderModel.Expected_Delivery_Partner = data[0].Delivery_Partner;

      console.log(SalesOrderModel);
      CCOrderService.SaveSalesOrder(SalesOrderModel)
        .then((data) => {
          if (data.data.IsSuccess) {
            toast.success("Order Save Successfully");
            window.location.reload(false);
          } else {
            debugger;
            toast.error(data.data.ResponseMessage);
          }
        })
        .catch((error) => {
          debugger;
          toast.error("Something went worng!");
        });
    }
  };

  const ApplyManualVoucherCode = (e) => {
    debugger;
    e.preventDefault();
    const SalesOrderModel = SalesOrderForm;
    SalesOrderModel.VoucherCode = getValuesPayment("VoucherCode");
    if (SalesOrderModel.VoucherCode != "" && SalesOrderModel.VoucherCode.length > 0) {
      SalesOrderModel.HomeProduct = ProductList.filter((d) => d.Counter > 0);
      SalesOrderModel.CartAmount = CartAmount;
      SalesOrderModel.PayableAmount = PayableAmount;
      SalesOrderModel.DiscountAmount = DiscountAmount + CartDiscountAmount;
      SalesOrderModel.DelieveryCharges = DelieveryCharges;
      SalesOrderModel.InstallationCharges = InstallationCharges;
      CCOrderService.ApplyVoucherByVoucherCode(SalesOrderModel)
        .then((data) => {
          debugger;
          if (data.data.IsSuccess) {
            debugger;
            toast.success("Voucher Applied");
            setHideVoucherApplyButton(true);
            setValuePayment("Voucher", "");
            console.log("Voucher Detail", data.data.Data);
            setDiscountAmount(data.data.Data.VoucherAmount);
            setPayableAmount(data.data.Data.PayableAmount);
            SetPayPayemntId("");
            ShowResendLink(false);

          } else {
            debugger;
            setValuePayment("VoucherCode", "");
            SalesOrderModel.VoucherCode = "";
            toast.error(data.data.ResponseMessage);
          }
        })
        .catch((error) => {
          debugger;
          toast.error("Something went worng!");
        });
    }
    else {
      toast.error("Please Enter Voucher Code!");
    }
  };

  const RemoveVoucherCode = (e) => {
    debugger;
    e.preventDefault();
    const SalesOrderModel = SalesOrderForm;
    setValuePayment("VoucherCode", "");
    setValuePayment("Voucher", "");
    setDiscountAmount(0);
    setPayableAmount(CartAmount - CartDiscountAmount);
    setHideVoucherApplyButton(false);
    SetPayPayemntId("");
    ShowResendLink(false);
  }

  const ValidateForm = () => {
    debugger;
    if (getValuesProduct("ChkPincode") == "") {
      debugger;
      toast.error("Please Enter Pincode");
      return false;
    }
    if (CartAmount <= 0) {
      toast.error("Cart could not be empty");
      return false;
    }

    const values = getValuesCustomer();

    if (
      values.ShipTo_Contact_Person_Name == "" ||
      values.ShipTo_Contact_Person_Name == undefined
    ) {
      toast.error("Shipping Contact Person Name could not be empty");
      return false;
    }
    if (
      values.ShipTo_Contact_Person_Mobile == "" ||
      values.ShipTo_Contact_Person_Mobile == undefined
    ) {
      toast.error("Shipping Contact Person Mobile could not be empty");
      return false;
    }
    if (
      values.ShipTo_Contact_Person_Email == "" ||
      values.ShipTo_Contact_Person_Email == undefined
    ) {
      toast.error("Shipping Contact Person Email could not be empty");
      return false;
    }
    if (
      values.ShipTo_AddressType == "" ||
      values.ShipTo_AddressType == undefined
    ) {
      toast.error("Shipping Address Type could not be empty");
      return false;
    }
    if (
      values.ShipTo_Building_Name == "" ||
      values.ShipTo_Building_Name == undefined
    ) {
      toast.error("Shipping Building Name could not be empty");
      return false;
    }
    if (values.ShipTo_Flat_No == "" || values.ShipTo_Flat_No == undefined) {
      toast.error("Shipping Flat No could not be empty");
      return false;
    }
    if (values.ShipTo_Street == "" || values.ShipTo_Street == undefined) {
      toast.error("Shipping Street could not be empty");
      return false;
    }
    if (values.ShipTo_Locality == "" || values.ShipTo_Locality == undefined) {
      toast.error("Shipping Locality could not be empty");
      return false;
    }
    if (values.ShipTo_Landmark == "" || values.ShipTo_Landmark == undefined) {
      toast.error("Shipping Landmark could not be empty");
      return false;
    }
    if (values.ShipTo_City == "" || values.ShipTo_City == undefined) {
      toast.error("Shipping City could not be empty");
      return false;
    }
    if (!values.BillTo_IsDefault) {
      if (
        values.BillTo_Contact_Person_Name == "" ||
        values.BillTo_Contact_Person_Name == undefined
      ) {
        toast.error("Billing Contact Person Name could not be empty");
        return false;
      }
      if (
        values.BillTo_Contact_Person_Mobile == "" ||
        values.BillTo_Contact_Person_Mobile == undefined
      ) {
        toast.error("Billing Contact Person Mobile could not be empty");
        return false;
      }
      if (
        values.BillTo_Contact_Person_Email == "" ||
        values.BillTo_Contact_Person_Email == undefined
      ) {
        toast.error("Billing Contact Person Email could not be empty");
        return false;
      }
      if (
        values.BillTo_Building_Name == "" ||
        values.BillTo_Building_Name == undefined
      ) {
        toast.error("Billing Building Name could not be empty");
        return false;
      }
      if (values.BillTo_Flat_No == "" || values.BillTo_Flat_No == undefined) {
        toast.error("Billing Flat No could not be empty");
        return false;
      }
      if (values.BillTo_Street == "" || values.BillTo_Street == undefined) {
        toast.error("Billing Street could not be empty");
        return false;
      }
      if (values.BillTo_Locality == "" || values.BillTo_Locality == undefined) {
        toast.error("Billing Locality could not be empty");
        return false;
      }
      if (values.BillTo_Landmark == "" || values.BillTo_Landmark == undefined) {
        toast.error("Billing Landmark could not be empty");
        return false;
      }
      if (values.BillTo_City == "" || values.BillTo_City == undefined) {
        toast.error("Billing City could not be empty");
        return false;
      }
      if (values.BillTo_Pincode == "" || values.BillTo_Pincode == undefined) {
        toast.error("Billing Pincode could not be empty");
        return false;
      }
      if (
        values.BillTo_Pincode != getValuesProduct("ChkPincode") &&
        values.BillTo_IsDefault
      ) {
        toast.error("Billing Pincode and Search Pincode should be same");
        return false;
      }
    }

    if (getValuesPayment("Source_Id") == "") {
      toast.error("Booking source selection is mandatory!");
      return false;
    }
    return true;
  };

  const OnCheckSameAddress = () => {
    debugger;
    SetShowBillingAddress(!ShowBillingAddress);
  };
  const handleTabsChange = (index) => {
    debugger;
    if (index == 0 && getValuesProduct("ChkPincode") == "") {
      setProductList([]);
      toast.error("Please Enter Pincode");
    }

    if (index == 1) {
      if (getValuesProduct("ChkPincode") != "") {
        SetCustomerTab(true);
        let pincode = getValuesProduct("ChkPincode");
        setValueCustomer("ShipTo_Pincode", pincode);
      } else {
        debugger;
        SetCustomerTab(false);
        toast.error("Please Enter Pincode");
      }
    }
    if (index == 2) {
      if (
        ProductCartListWeight > 0 &&
        CartAmount > 0 &&
        getValuesProduct("ChkPincode") != ""
      ) {
        GetDeliveryCharges();
        GetActiveBookingSourceList();
        GetActiveOrderBookingSourceList();
        GetActiveTechnicianMappedinSC(userInfo.userData.First_Name);
      } else {
        toast.error("Cart is Empty");
        setShowPaymentTab(false);
      }
    }
    return false;
  };

  const GetDeliveryCharges = () => {
    CCOrderService.GetDeliveryCharges(
      getValuesProduct("ChkPincode"),
      ProductCartListWeight
    )
      .then((data) => {
        if (data.data.IsSuccess) {
          // setShowPaymentTab(true);
          setDelieveryCharges(data.data.Data);
        } else {
          toast.error("Something went worng!");
        }
      })
      .catch((error) => {
        toast.error("Something went worng!");
      });
  };

  const GetActiveBookingSourceList = () => {
    CCOrderService.GetActiveBookingSourceList()
      .then((data) => {
        if (data.data.IsSuccess) {
          setActiveBookingSourceList(data.data.Data);
        } else {
          toast.error("Something went worng!");
        }
      })
      .catch((error) => {
        toast.error("Something went worng!");
      });
  };

  const GetActiveOrderBookingSourceList = () => {
    debugger;
    CCOrderService.GetActiveOrderBookingSourceList()
      .then((data) => {
        if (data.data.IsSuccess) {
          debugger;
          setActiveOrderBookingSourceList(data.data.Data);
        } else {
          toast.error("Something went worng!");
        }
      })
      .catch((error) => {
        toast.error("Something went worng!");
      });
  };
  const GetActiveTechnicianMappedinSC = (scusername) => {
    CCOrderService.GetActiveTechnicianMappedinSC(scusername)
      .then((data) => {
        debugger;
        if (data.data.IsSuccess) {
          setActiveTechListInSC(data.data.Data);
        } else {
          setActiveTechListInSC([]);
          // toast.error("Something went worng!")
        }
      })
      .catch((error) => {
        setActiveTechListInSC([]);
        // toast.error("Something went worng!")
      });
  };

  const SendPaymentLink = (e) => {
    e.preventDefault();
    debugger;
    if (ValidateForm()) {
      const values = getValuesCustomer();
      const PaymentLinkRequestModel = PaymentLinkRequestForm;
      PaymentLinkRequestModel.Amount = PayableAmount;
      PaymentLinkRequestModel.ProductName = "HiCare Products";
      PaymentLinkRequestModel.CustomerName =
        values.ShipTo_Contact_Person_Name ?? values.BillTo_Contact_Person_Name;
      PaymentLinkRequestModel.CustomerMobile =
        values.ShipTo_Contact_Person_Mobile ??
        values.BillTo_Contact_Person_Mobile;
      PaymentLinkRequestModel.CustomerEmail =
        values.ShipTo_Contact_Person_Email ??
        values.BillTo_Contact_Person_Email;
      PaymentLinkRequestModel.FlatNo =
        values.ShipTo_Flat_No ?? values.BillTo_Flat_No;
      PaymentLinkRequestModel.BuildingName =
        values.ShipTo_Building_Name ?? values.BillTo_Building_Name;
      PaymentLinkRequestModel.Street =
        values.ShipTo_Street ?? values.ShipTo_Street;
      PaymentLinkRequestModel.Landmark =
        values.ShipTo_Landmark ?? values.BillTo_Landmark;
      PaymentLinkRequestModel.Pincode =
        values.ShipTo_Pincode ?? values.BillTo_Pincode;
      PaymentLinkRequestModel.City = values.ShipTo_City ?? values.BillTo_City;
      PaymentLinkRequestModel.Locality =
        values.ShipTo_Locality ?? values.BillTo_Locality;
      console.log(PaymentLinkRequestModel);
      CCOrderService.SendPaymentLink(PaymentLinkRequestModel)
        .then((data) => {
          if (data.data.IsSuccess && data.data.Data.id != null) {
            toast.success(data.data.ResponseMessage);
            setPaymentLinkResponseForm(data.data.Data);
            ShowResendLink(true);
            SetPayPayemntId(data.data.Data.id);
            console.log("Payment Link");
            console.log(data.data.Data);
          } else {
            toast.error(data.data.ResponseMessage);
          }
        })
        .catch((error) => {
          debugger;
          toast.error("Something went worng!");
        });
    }
  };

  const ReSendPaymentLink = (e) => {
    e.preventDefault();
    debugger;

    CCOrderService.ReSendPaymentLink(PayPayemntId)
      .then((data) => {
        if (data.data.IsSuccess) {
          toast.success(data.data.ResponseMessage);
          console.log("Resend Payment Link");
          console.log(data.data.Data);
          // SetPayPayemntId(data.data.Data.id);
        } else {
          debugger;
          toast.error("Something went worng!");
        }
      })
      .catch((error) => {
        debugger;
        toast.error("Something went worng!");
      });
  };

  const CheckPaymentStatus = (e) => {
    e.preventDefault();
    debugger;
    if (PayPayemntId != "") {
      CCOrderService.CheckPaymentStatus(PayPayemntId)
        .then((data) => {
          debugger;
          console.log(data.data.Data);
          if (data.data.IsSuccess && data.data.Data.length > 0) {
            if ((data.data.Data[0].status = "captured")) {
              toast.success(data.data.ResponseMessage);
              console.log("Check Payment Status");
              console.log(data.data.Data);
              setOrderPaymentId(data.data.Data[0].payment_id);
              setOrderPaymentDetails(data.data.Data[0]);
            } else {
              toast.error("Payment Not Done!");
            }
          } else {
            debugger;
            toast.error("Payment Not Done!");
          }
        })
        .catch((error) => {
          debugger;
          toast.error("Something went worng!");
        });
    } else {
      toast.error("Please Send Payment Notification first");
    }
  };

  const OnPinCodeChange = (e) => {
    if (e.keyCode == 8 || e.keyCode == 46) {
      setValueProduct("ChkPincode", "");
      setProductList([]);
    }
  };

  const handleMobileNoChange = (e) => {
    setMobileNumber(e.target.value);
  }

  const handleMobileNoBlur = () => {
    debugger;
    CCOrderService.GetAddressByMobileNumber(MobileNumber, getValuesProduct("ChkPincode")).then((data) => {
      debugger;
      if (data.data.IsSuccess) {
        toast.success(data.data.ResponseMessage);
        console.log(data.data.Data);
        setAddressList(data.data.Data);
        setShowAddressList(true);
      }
      else {
        toast.error(data.data.ResponseMessage);
        setShowAddressList(false);
      }

    })
  }

  const onAddressSelection = (addressId) => {
    debugger;
    console.log(addressId);
    if (addressId != "") {
      var addressDetail = AddressList.filter(x => x.Id == addressId)[0];
      console.log(addressDetail);
      setValueCustomer("AddressId", addressDetail.Id);
      setValueCustomer("ShipTo_Contact_Person_Name", addressDetail.Contact_Person_Name);
      setValueCustomer("ShipTo_Contact_Person_Mobile", addressDetail.Contact_Person_Mobile);
      setValueCustomer("ShipTo_Contact_Person_Email", addressDetail.Contact_Person_Email);
      setValueCustomer("ShipTo_AddressType", addressDetail.AddressType);
      setValueCustomer("ShipTo_Flat_No", addressDetail.Flat_No);
      setValueCustomer("ShipTo_Building_Name", addressDetail.Building_Name);
      setValueCustomer("ShipTo_Street", addressDetail.Street);
      setValueCustomer("ShipTo_Locality", addressDetail.Locality);
      setValueCustomer("ShipTo_Landmark", addressDetail.Landmark);
      setValueCustomer("ShipTo_City", addressDetail.City);
      setValueCustomer("ShipTo_Pincode", addressDetail.Pincode);
      setValueCustomer("BillTo_IsDefault", addressDetail.IsDefault);
      setDisableAddressFeilds(true);
    }
    else {
      setDisableAddressFeilds(false);
      setValueCustomer("AddressId", 0);
      setValueCustomer("ShipTo_Contact_Person_Name", null);
      setValueCustomer("ShipTo_Contact_Person_Mobile", null);
      setValueCustomer("ShipTo_Contact_Person_Email", null);
      setValueCustomer("ShipTo_AddressType", "");
      setValueCustomer("ShipTo_Flat_No", null);
      setValueCustomer("ShipTo_Building_Name", null);
      setValueCustomer("ShipTo_Street", null);
      setValueCustomer("ShipTo_Locality", null);
      setValueCustomer("ShipTo_Landmark", null);
      setValueCustomer("ShipTo_City", null);
      setValueCustomer("ShipTo_Pincode", getValuesProduct("ChkPincode"));
      setValueCustomer("BillTo_IsDefault", null);
    }



  }

  const setCheckedItems = (partner) => {
    debugger;

    const data = ProductTATList.map(a => {
      if (a.Delivery_Partner === partner) {
        a.Is_Selected = !a.Is_Selected;
        return a;
      } else {
        a.Is_Selected = false;
        return a;
      }
    });
    setProductTATList(data);

  }

  return (
    <>
      <LoadingSpinnerComponent></LoadingSpinnerComponent>
      <HStack>
        <Box width={"80%"}>
          <FormControl>
            <FormLabel>Pincode</FormLabel>
            <Input
              autoComplete="Off"
              onKeyDown={(e) => OnPinCodeChange(e)}
              placeholder="Enter Pincode"
              {...registerProduct("ChkPincode")}
            />
          </FormControl>
        </Box>
        <Box
          width={"full"}
          paddingTop={30}
          paddingLeft={30}>
          <Button
            colorScheme="red"
            onClick={() => CheckPinCodeAvailablity()}>
            Check Pincode
          </Button>
        </Box>
        <Box width={"full"}
          paddingTop={30}
          paddingLeft={30}>
          {ProductTATList.map((data, index) => {
            return (
              <Box key={index}>
                <Box>
                  <Checkbox colorScheme='green' isChecked={data.Is_Selected} isReadOnly={false} onChange={(e) => setCheckedItems(data.Delivery_Partner)}>
                    {data.ETA_STATEMENT}</Checkbox>
                </Box>
              </Box>)
          })}
        </Box>
      </HStack>
      <Tabs onChange={(index) => handleTabsChange(index)}>
        <TabList>
          <Tab>CC Product Info</Tab>
          <Tab>Customer Detail</Tab>
          <Tab isDisabled={IsPaymentTab}>Payment</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Stack spacing={4}>
              <>
                <TableContainer>
                  <Table
                    size="sm"
                    variant="simple">
                    <Thead align="center">
                      <Tr>
                        <Th width="8%"></Th>
                        <Th
                          size="sm"
                        >
                          Product Name
                        </Th>
                        <Th>Price </Th>
                        <Th width="12%">Discount Price</Th>
                        <Th width="12%">Action</Th>
                        <Th width="12%">Amount</Th>
                      </Tr>
                    </Thead>
                    <Tbody >
                      {ProductList.map((data, index) => {
                        return (
                          <Tr key={index}>
                            <Td>
                              <a href={data.Product_Thumbnail}>
                                <img
                                  src={data.Product_Thumbnail}
                                  width="50px"
                                  height="50px"
                                />
                              </a>
                            </Td>
                            <Td>{data.Product_Name}
                              {
                                (data.StockCount <= 10 && data.Is_Stock_Available && data.StockCount != 0) ?
                                  <><br /><span className="NoteImageSize">Only {data.StockCount} Remaining</span></>
                                  : <></>
                              }
                              {
                                (!data.Is_Stock_Available || (data.StockCount <= 0)) ?
                                  <><br /><span className="NoteImageSize">Out Of Stock</span></>
                                  : <></>
                              }
                            </Td>
                            <Td>{data.Price_Per_Quantity}</Td>
                            <Td>{data.Discounted_Price}</Td>
                            <Td align="center">
                              {
                                (!data.Is_Stock_Available || (data.StockCount <= 0)) ?
                                  <></>
                                  :
                                  <ButtonGroup size='sm' isAttached variant='outline'>
                                    <Button size='sm' colorScheme='red' onClick={(e) => AddSubtractProductCounter(e, data, "minus", index)} variant='outline'><FaMinusCircle color="red" /></Button>
                                    <Button size='sm' colorScheme='black' variant='outline'>{data.Counter}</Button>
                                    <Button size='sm' colorScheme='green' onClick={(e) => AddSubtractProductCounter(e, data, "plus", index)} variant='outline'><FaPlusCircle color="green" /> </Button>
                                  </ButtonGroup>
                              }
                            </Td>
                            <Td>
                              {
                                (!data.Is_Stock_Available || (data.StockCount <= 0)) ?
                                  <></>
                                  :
                                  <> ₹ {data.StockCounterAmount} </>
                              }
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                    {ProductList.length <= 0 ? (
                      <Tfoot>
                        <Tr colSpan="12">
                          <Td colSpan="12">No Data Available</Td>
                        </Tr>
                      </Tfoot>
                    ) : (
                      <></>
                    )}
                  </Table>
                </TableContainer>
              </>
            </Stack>
          </TabPanel>
          <TabPanel>
            {CustomerTab ? (
              <Grid templateColumns="repeat(2, 1fr)">
                <GridItem w="85%">
                  <Stack>
                    <HStack>
                      <Box
                        width={"full"}
                        backgroundColor="green"
                        alignItems="center"
                        justifyContent="center">
                        <FormControl
                          paddingLeft={160}
                          paddingTop={2}>
                          <FormLabel color="white">Shipping Address</FormLabel>
                        </FormControl>
                      </Box>
                    </HStack>

                    <HStack>
                      <Box width={"full"}>
                        <FormControl>
                          <FormLabel>Enter Mobile No</FormLabel>
                          <Input
                            type="number"
                            autoComplete="Off"
                            placeholder="Enter Mobile No"
                            onChange={handleMobileNoChange}
                            onBlur={handleMobileNoBlur}
                          />
                        </FormControl>
                      </Box>
                      {ShowAddressList ? (
                        <Box width={"full"}>
                          <FormControl>
                            <FormLabel>Address List</FormLabel>
                            <Select
                              className="form-control"
                              onChange={(e) => onAddressSelection(e.target.value)}
                            >
                              <option value="">Select Address</option>
                              {AddressList.map((data, index) => {
                                return (
                                  <option
                                    value={data.Id}
                                    key={index}
                                  >
                                    {data.Building_Name + "/" + data.Flat_No}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>) : (<></>)
                      }

                    </HStack>

                    <HStack>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>Customer Name</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter Name"
                            {...registerCustomer("ShipTo_Contact_Person_Name")}
                          />
                        </FormControl>
                      </Box>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>Mobile No</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            type="number"
                            autoComplete="Off"
                            placeholder="Enter Mobile No"
                            {...registerCustomer(
                              "ShipTo_Contact_Person_Mobile"
                            )}
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>Email Id</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter Email Id"
                            {...registerCustomer("ShipTo_Contact_Person_Email")}
                          />
                        </FormControl>
                      </Box>
                      <Box width={"full"}>
                        <FormControl>
                          <FormLabel>Address Type</FormLabel>
                          <Select
                            isDisabled={DisableAddressFeilds}
                            className="form-control"
                            {...registerCustomer("ShipTo_AddressType")}>
                            <option value="">Select AddressType</option>
                            {CommonVariables.AddressType.map((data, index) => {
                              return (
                                <option
                                  value={data.Value}
                                  key={index}>
                                  {data.Key}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </HStack>

                    <HStack paddingTop={"3"}>
                      <Box width={500}>
                        <FormControl isRequired>
                          <FormLabel>Flat No</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter Flat No"
                            {...registerCustomer("ShipTo_Flat_No")}
                          />
                        </FormControl>
                      </Box>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>Building Name</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter Building Name"
                            {...registerCustomer("ShipTo_Building_Name")}
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                    <HStack paddingTop={"3"}>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>Street Name</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter Street Name"
                            {...registerCustomer("ShipTo_Street")}
                          />
                        </FormControl>
                      </Box>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>Locality</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter Locality"
                            {...registerCustomer("ShipTo_Locality")}
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                    <HStack paddingTop={"3"}>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>LandMark</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter Landmark"
                            {...registerCustomer("ShipTo_Landmark")}
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                    <HStack paddingTop={"3"}>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>City</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter City"
                            {...registerCustomer("ShipTo_City")}
                          />
                        </FormControl>
                      </Box>
                      <Box width={"full"}>
                        <FormControl isRequired>
                          <FormLabel>Pincode</FormLabel>
                          <Input
                            readOnly
                            autoComplete="Off"
                            placeholder="Enter Pincode"
                            {...registerCustomer("ShipTo_Pincode")}
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width={"full"}>
                        <FormControl>
                          <FormLabel>GST No</FormLabel>
                          <Input
                            isDisabled={DisableAddressFeilds}
                            autoComplete="Off"
                            placeholder="Enter GST No"
                            {...registerCustomer("ShipTo_GSTNo")}
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                  </Stack>
                </GridItem>

                <GridItem w="85%">
                  <Stack>
                    <HStack>
                      <Box
                        backgroundColor="green"
                        width={"full"}
                        alignItems="center"
                        justifyContent="center">
                        <FormControl
                          paddingLeft={160}
                          paddingTop={2}>
                          <FormLabel color="white">Billing Address</FormLabel>
                        </FormControl>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width={"full"}>
                        <FormControl>
                          <FormLabel>Same as Shipping Address</FormLabel>
                        </FormControl>
                      </Box>
                      <Box width={"full"}>
                        <FormControl>
                          <Checkbox
                            colorScheme="green"
                            {...registerCustomer("BillTo_IsDefault")}
                            onChange={OnCheckSameAddress}
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                    {ShowBillingAddress ? (
                      <></>
                    ) : (
                      <>
                        <HStack>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>Customer Name</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Name"
                                {...registerCustomer(
                                  "BillTo_Contact_Person_Name"
                                )}
                              />
                            </FormControl>
                          </Box>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>Mobile No</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Mobile No"
                                {...registerCustomer(
                                  "BillTo_Contact_Person_Mobile"
                                )}
                              />
                            </FormControl>
                          </Box>
                        </HStack>
                        <HStack>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>Email Id</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Email Id"
                                {...registerCustomer(
                                  "BillTo_Contact_Person_Email"
                                )}
                              />
                            </FormControl>
                          </Box>
                        </HStack>
                        <HStack paddingTop={"3"}>
                          <Box width={500}>
                            <FormControl isRequired>
                              <FormLabel>Flat No</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Flat No"
                                {...registerCustomer("BillTo_Flat_No")}
                              />
                            </FormControl>
                          </Box>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>Building Name</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Building Name"
                                {...registerCustomer("BillTo_Building_Name")}
                              />
                            </FormControl>
                          </Box>
                        </HStack>
                        <HStack paddingTop={"3"}>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>Street Name</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Street Name"
                                {...registerCustomer("BillTo_Street")}
                              />
                            </FormControl>
                          </Box>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>Locality</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Locality"
                                {...registerCustomer("BillTo_Locality")}
                              />
                            </FormControl>
                          </Box>
                        </HStack>
                        <HStack paddingTop={"3"}>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>LandMark</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Landmark"
                                {...registerCustomer("BillTo_Landmark")}
                              />
                            </FormControl>
                          </Box>
                        </HStack>
                        <HStack paddingTop={"3"}>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>City</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter City"
                                {...registerCustomer("BillTo_City")}
                              />
                            </FormControl>
                          </Box>
                          <Box width={"full"}>
                            <FormControl isRequired>
                              <FormLabel>Pincode</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter Pincode"
                                {...registerCustomer("BillTo_Pincode")}
                              />
                            </FormControl>
                          </Box>
                        </HStack>
                        <HStack>
                          <Box width={"full"}>
                            <FormControl>
                              <FormLabel>GST No</FormLabel>
                              <Input
                                autoComplete="Off"
                                placeholder="Enter GST No"
                                {...registerCustomer("BillTo_GSTNo")}
                              />
                            </FormControl>
                          </Box>
                        </HStack>
                      </>
                    )}
                  </Stack>
                </GridItem>
              </Grid>
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel>
            <Stack>
              <HStack>
                {userInfo.userData.Role_Name != "Service Center" ? (
                  <>
                    <Box width={"full"}>
                      <FormControl>
                        <FormLabel>Select Order Source :</FormLabel>
                        <Select
                          className="form-control"
                          {...registerPayment("Order_Source")}>
                          <option value="">Select Order Source</option>
                          {ActiveOrderBookingSourceList.map((data, index) => {
                            return (
                              <option
                                value={data.Key}
                                key={index}>
                                {data.Value}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box width={"full"}>
                      <FormControl>
                        <FormLabel>Select Booking Source :</FormLabel>
                        <Select
                          className="form-control"
                          {...registerPayment("Source_Id")}
                          onChange={(e) => OnBookingSourceChange(e)}>
                          <option value="">Select Booking Source</option>
                          {ActiveBookingSourceList.map((data, index) => {
                            return (
                              <option
                                value={data.Source_Name}
                                key={index}>
                                {data.Source_Name}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box width={"full"}>
                      <FormControl>
                        <FormLabel>Select Technician :</FormLabel>
                        <Select
                          className="form-control"
                          {...registerPayment("Referred_By_Technician")}>
                          <option value="">Select Technician</option>
                          {ActiveTechListInSC.map((data, index) => {
                            return (
                              <option
                                value={data.Name}
                                key={index}>
                                {data.Name}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box width={"full"}>
                      <FormControl>
                        <FormLabel>Select Booking Source :</FormLabel>
                        <Select
                          className="form-control"
                          {...registerPayment("Source_Id")}
                          onChange={(e) => OnBookingSourceChange(e)}>
                          <option value="">Select Booking Source</option>
                          {ActiveBookingSourceList.map((data, index) => {
                            return (
                              <option
                                value={data.Source_Name}
                                key={index}>
                                {data.Source_Name}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>Voucher</FormLabel>
                    <Select
                      className="form-control"
                      {...registerPayment("Voucher")}
                      onChange={(e) => OnVoucherChange(e)}>
                      <option value="">Select Voucher</option>
                      {CommonVariables.VoucherList.map((data, index) => {
                        if (userInfo.userData.Role_Name == "Manager") {
                          return (
                            <option
                              value={data.Value}
                              key={index}>
                              {data.Key}
                            </option>
                          );
                        } else if (data.Value != "100% OFF") {
                          return (
                            <option
                              value={data.Value}
                              key={index}>
                              {data.Key}
                            </option>
                          );
                        }
                        // else  {
                        //     return (
                        //         <option value={data.Value} key={index}>{data.Key}</option>
                        //     )
                        // }
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>SFDC Order No : </FormLabel>
                    <Input
                      autoComplete="Off"
                      placeholder="Enter SFDC Order No"
                      {...registerPayment("SFDC_OrderNo")}
                    />
                  </FormControl>
                </Box>

                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>Apply Voucher : </FormLabel>
                    <Input
                      autoComplete="Off"
                      placeholder="Enter Voucher Code"
                      {...registerPayment("VoucherCode")}
                    />
                  </FormControl>
                </Box>
                {!HideVoucherApplyButton ? (
                  <>
                    <Box
                      width={"full"}
                      paddingTop={30}
                      paddingLeft={30}>
                      <Button
                        colorScheme="green"
                        onClick={(e) => ApplyManualVoucherCode(e)}>
                        Apply
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      width={"full"}
                      paddingTop={30}
                      paddingLeft={30}>
                      <Button
                        colorScheme="red"
                        onClick={(e) => RemoveVoucherCode(e)}>
                        Remove
                      </Button>
                    </Box>
                  </>
                )}

              </HStack>
            </Stack>
            <br />
            <Stack>
              <HStack>
                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>Cart Amount : {CartAmount}</FormLabel>
                  </FormControl>
                </Box>
              </HStack>
            </Stack>
            <Stack>
              <HStack>
                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>Discount : {CartDiscountAmount}</FormLabel>
                  </FormControl>
                </Box>
              </HStack>
            </Stack>
            {DiscountAmount > 0 ? (
              <Stack>
                <HStack>
                  <Box width={"full"}>
                    <FormControl>
                      <FormLabel>
                        Voucher Discount :{" "}
                        {DiscountAmount > 0 ? (
                          DiscountAmount
                        ) : (
                          <span style={{ color: "red" }}>
                            To avail voucher discount select voucher
                          </span>
                        )}
                      </FormLabel>
                    </FormControl>
                  </Box>
                </HStack>
              </Stack>
            ) : (
              <></>
            )}
            <Stack>
              <HStack>
                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>
                      Installation Charges : {InstallationCharges}
                    </FormLabel>
                  </FormControl>
                </Box>
              </HStack>
            </Stack>
            <Stack>
              <HStack>
                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>
                      Delivery Charges :{" "}
                      {DelieveryCharges > 0 ? (
                        DelieveryCharges
                      ) : (
                        <span style={{ color: "red" }}> Free</span>
                      )}
                    </FormLabel>
                  </FormControl>
                </Box>
              </HStack>
            </Stack>
            <Stack>
              <HStack>
                <Box width={"full"}>
                  <FormControl>
                    <FormLabel>Payable Amount : ₹ {PayableAmount}</FormLabel>
                  </FormControl>
                </Box>
              </HStack>
            </Stack>

            {ShowPaymentTab ? (
              <>
                <Stack>
                  <HStack>
                    {!HidePaymentLinkAction ? (
                      <>
                        {ResendLink ? (
                          <></>
                        ) : (
                          <Box width={"20%"}>
                            <Button
                              type="button"
                              disabled={IsLoading}
                              onClick={(e) => SendPaymentLink(e)}
                              colorScheme="whatsapp">
                              Send Payment Link
                            </Button>
                          </Box>
                        )}
                        {ResendLink ? (
                          <>
                            <Box width={"20%"}>
                              <Button
                                type="button"
                                disabled={IsLoading}
                                onClick={(e) => ReSendPaymentLink(e)}
                                colorScheme="red">
                                Re - SendPaymentLink
                              </Button>
                            </Box>
                            <Box width={"20%"}>
                              <Button
                                type="button"
                                disabled={IsLoading}
                                onClick={(e) => CheckPaymentStatus(e)}
                                colorScheme="whatsapp">
                                Check Payment Status
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {ResendLink || HidePaymentLinkAction ? (
                      <Box width={"20%"}>
                        <Button
                          type="button"
                          disabled={IsLoading}
                          onClick={(e) => SaveSalesCustomerDetails(e)}
                          colorScheme="whatsapp">
                          Save Order
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </HStack>
                </Stack>
              </>
            ) : (
              <></>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default CCOrderSetupComponent;
