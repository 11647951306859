import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Textarea,
  HStack,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Flex,
  VStack,
  Stack,
  Heading,
  Table,
  ButtonGroup,
  Tfoot,
  Box,
  TableContainer,
  TableCaption,
  Spacer,
  Button,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Divider,
} from "@chakra-ui/react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import * as CampaignService from "../../Services/Campaign/CampaignService";
import * as ProductService from "../../Services/Product/ProductService";
import useLoaderState from "../../Common/useLoaderState";
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import ContainerComponent from "../Container/ContainerComponent";
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from "../Modal/ModalComponent";
import ReactDatePicker from "react-datepicker";
import * as CommonVariables from "../../Common/CommonVariables";
import * as moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import { Tooltip } from "@chakra-ui/react";
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";

function CampaignComponent() {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleVoucherSubmit,
    register: registerVoucher,
    setValue: setValueVoucher,
    reset: resetVoucher,
    formState: { errors: errorVoucher },
  } = useForm({ mode: "onBlur" });
  
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isVoucherOpen,
    onOpen: onVoucherOpen,
    onClose: onVoucherClose,
  } = useDisclosure();
  const [CampaignList, setCampaignList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [CampaignMappingList, setCampaignMappingList] = useState([]);
  const [IsLoading] = useLoaderState();
  const [SelectedCampaign, setCampaignId] = useState(0);
  const [Campaign_Start_Date, setStartDate] = useState(new Date());
  const [Campaign_End_Date, setEndDate] = useState(new Date());
  const [ShowProduct, setShowProduct] = useState(false);

  const [SelectedProductList, setSelectedProductList] = useState([]);
  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [PopUpImage, setPopUpImage] = useState('');

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const DayCheckbox = ({ day, isChecked, onChange }) => (
    <Checkbox
      isChecked={isChecked}
      onChange={onChange}>
      {day}
    </Checkbox>
  );

  useEffect(() => {
    //debugger;
    GetCampaignList();
    GetProductList();
  }, []);

  const [selectedDays, setSelectedDays] = useState([]);
  const allDaysSelected = selectedDays.length === daysOfWeek.length;

  const handleDayChange = (selected) => {
    debugger;
    setSelectedDays(selected);
  };

  const handleAllDaysChange = (isChecked) => {
    debugger;
    if (isChecked) {
      if (allDaysSelected) {
        setSelectedDays([]);
      } else {
        setSelectedDays(daysOfWeek);
      }
    }
  };

  const PopUpFileUploadToS3 = (fileList) => {
    debugger;
    if (fileList != null && fileList.length > 0) {
      var formdata = new FormData();
      formdata.append("Myfile", fileList[0]);

      AttachmentService.FileUploadToS3(formdata)
        .then((response) => {
          if (response.data.IsSuccess) {
            toast.success(response.data.ResponseMessage);
            setPopUpImage(response.data.Data);
          } else {
            setPopUpImage("");
            toast.error(response.data.ResponseMessage);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong!");
        });
    } else {
      toast.error("Please choose Image First!");
    }
  };

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  const AddCampaign = () => {
    debugger;
    reset({});
    onAddOpen();
    console.log(ProductList);
    setSelectedProductList([]);
    setSelectedDays([]);
    setPopUpImage('');
    setValue("Id", 0);
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const GetCampaignList = () => {
    debugger;
    CampaignService.GetCampaignList()
      .then((data) => {
        debugger;
        if (data.data.IsSuccess) {
          setCampaignList(data.data.Data);
        } else {
          setCampaignList([]);
          //toast.error("Somthing went wrong.");
        }
      })
      .catch((error) => {
        //toast.error("Somthing went wrong.");
      });
  };

  const GetDayListById = (id) => {
    debugger;
    CampaignService.GetDayListById(id)
      .then((data) => {
        debugger;
        if (data.data.IsSuccess) {
          setSelectedDays(data.data.Data);
        } else {
          setSelectedDays([]);
          //toast.error("Somthing went wrong.");
        }
      })
      .catch((error) => {
        //toast.error("Somthing went wrong.");
      });
  };

  const GetProductList = () => {
    CampaignService.GetProductList()
      .then((data) => {
        if (data.data.IsSuccess) {
          setProductList(data.data.Data);
        } else {
          //toast.error("Somthing went wrong.");
        }
      })
      .catch((error) => {
        //toast.error("Somthing went wrong.");
      });
  };

  const SaveCampaign = (value) => {
    debugger;
    if (validateCampaign(value)) {
      value.ProductList = SelectedProductList;
      value.DayList = selectedDays;
      value.Image_URL = PopUpImage;
      //value.Campaign_Start_Date = moment(Campaign_Start_Date).format("YYYY-MM-DD");
      //value.Campaign_End_Date = moment(Campaign_End_Date).format("YYYY-MM-DD");
      value.Campaign_Start_Date = moment(Campaign_Start_Date).format();
      value.Campaign_End_Date = moment(Campaign_End_Date).format();
      if (parseInt(value.Id) > 0) {
        CampaignService.UpdateCampaignDetail(value)
          .then((data) => {
            debugger;
            if (data.data.IsSuccess) {
              toast.success("Campaign Update");
              onAddClose();
              GetCampaignList();
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {});
      } else {
        console.log(value);
        CampaignService.SaveCampaignDetail(value)
          .then((data) => {
            debugger;
            if (data.data.IsSuccess) {
              toast.success("Campaign Save");
              onAddClose();
              GetCampaignList();
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {});
      }
    }
  };

  const validateCampaign = (data) => {
    debugger;
    if (ShowProduct && SelectedProductList.length == 0) {
      toast.error("Select Product List");
      return false;
    }
    if (!ShowProduct && data.Minimum_Order_Value <= 0) {
      toast.error("Check value of Minimum Order Value");
      return false;
    }
    if (data.Discount_Type == "Percentage" && data.Discount_Value > 100) {
      toast.error("Discount Value cannot be greater than 100%");
      return false;
    }
    if (selectedDays.length == 0) {
      toast.error("Please select atleast one option from the day section");
      return false;
    }

    if (data.Timer_Campaign == true && data.Is_PopupCampaign == true) {
      toast.error("Please select either 'Timer_Campaign' or 'PopUp Campaign'");
      return false;
    }

    if(data.Timer_Campaign == true && PopUpImage != ''){
      toast.error("Image can be uploaded when 'PopUp Campaign' is selected.");
      return false;
    }

    if(data.Is_PopupCampaign == true && PopUpImage == ''){
      toast.error("Please upload one image.");
      return false;
    }

    return true;
  };

  const GetCampaignVocherMapping = (id) => {
    debugger;
    resetVoucher({});
    setCampaignId(id);
    CampaignService.GetCampaignVocherMapping(id)
      .then((data) => {
        if (data.data.IsSuccess) {
          debugger;
          setCampaignMappingList(data.data.Data);
          console.log(data.data.Data);
          onVoucherOpen();
        }
      })
      .catch((error) => {});
  };

  const saveVoucherDetails = (value) => {
    debugger;
    value.Campaign_Id = SelectedCampaign;
    CampaignService.SaveVoucher(value)
      .then((data) => {
        debugger;
        if (data.data.IsSuccess) {
          toast.success("Voucher Save");
          GetCampaignVocherMapping(SelectedCampaign);
        } else {
          toast.error(data.data.ResponseMessage);
        }
      })
      .catch((error) => {});
  };

  function clickHandler(stDate, endDate) {
    const startdate = new Date(stDate);
    setStartDate(startdate);
    const edDate = new Date(endDate);
    setEndDate(edDate);
  }

  const EditCampaign = (id) => {
    debugger;
    setPopUpImage('');
    CampaignService.GetCampaignById(id)
      .then((data) => {
        debugger;
        if (data.data.IsSuccess) {
          reset(data.data.Data);
          clickHandler(
            data.data.Data.Campaign_Start_Date,
            data.data.Data.Campaign_End_Date
          );
          setSelectedDays(data.data.Data.DayList);
          if(data.data.Data.Is_PopupCampaign == true){
            setPopUpImage(data.data.Data.Image_URL);
          }
          if (data.data.Data.Campaign_Type == "Product") {
            setShowProduct(true);
            setSelectedProductList(data.data.Data.ProductList);
          } else {
            setShowProduct(false);
          }
          onAddOpen();
        }
      })
      .catch((error) => {});
  };

  const DeleteCampaign = (id, IsActive) => {
    CampaignService.ActiveDeActiveCampaignDetail(id, !IsActive)
      .then((data) => {
        if (data.data.IsSuccess) {
          toast.success(data.data.ResponseMessage);
          GetCampaignList();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {});
  };

  const DeleteVoucher = (id, IsActive) => {
    debugger;
    CampaignService.ActiveDeActiveVocuherDetail(id, !IsActive)
      .then((data) => {
        if (data.data.IsSuccess) {
          toast.success(data.data.ResponseMessage);
          GetCampaignVocherMapping(SelectedCampaign);
        } else {
          GetCampaignVocherMapping([]);
        }
      })
      .catch((error) => {});
  };

  const onCampaignTypeChange = (value) => {
    // e.preventDefault();
    if (value == "Product") {
      setShowProduct(true);
      setValue("Minimum_Order_Value", 0);
    } else {
      setSelectedProductList([]);
      setShowProduct(false);
    }
  };

  const updateProductList = (data) => {
    setSelectedProductList(data);
    console.log(setSelectedProductList);
  };

  return (
    <ContainerComponent
      Title="Campaign"
      Icon={<FaCopyright />}
      ShowDownloadAction="false"
      ShowAction="true"
      BtnIcon={<FaPlus />}
      ButtonTitle="Campaign"
      AddNewAction={AddCampaign}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Validity</Th>
              <Th>Min Order</Th>
              <Th>Discount</Th>
              <Th>Is Active</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {CampaignList.map((data, index) => {
              return (
                <Tr key={index}>
                  <Td>{data.Id}</Td>
                  <Td>{data.Campaign_Name}</Td>
                  <Td>{data.Campaign_Type}</Td>
                  <Td>
                    {moment(data.Campaign_Start_Date).format("DD-MMM-YYYY")} -{" "}
                    {moment(data.Campaign_End_Date).format("DD-MMM-YYYY")}
                  </Td>
                  <Td>₹{data.Minimum_Order_Value}</Td>
                  <Td>
                    {data.Discount_Type == "Cash" ? "₹" : ""}{" "}
                    {data.Discount_Value}{" "}
                    {data.Discount_Type == "Percentage" ? "%" : ""}
                  </Td>
                  <Td>
                    <Checkbox
                      colorScheme={data.Is_Active ? "green" : "red"}
                      defaultChecked
                      isReadOnly></Checkbox>
                  </Td>
                  <Td>
                    <ButtonGroup
                      variant={"outline"}
                      spacing="1">
                      <Tooltip
                        label="Edit Campaign"
                        placement="auto-start">
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          size={"xs"}
                          onClick={() => EditCampaign(data.Id)}>
                          <EditIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    &nbsp;&nbsp;
                    <ButtonGroup
                      variant={"outline"}
                      spacing="1">
                      <Tooltip
                        label="Delete Campaign"
                        placement="auto-start">
                        <Button
                          colorScheme="red"
                          variant="solid"
                          size={"xs"}
                          onClick={() =>
                            DeleteCampaign(data.Id, data.Is_Active)
                          }>
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    &nbsp;&nbsp;
                    <ButtonGroup
                      variant={"outline"}
                      spacing="1">
                      <Tooltip
                        label="Campaign Voucher Mapping"
                        placement="auto-start">
                        <Button
                          colorScheme="whatsapp"
                          variant="solid"
                          size={"xs"}
                          onClick={() => GetCampaignVocherMapping(data.Id)}>
                          <HamburgerIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          {CampaignList.length <= 0 ? (
            <Tfoot>
              <Tr colSpan="2">
                <Td>No Data Available</Td>
              </Tr>
            </Tfoot>
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>

      <ModalComponent
        Title="Add/Update Campaign"
        size={"6xl"}
        isOpen={isAddOpen}
        onOpen={onAddOpen}
        onClose={onAddClose}
        onSave={handleSubmit(SaveCampaign)}>
        <Input
          hidden
          {...register("Id")}
        />
        <Stack spacing={4}>
          <HStack>
            <Box width={"40%"}>
              <FormControl
                isRequired
                isInvalid={errors.Campaign_Code}>
                <FormLabel>Campaign Code</FormLabel>
                <Input
                  placeholder="Campaign Code"
                  {...register("Campaign_Code", {
                    required: "Campaign Code is Mandatory",
                  })}
                />
                <FormErrorMessage>
                  {errors.Campaign_Code && errors.Campaign_Code.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Campaign_Name}>
                <FormLabel>Campaign Name</FormLabel>
                <Input
                  placeholder="Campaign Name"
                  {...register("Campaign_Name", {
                    required: "Campaign Name is Mandatory",
                  })}
                />
                <FormErrorMessage>
                  {errors.Campaign_Name && errors.Campaign_Name.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Campaign_Type}>
                <FormLabel>Campaign Type</FormLabel>
                <Select
                  className="form-control"
                  {...register("Campaign_Type", {
                    required: "Campaign Type is Mandatory",
                  })}
                  onChange={(e) => onCampaignTypeChange(e.target.value)}>
                  <option value="">Select Campaign Type</option>
                  {CommonVariables.Campaign_Type.map((data, index) => {
                    return (
                      <option
                        value={data.Value}
                        key={index}>
                        {data.Key}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.Campaign_Type && errors.Campaign_Type.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            {ShowProduct ? (
              <Box width={"full"}>
                <FormControl>
                  <FormLabel>Product List</FormLabel>
                  <Multiselect
                    placeholder="Select Product List"
                    options={ProductList}
                    selectedValues={SelectedProductList}
                    onSelect={updateProductList}
                    onRemove={updateProductList}
                    displayValue="Product_Name"></Multiselect>
                </FormControl>
              </Box>
            ) : (
              <Box width={"full"}>
                <FormControl>
                  <FormLabel>Minimum Order Value</FormLabel>
                  <Input
                    type="number"
                    placeholder="Minimum Order Value"
                    {...register("Minimum_Order_Value", {
                      required: "Minimum Order Value is Mandatory",
                      setValueAs: (v) => parseInt(v),
                    })}
                  />
                </FormControl>
              </Box>
            )}
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Discount_Type}>
                <FormLabel>Discount Type</FormLabel>
                <Select
                  className="form-control"
                  {...register("Discount_Type", {
                    required: "Discount Type is Mandatory",
                  })}>
                  <option value="">Select Discount Type</option>
                  {CommonVariables.Discount_Type.map((data, index) => {
                    return (
                      <option
                        value={data.Value}
                        key={index}>
                        {data.Key}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.Discount_Type && errors.Discount_Type.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Discount_Value}>
                <FormLabel>Discount Value</FormLabel>
                <Input
                  placeholder="Discount Value"
                  {...register("Discount_Value", {
                    required: "Discount Value is Mandatory",
                    setValueAs: (v) => parseInt(v),
                  })}
                />
                <FormErrorMessage>
                  {errors.Discount_Value && errors.Discount_Value.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Discount StartDate</FormLabel>

                <ReactDatePicker
                  valueName="selected" // DateSelect value's name is selected
                  selected={Campaign_Start_Date}
                  onChange={(date) => setStartDate(date)}
                  name="ReactDatepicker"
                  showTimeSelect
                  timeClassName={handleColor}
                  className="form-control"
                  placeholderText="Select Start date"
                  dateFormat="dd-MM-yyyy hh:mm aa"
                  value={Campaign_Start_Date}
                />
              </FormControl>
            </Box>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Discount EndDate</FormLabel>

                <ReactDatePicker
                  valueName="selected" // DateSelect value's name is selected
                  selected={Campaign_End_Date}
                  onChange={(date) => setEndDate(date)}
                  name="ReactDatepicker"
                  showTimeSelect
                  timeClassName={handleColor}
                  className="form-control"
                  placeholderText="Select End date"
                  dateFormat="dd-MM-yyyy hh:mm aa"
                  value={Campaign_End_Date}
                />
              </FormControl>
            </Box>
            <Box width={"50%"}>
              <FormControl>
                <FormLabel>Timer Campaign</FormLabel>
                <Checkbox
                  colorScheme="green"
                  defaultChecked={false}
                  {...register("Timer_Campaign")}></Checkbox>
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            </Box>
            <Box width={"50%"}>
              <FormControl>
                <FormLabel>PopUp Campaign</FormLabel>
                <Checkbox
                  colorScheme="green"
                  defaultChecked={false}
                  {...register("Is_PopupCampaign")}></Checkbox>
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            </Box>
            <Box width={"50%"}>
              <FormControl>
                <FormLabel>Available Voucher</FormLabel>
                <Checkbox
                  colorScheme="green"
                  defaultChecked={false}
                  {...register("Show_IsAvailableCoupon")}></Checkbox>
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            </Box>
          </HStack>
          <HStack>
            

            {!ShowProduct ? (
              <Box width={"full"}>
                <FormControl
                  isRequired
                  isInvalid={errors.Campaign_Display_Title}>
                  <FormLabel>Campaign Display Title</FormLabel>
                  <Input
                    placeholder="Campaign Display Title"
                    {...register("Campaign_Display_Title", {
                      required: "Campaign Display Title is Mandatory",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.Campaign_Display_Title &&
                      errors.Campaign_Display_Title.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            ) : (
              <></>
            )}
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>PopUp Image Campaign</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    PopUpFileUploadToS3(fileList);
                  }}
                  placeholder="PopUp_Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.PopUpImage && errors.PopUpImage.message}
                </FormErrorMessage>
              </FormControl>
              <span className="NoteImageSize"> Note : Size W-400px * H-450px</span>
            </Box>
            <Box width={"50%"}>
            <a href={PopUpImage} target="blank"><img src={PopUpImage} width='50px' height='50px' /></a>
            </Box>
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Select Days</FormLabel>
                <CheckboxGroup
                  colorScheme="blue"
                  value={selectedDays}
                  onChange={handleDayChange}>
                  <HStack>
                    <Checkbox
                      isChecked={allDaysSelected}
                      onChange={handleAllDaysChange}>
                      All
                    </Checkbox>
                    {daysOfWeek.map((day) => (
                      <DayCheckbox
                        key={day}
                        day={day}
                        isChecked={selectedDays.includes(day)}
                        onChange={(e) => {
                          debugger;
                          if (e.target.checked) {
                            setSelectedDays([...selectedDays, day]);
                          } else {
                            setSelectedDays(
                              selectedDays.filter(
                                (selectedDay) => selectedDay !== day
                              )
                            );
                          }
                        }}
                      />
                    ))}
                  </HStack>
                </CheckboxGroup>
              </FormControl>
            </Box>
          </HStack>

          <Checkbox
            hidden
            defaultChecked
            {...register("Is_Active")}>
            {" "}
          </Checkbox>
        </Stack>
      </ModalComponent>

      <ModalComponent
        Title="Campaign Voucher Mapping"
        scroll="inside"
        size={"3xl"}
        isOpen={isVoucherOpen}
        onOpen={onVoucherOpen}
        onClose={onVoucherClose}>
        <Stack>
          <Box spacing={4}>
            <HStack>
              <Box width={"full"}>
                <FormControl
                  isRequired
                  isInvalid={errorVoucher.Voucher_Code}>
                  <FormLabel>Voucher Code</FormLabel>
                  <Input
                    placeholder="Voucher Code"
                    {...registerVoucher("Voucher_Code", {
                      required: "Voucher Code is Mandatory",
                    })}
                  />
                  <FormErrorMessage>
                    {errorVoucher.Voucher_Code &&
                      errorVoucher.Voucher_Code.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box
                width={"50%"}
                paddingTop={6}>
                <FormControl
                  isRequired
                  isInvalid={errorVoucher.Max_Use_Count}>
                  <FormLabel>Max Use Count</FormLabel>
                  <Input
                    type="number"
                    placeholder="Max Use Count"
                    {...registerVoucher("Max_Use_Count", {
                      required: "Max Use Count is Mandatory",
                      min: 1,
                      setValueAs: (v) => parseInt(v),
                    })}
                  />
                  <FormErrorMessage>
                    {errorVoucher.Max_Use_Count &&
                      errorVoucher.Max_Use_Count.message}
                  </FormErrorMessage>
                  <span className="NoteImageSize">
                    {" "}
                    Note : Count cannot be less than 0
                  </span>
                </FormControl>
              </Box>
            </HStack>
            <HStack
              paddingTop={"5"}
              float="right">
              <ButtonGroup
                variant="outline"
                spacing="4">
                <Button
                  type="submit"
                  onClick={handleVoucherSubmit(saveVoucherDetails)}
                  colorScheme="whatsapp">
                  Save Voucher
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>

        <br />
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Id</Th>
                <Th>Voucher Code</Th>
                <Th>Max Use</Th>
                <Th>Used Count</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {CampaignMappingList.map((cmap, index) => {
                return (
                  <Tr key={index}>
                    <Td>{cmap.Id}</Td>
                    <Td>{cmap.Voucher_Code}</Td>
                    <Td>{cmap.Max_Use_Count}</Td>
                    <Td>{cmap.Used_Count}</Td>
                    <Td>
                      <ButtonGroup
                        variant={"outline"}
                        spacing="1">
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          size={"xs"}
                          onClick={() =>
                            DeleteVoucher(cmap.Id, cmap.Is_Active)
                          }>
                          <DeleteIcon />
                        </Button>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            {CampaignMappingList.length <= 0 ? (
              <Tfoot>
                <Tr colSpan="2">
                  <Td>No Data Available</Td>
                </Tr>
              </Tfoot>
            ) : (
              <></>
            )}
          </Table>
        </TableContainer>
      </ModalComponent>
    </ContainerComponent>
  );
}

export default CampaignComponent;
