import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetProductGalleryList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductGallery/GetProductGalleryList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductGalleryById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductGallery/GetProductGalleryById?productGalleryId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveProductGallery = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductGallery/SaveProductGallery', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateProductGallery = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductGallery/UpdateProductGallery', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactivateProductGallery = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductGallery/ActivateDeactivateProductGalleryDetail?productGalleryId=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetProductGalleryByProductId = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductGallery/GetProductGalleryByProductId?productId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}