import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetServiceCenterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServiceCenter/GetServiceCenterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetServiceCenterById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServiceCenter/GetServiceCenterById?serviceCenterId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveServiceCenterDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ServiceCenter/SaveServiceCenter', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateServiceCenterDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ServiceCenter/UpdateServiceCenter', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActiveDeActiveServiceCenterDetail = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServiceCenter/ActivateDeactivateServiceCenterDetail?id=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}