import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { store, persistor } from './Store/store';
import { useNavigate } from 'react-router-dom';
import * as LoginService from './Services/Login/LoginService'

//Add Authorization Header in every HTTP request
axios.interceptors.request.use(function (config) {
  const token = store.getState();
  
  // var ip = GetIPAddressDetail();
  
  if (token.isAuthenticated)
    config.headers.Authorization = `Bearer ${token.AccessToken}`;
  else
    config.headers.Authorization = null;

  return config;
});
//End


// function GetIPAddressDetail() {

//   LoginService.GetIPAddress().then(data => { }).catch(error => {
//     if (data.data.IsSuccess) {
//       debugger;
//       return data.data.Data;
//     } else {
//       return "";
//     }
//   }).catch(error => {
//     return "";
//   })
// }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
