import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Flex,
  Stack,
  HStack,
  Heading,
  Table,
  ButtonGroup,
  Tfoot,
  Box,
  TableContainer,
  TableCaption,
  Spacer,
  Button,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Divider,
} from "@chakra-ui/react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import * as ProductDetailService from "../../Services/Product/ProductDetailService";
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import useLoaderState from "../../Common/useLoaderState";
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import ContainerComponent from "../Container/ContainerComponent";
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from "../Modal/ModalComponent";
import * as CommonVariables from "../../Common/CommonVariables";
import FilePicker from "chakra-ui-file-picker";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';

function ProductDetailComponent(props) {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    getvalues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleProductDetailSubmit,
    getValues: getValuesProductD,
    register: registerProductDetail,
    setValue: setValueProductDetail,
    reset: resetProductDetail,
    formState: { errors: errorProductDetail },
  } = useForm({ mode: "onBlur" });
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isPinCodeOpen,
    onOpen: onPinCodeOpen,
    onClose: onPinCodeClose,
  } = useDisclosure();
  const [IsLoading] = useLoaderState();
  const [SelectedProductDetail, setProductDetailId] = useState(0);
  const [Product_Id, SetProductId] = useState(0);
  const [Discount_StartDate, setStartDate] = useState(new Date());
  const [Discount_EndDate, setEndDate] = useState(new Date());

  useEffect(() => {
    SetProductId(props.Product_Id);
    if (props.Product_Id > 0) GetProductConfigDetailByProductId();
  }, []);

  function clickHandler(stDate, endDate) {
    const startdate = new Date(stDate);
    setStartDate(startdate);
    const edDate = new Date(endDate);
    setEndDate(edDate);
  }

  const GetProductConfigDetailByProductId = () => {
    SetProductId(props.Product_Id);
    ProductDetailService.GetProductConfigDetailByProductId(props.Product_Id)
      .then((data) => {
        if (data.data.IsSuccess) {
          debugger;
          console.log(data.data.Data);
          resetProductDetail(data.data.Data);
          setValueProductDetail(
            "Is_Stock_Available",
            data.data.Data.Is_Stock_Available ? "Yes" : "No"
          );
          setValueProductDetail(
            "Free_Shipping",
            data.data.Data.Free_Shipping ? "Yes" : "No"
          );
          setValueProductDetail(
            "Is_SubscribedProduct",
            data.data.Data.Is_SubscribedProduct ? "Yes" : "No"
          );
          setValueProductDetail(
            "Is_People_Choice",
            data.data.Data.Is_People_Choice ? "Yes" : "No"
          );
          clickHandler(
            data.data.Data.Discount_StartDate,
            data.data.Data.Discount_EndDate
          );
        } else {
          setValueProductDetail("Id", 0);
        }
      })
      .catch((error) => {
        setValueProductDetail("Id", 0);
      });
  };

  const SaveProductDetail = (value) => {
    value.Is_Stock_Available = value.Is_Stock_Available == "Yes" ? true : false;
    value.Free_Shipping = value.Free_Shipping == "Yes" ? true : false;
    value.Is_SubscribedProduct = value.Is_SubscribedProduct == "Yes" ? true : false;
    value.Is_People_Choice = value.Is_People_Choice == "Yes" ? true : false;
    value.Discount_StartDate = moment(Discount_StartDate).format("YYYY-MM-DD");
    value.Discount_EndDate = moment(Discount_EndDate).format("YYYY-MM-DD");
    value.Product_Id = props.Product_Id;

    console.log("SaveProductDetail");
    console.log(value);
    const values = getValuesProductD();
    console.log("getvalues");
    console.log(values);
    debugger;
    ProductDetailService.SaveProductDiscountConfigDetail(value)
      .then((data) => {
        debugger;
        if (data.data.IsSuccess) {
          resetProductDetail({});
          toast.success("Detail Save");
          onAddClose();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {});
  };

  return (
    <Stack spacing={4}>
    <LoadingSpinnerComponent></LoadingSpinnerComponent>
      <Box as="form">
        <Input
          hidden
          {...registerProductDetail("Id", { setValueAs: (v) => parseInt(v) })}
        />

        <HStack paddingTop={"5"}>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Price_Per_Quantity}>
              <FormLabel>Price_Per_Quantity</FormLabel>
              <Input
                type="number"
                placeholder="Price_Per_Quantity"
                {...registerProductDetail("Price_Per_Quantity", {
                  required: "Price is Mandatory",
                  setValueAs: (v) => parseFloat(v),
                })}
              />
              <FormErrorMessage>
                {errors.Price_Per_Quantity && errors.Price_Per_Quantity.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Product_Weight}>
              <FormLabel>Product Weight </FormLabel>
              <Input
                type="number"
                placeholder="Product Weight"
                {...registerProductDetail("Product_Weight", {
                  required: "Product Weight is Mandatory",
                  setValueAs: (v) => parseFloat(v),
                })}
              />
              <FormErrorMessage>
                {errors.Product_Weight && errors.Product_Weight.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Discount Type</FormLabel>
              <Select
                className="form-control"
                {...registerProductDetail("Discount_Type")}>
                <option value="">Select Discount Type</option>
                {CommonVariables.Discount_Type.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Discount</FormLabel>
              <Input
                type="number"
                placeholder="Discount"
                {...registerProductDetail("Discount", {
                  setValueAs: (v) => parseFloat(v),
                })}
              />
            </FormControl>
          </Box>
        </HStack>
        <HStack paddingTop={"5"}>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Discount StartDate</FormLabel>

              <ReactDatePicker
                valueName="selected" // DateSelect value's name is selected
                selected={Discount_StartDate}
                onChange={(date) => setStartDate(date)}
                name="ReactDatepicker"
                className="form-control"
                placeholderText="Select start date"
                dateFormat="dd-MM-yyyy"
                value={Discount_StartDate}
              />
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Discount EndDate</FormLabel>

              <ReactDatePicker
                valueName="selected" // DateSelect value's name is selected
                selected={Discount_EndDate}
                onChange={(date) => setEndDate(date)}
                name="ReactDatepicker"
                className="form-control"
                placeholderText="Select end date"
                dateFormat="dd-MM-yyyy"
                value={Discount_EndDate}
              />
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Delivery_Charges}>
              <FormLabel>Delivery Charges</FormLabel>
              <Input
                type="number"
                placeholder="Delivery Charges"
                {...registerProductDetail("Delivery_Charges", {
                  required: "Delivery Charge is Mandatory",
                  setValueAs: (v) => parseInt(v),
                })}
              />
              <FormErrorMessage>
                {errors.Delivery_Charges && errors.Delivery_Charges.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Installation_Charges}>
              <FormLabel>Insatllation Charges</FormLabel>
              <Input
                type="number"
                placeholder="Insatllation Charges"
                {...registerProductDetail("Installation_Charges", {
                  required: "Insatllation Charges is Mandatory",
                  setValueAs: (v) => parseInt(v),
                })}
              />
              <FormErrorMessage>
                {errors.Installation_Charges &&
                  errors.Installation_Charges.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </HStack>
        <HStack paddingTop={"5"}>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Maximum_Buy_Quantity}>
              <FormLabel>Maximum_Buy_Quantity</FormLabel>
              <Input
                type="number"
                placeholder="Maximum_Buy_Quantity"
                {...registerProductDetail("Maximum_Buy_Quantity", {
                  required: "Field is Mandatory",
                  setValueAs: (v) => parseInt(v),
                })}
              />
              <FormErrorMessage>
                {errors.Maximum_Buy_Quantity &&
                  errors.Maximum_Buy_Quantity.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Minimum_Buy_Quantity}>
              <FormLabel>Minimum_Buy_Quantity</FormLabel>
              <Input
                type="number"
                placeholder="Minimum_Buy_Quantity"
                {...registerProductDetail("Minimum_Buy_Quantity", {
                  required: "Field is Mandatory",
                  setValueAs: (v) => parseInt(v),
                })}
              />
              <FormErrorMessage>
                {errors.Minimum_Buy_Quantity &&
                  errors.Minimum_Buy_Quantity.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Is Stock Available</FormLabel>
              <Select
                className="form-control"
                {...registerProductDetail("Is_Stock_Available")}>
                <option value="">Select Type</option>
                {CommonVariables.ProductConfType.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Free Shipping</FormLabel>
              <Select
                className="form-control"
                {...registerProductDetail("Free_Shipping")}>
                <option value="">Select Type</option>
                {CommonVariables.ProductConfType.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Is People Choice&nbsp;&nbsp;
              <Select
                className="form-control"
                {...registerProductDetail("Free_Shipping")}>
                <option value="">Select Type</option>
                {CommonVariables.ProductConfType.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
                {/* <Checkbox style={{top:"4px"}} colorScheme='green' {...registerProductDetail("Is_People_Choice")} /> */}
              </FormLabel>
             </FormControl>
          </Box>
        </HStack>
        <HStack paddingTop={5}>
          <Box width={"full"}>
          <FormControl>
              <FormLabel>Is_SubscribedProduct</FormLabel>
              <Select
                className="form-control"
                {...registerProductDetail("Is_SubscribedProduct")}>
                <option value="">Select Type</option>
                {CommonVariables.ProductConfType.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

          </Box>
          <Box width={"full"}>
            <FormLabel>Subscription Discount</FormLabel>
            <Input
              type="number"
              placeholder="Subscription Discount"
              {...registerProductDetail("SubscriptionDiscount", {
                setValueAs: (v) => parseFloat(v),
              })}
            />
          </Box>
          <Box width={"full"}>
            <FormLabel>Subscribed Product Tenure In Days</FormLabel>
            <Input
              type="number"
              placeholder="Subscribed_Product_Tenure_Indays"
              {...registerProductDetail("SubscribedProductTenureIndays", {
                setValueAs: (v) => parseFloat(v),
              })}
            />
          </Box>
        </HStack>
        <HStack
          spacing={"2"}
          paddingTop={"5"}
          float="right">
          <ButtonGroup
            variant="outline"
            spacing="4">
            <Button
              type="submit"
              disabled={IsLoading}
              onClick={handleProductDetailSubmit(SaveProductDetail)}
              colorScheme="whatsapp">
              Save
            </Button>
          </ButtonGroup>
        </HStack>
      </Box>
    </Stack>
  );
}

export default ProductDetailComponent;
