import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Flex,
  Stack,
  Heading,
  Table,
  ButtonGroup,
  Tfoot,
  Box,
  TableContainer,
  TableCaption,
  Spacer,
  Button,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Divider,
} from "@chakra-ui/react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import * as ProductGalleryService from "../../Services/Product/ProductGalleryService";
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import useLoaderState from "../../Common/useLoaderState";
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import ContainerComponent from "../Container/ContainerComponent";
import { FaCopyright, FaPlus, FaUpload } from "react-icons/fa";
import ModalComponent from "../Modal/ModalComponent";
import * as CommonVariables from "../../Common/CommonVariables";
import FilePicker from "chakra-ui-file-picker";
import LoadingSpinnerComponent from "../Loader/LoadingSpinnerComponent";

function ProductGalleryComponent(props) {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleProductGallery,
    register: registerProductGallery,
    setValue: setValueProductGallery,
    reset: resetProductGallery,
    formState: { errors: errorProductGallery },
  } = useForm({ mode: "onBlur" });
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isPinCodeOpen,
    onOpen: onPinCodeOpen,
    onClose: onPinCodeClose,
  } = useDisclosure();
  const [IsLoading] = useLoaderState();
  const [Product_Id, SetProductId] = useState(0);
  const [SelectedFileDetails, setSelectedFileDetails] = useState("");
  const [UploadedURL, setUploadedURL] = useState("");
  const [ProductGalleryList, setProductGalleryList] = useState([]);

  useEffect(() => {
    if (parseInt(props.Product_Id) > 0) GetGalleryListByProduct();
  }, []);

  const GetGalleryListByProduct = () => {
    SetProductId(props.Product_Id);
    ProductGalleryService.GetProductGalleryByProductId(props.Product_Id)
      .then((data) => {
        if (data.data.IsSuccess) {
          setProductGalleryList(data.data.Data);
        } else {
          // setRelatedProductList([]);
          resetProductGallery();
        }
      })
      .catch((error) => {});
  };

  const SaveProductGallery = (value) => {
    //debugger;
    value.Id = 0;
    value.Product_Id = props.Product_Id;
    value.Sequence_No = parseInt(value.Sequence_No);
    ProductGalleryService.SaveProductGallery(value)
      .then((data) => {
        if (data.data.IsSuccess) {
          toast.success("Added Image Succesfully");
          resetProductGallery({});
          GetGalleryListByProduct();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {});
  };

  const GalleryFileUploadToS3 = (fileList) => {
    const file = fileList[0];
    if (file.size <= 1024 * 1024) {
      if (fileList != null && fileList.length > 0) {
        var formdata = new FormData();
        formdata.append("Myfile", fileList[0]);

        AttachmentService.FileUploadToS3(formdata)
          .then((response) => {
            if (response.data.IsSuccess) {
              toast.success(response.data.ResponseMessage);
              setValueProductGallery("Gallery_Image", response.data.Data);
            } else {
              setValueProductGallery("Gallery_Image", "");
              // setError("Gallery_Image", {
              //   types: { required: "Gallery_Image is Mandatory" },
              // });
              toast.error(response.data.ResponseMessage);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            // setError("Gallery_Image", {
            //   types: { required: "Gallery_Image is Mandatory" },
            // });
          });
      } else {
        toast.error("Please choose Image First!");
        // setError("Gallery_Image", {
        //   types: { required: "Gallery_Image is Mandatory" },
        // });
      }
    } else {
      toast.error("File size exceeds 1MB.");
    }
  };

  const ActivateDeactivateProductGallery = (id, Is_Active) => {
    debugger;
    ProductGalleryService.ActivateDeactivateProductGallery(id, !Is_Active)
      .then((data) => {
        if (data.data.IsSuccess) {
          toast.success("Deleted Successfully");
          GetGalleryListByProduct();
        }
      })
      .catch((error) => {});
  };

  return (
    <Stack>
      <LoadingSpinnerComponent></LoadingSpinnerComponent>
      <Box as="form">
        <Input
          hidden
          {...registerProductGallery("Id")}
        />

        <HStack>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Gallery_Image}>
              <FormLabel>Gallery_Image</FormLabel>

              <FilePicker
                onFileChange={(fileList) => {
                  GalleryFileUploadToS3(fileList);
                }}
                placeholder="Gallery_Image"
                clearButtonLabel="Clear"
                multipleFiles={false}
                accept="application/png"
                hideClearButton={false}
              />
              <FormErrorMessage>
                {errors.Gallery_Image && errors.Gallery_Image.message}
              </FormErrorMessage>
            </FormControl>
            <span className="NoteImageSize"> Note : Size 583*450</span>
          </Box>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errors.Sequence_No}>
              <FormLabel>Sequence_No</FormLabel>
              <Input
                placeholder="Sequence No"
                {...registerProductGallery("Sequence_No", {
                  required: "Sequence No is Mandatory",
                })}
              />
              <FormErrorMessage>
                {errors.Sequence_No && errors.Sequence_No.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </HStack>
        <HStack>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Video URL</FormLabel>

              <Input
                type="text"
                placeholder="Enter Video Url"
                {...registerProductGallery("Video_Url")}
              />
            </FormControl>
          </Box>
        </HStack>
        <Checkbox
          hidden
          {...register("Is_Active")}>
          {" "}
        </Checkbox>
        <HStack
          spacing={"2"}
          paddingTop={"5"}
          float="right">
          <ButtonGroup
            variant="outline"
            spacing="4">
            <Button
              disabled={IsLoading}
              type="submit"
              onClick={handleProductGallery(SaveProductGallery)}
              colorScheme="whatsapp">
              Save
            </Button>
          </ButtonGroup>
        </HStack>
      </Box>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Product Name</Th>
              <Th>Gallery Image</Th>
              <Th>Sequence No</Th>
              {/* <Th>Is Active</Th> */}
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ProductGalleryList.map((data, index) => {
              return (
                <Tr key={index}>
                  <Td>{data.Id}</Td>LoadingSpinnerComponent
                  <Td>{data.Product_Name}</Td>
                  <Td>
                    <img
                      src={data.Gallery_Image}
                      width="50px"
                      height="50px"
                    />
                  </Td>
                  <Td>{data.Sequence_No}</Td>
                  {/* <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td> */}
                  <Td>
                    <ButtonGroup
                      variant={"outline"}
                      spacing="1">
                      <Button
                        disabled={IsLoading}
                        colorScheme="blue"
                        variant="solid"
                        size={"xs"}
                        onClick={() =>
                          ActivateDeactivateProductGallery(
                            data.Id,
                            data.Is_Active
                          )
                        }>
                        <DeleteIcon />
                      </Button>
                    </ButtonGroup>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          {ProductGalleryList.length <= 0 ? (
            <Tfoot>
              <Tr colSpan="12">
                <Td colSpan="12">No Data Available</Td>
              </Tr>
            </Tfoot>
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default ProductGalleryComponent;
