import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { GridItem, Grid, Textarea, HStack, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as ProductTestimonialService from '../../Services/Product/ProductTestimonialService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';

function ProductTestimonialComponent(props) {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { handleSubmit: handleProductTestimonialSubmit, register: registerProductTestimonial, setValue: setValueProductTestimonial, reset: resetProductTestimonial, formState: { errors: errorProductTestimonial } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isUnApprovedTestimonialOpen, onOpen: onUnApprovedTestimonialOpen, onClose: onUnApprovedTestimonialClose } = useDisclosure()
    const [ProductTestimonialList, setProductTestimonialList] = useState([]);
    const [ProductTestimonialUnApprovedList, setProductTestimonialUnApprovedList] = useState([]);
    const [IsLoading] = useLoaderState();
    const [Product_Id, SetProductId] = useState(0);

    useEffect(() => {
        SetProductId(props.Product_Id)
        if (parseInt(props.Product_Id) > 0)
            GetProductTestimonialListByProductId();
    }, [])


    const SaveProductTestimonial = (value) => {
        value.Id = 0;
        value.Product_Id = props.Product_Id;
        ProductTestimonialService.SaveProductTestimonial(value).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Testimonial Save");
                resetProductTestimonial();

                GetProductTestimonialListByProductId(props.Product_Id);
            }
        }).catch(error => {

        });
    }


    const EditProductTestimonial = (id) => {
        ProductTestimonialService.GetProductTestimonialByProductId(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
            }
        }).catch(error => {

        })
    }

    const DeleteProductTestimonial = (id) => {
        ProductTestimonialService.DeleteProductTestimonial(id).then(data => {
            if (data.data.IsSuccess) {
                GetProductTestimonialListByProductId(props.Product_Id);
            }
        }).catch(error => {

        })
    }

    const OpenUnApprovedTestimonial = () => {
        onUnApprovedTestimonialOpen();
    }

    const GetProductTestimonialListByProductId = () => {
        SetProductId(props.Product_Id);
        ProductTestimonialService.GetProductTestimonialByProductId(props.Product_Id).then(data => {
            if (data.data.IsSuccess) {
                var testimonialList = data.data.Data.filter(d => d.Is_Approved == true);
                setProductTestimonialList(testimonialList)
                setProductTestimonialUnApprovedList(data.data.Data.filter(d => d.Is_Approved == false));
            }
            else {
                setProductTestimonialList([])
            }
        }).catch(error => {

        });
    }


    const ApproveProductTestimonial = (e, index) => {
        e.preventDefault();
        var action = window.confirm('Do you want to approve Testimonial of - ' + ProductTestimonialUnApprovedList[index].Temp_CustName + ' rating is ' + ProductTestimonialUnApprovedList[index].Rating);
        if (action == true) {
            ProductTestimonialService.ApproveProductTestimonial(ProductTestimonialUnApprovedList[index].Id).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Approved Successfully!")
                    setProductTestimonialUnApprovedList([])
                    GetProductTestimonialListByProductId(Product_Id);
                }
            }).catch(error => {

            })
        }
    }

    return (
        <Stack spacing={4}>
        <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Box as="form">
                <Input hidden  {...register("Id")} />

                <Input type="number" hidden  {...register("Product_Id")} />

                <HStack paddingTop={"5"}>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Temp_CustName}>
                            <FormLabel>Customer Name</FormLabel>
                            <Input placeholder='Customer Name' {...registerProductTestimonial("Temp_CustName", { required: "Customer Name is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.Temp_CustName && errors.Temp_CustName.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Temp_CustCity}>
                            <FormLabel>Customer City</FormLabel>
                            <Input placeholder='Customer City' {...registerProductTestimonial("Temp_CustCity", { required: "Customer City is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.Temp_CustCity && errors.Temp_CustCity.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack>
                <HStack paddingTop={"5"}>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Title}>
                            <FormLabel>Title</FormLabel>
                            <Input placeholder='Title' {...registerProductTestimonial("Title", { required: "Title is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.Title && errors.Title.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Rating}>
                            <FormLabel>Rating</FormLabel>
                            <Input type="number" placeholder='Rating' {...registerProductTestimonial("Rating", { required: "Rating is Mandatory", setValueAs: v => parseFloat(v) })} />
                            <FormErrorMessage>
                                {errors.Rating && errors.Rating.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack>
                <HStack paddingTop={"5"}>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Description}>
                            <FormLabel>Description</FormLabel>
                            <Textarea placeholder='Description' {...registerProductTestimonial("Description", { required: "Detail is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.Description && errors.Description.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack>

                <HStack spacing={"2"} paddingTop={"5"} float="right">
                    <ButtonGroup variant='outline' spacing='4' >
                        {ProductTestimonialUnApprovedList.length > 0 ? <Button onClick={() => OpenUnApprovedTestimonial()} colorScheme="red"><span className='Free'><b>{ProductTestimonialUnApprovedList.length}</b></span>&nbsp; Non Approved Testimonial</Button> : <></>}
                        <Button disabled={IsLoading} type='submit' onClick={handleProductTestimonialSubmit(SaveProductTestimonial)} colorScheme="whatsapp">Save</Button>
                    </ButtonGroup>
                </HStack>

            </Box>

            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Title</Th>
                            <Th>Rating</Th>
                            <Th>Customer Name</Th>
                            <Th>Customer City</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ProductTestimonialList.map((data, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>{data.Id}</Td>
                                    <Td>{data.Title}</Td>
                                    <Td>{data.Rating}</Td>
                                    <Td>{data.Temp_CustName}</Td>
                                    <Td>{data.Temp_CustCity}</Td>
                                    <Td>
                                        <ButtonGroup variant={"outline"} spacing="1">
                                            <Button disabled={IsLoading} colorScheme="blue" variant="solid" size={"xs"} onClick={() => DeleteProductTestimonial(data.Id)}>
                                                <DeleteIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                    {ProductTestimonialList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="12">
                                <Td colSpan="12">
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>

            <ModalComponent scroll="inside" size="4xl" Title="Non Approved Testimonial" isOpen={isUnApprovedTestimonialOpen} onOpen={onUnApprovedTestimonialOpen} onClose={onUnApprovedTestimonialClose} >

                {ProductTestimonialUnApprovedList.length > 0 ?

                    <Grid templateColumns='repeat(1, 1fr)'>

                        <GridItem w='95%' >
                            <div>
                                {ProductTestimonialUnApprovedList.map((data, index) => {
                                    return (
                                        <ContainerComponent paddingTop={10}>
                                            <Box key={index} marginLeft={3}>
                                                <Stack marginBottom={10}>
                                                    <HStack>
                                                        <Box width={'full'}><b>Customer Name : </b> {data.Temp_CustName}; </Box>
                                                        <Box width="80%"><b>Customer City : </b> {data.Temp_CustCity}; </Box>
                                                        <Box width="30%"><b>Rating : </b> {data.Rating >= 3 ? data.Rating : <span className='Free'>{data.Rating}</span>} </Box>
                                                    </HStack>
                                                    <HStack>
                                                        <Box width={'full'}><b>Title  : </b> {data.Title}; </Box>
                                                    </HStack>
                                                    <HStack>
                                                        <Box width={'full'}><b>Description : </b> {data.Description} </Box>
                                                        <Box width="20%">  <Button colorScheme='red' variant='outline' onClick={(e) => ApproveProductTestimonial(e, index)} > Approve</Button> </Box>
                                                    </HStack>
                                                </Stack>
                                            </Box>
                                        </ContainerComponent>
                                    )
                                })}
                            </div>
                        </GridItem>
                    </Grid>

                    :
                    <span className='Free'> No Testimonial is reamining for approval. </span>
                }
            </ModalComponent>
        </Stack >


    )
}





export default ProductTestimonialComponent