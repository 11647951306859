import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetPincodeList = async (selectedPincode) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Pincode/GetPincodeList?pincode=${selectedPincode}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetPincodeDetail = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Pincode/GetPincodeDetail?pincodeId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SavePincode = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Pincode/SavePinocde`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdatePincode = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Pincode/UpdatePinocde`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetPinocdeListBySc = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Pincode/GetPinocdeListBySc?scId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const MapPincodeServiceCenter = async (id,pincodeId) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Pincode/MapPincodeServiceCenter?scId=${id}&pincodeId=${pincodeId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const DeletePinCodeMappingById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Pincode/DeletePinCodeMappingById?mappingId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const ActivateDeactivatePinCode = async (id,IsActive) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Pincode/ActivateDeactivatePinCode?pincodeId=${id}&isactive=${IsActive}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}