import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import  Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import _LoginLayout from './PageLayout/_LoginLayout';
import _DashboardLayout from './PageLayout/_DashboardLayout';
import LoginPage from './Pages/LoginPage';
import GAuthPage from './Pages/GAuthPage';
import DashboardComponent from './Components/Dashboard/DashboardComponent';
import CategoryPage from './Pages/CategoryPage';
import ServiceCenterPage from './Pages/ServiceCenterPage';
import PincodePage from './Pages/PincodePage';
import BannerPage from './Pages/BannerPage';
import BannerSectionPage from './Pages/BannerSectionPage';
import ProductPage from './Pages/ProductPage';
import OrderListPage from './Pages/OrderListPage';
import CCOrderScreenPage from './Pages/CCOrderScreenPage';
import StockPage from './Pages/StockPage';
import CampaignPage from './Pages/CampaignPage';
import ComplaintPage from './Pages/ComplaintPage';
import UserPage from './Pages/UserPage';
import MetaPage from './Pages/MetaPage';
import BlogMasterPage from './Pages/BlogMasterPage';
import OTPPage from './Pages/OTPPage';


Chart.register(CategoryScale);

function App() {


  const a ="";
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <_LoginLayout>
              <LoginPage></LoginPage>
            </_LoginLayout>
          }
        />
        <Route
          path="/login"
          element={
            <_LoginLayout>
              <LoginPage></LoginPage>
            </_LoginLayout>
          }
        />
        <Route
          path="/gauth/authentication"
          element={
            <_LoginLayout>
              <GAuthPage></GAuthPage>
            </_LoginLayout>
          }
        />
        <Route
          path="/gauth"
          element={
            <_LoginLayout>
              <GAuthPage></GAuthPage>
            </_LoginLayout>
          }
        />
        <Route
          path="/dashboard"
          element={
            <_DashboardLayout>
              <DashboardComponent></DashboardComponent>
            </_DashboardLayout>
          }
        />

        <Route
          path="/category"
          element={
            <_DashboardLayout>
              <CategoryPage></CategoryPage>
            </_DashboardLayout>
          }
        />

        <Route
          path="/category"
          element={
            <_DashboardLayout>
              <CategoryPage></CategoryPage>
            </_DashboardLayout>
          }
        />

        <Route
          path="/pincode"
          element={
            <_DashboardLayout>
              <PincodePage></PincodePage>
            </_DashboardLayout>
          }
        />

        <Route
          path="/banner"
          element={
            <_DashboardLayout>
              <BannerPage></BannerPage>
            </_DashboardLayout>
          }
        />

        <Route
          path="/bannersection"
          element={
            <_DashboardLayout>
              <BannerSectionPage></BannerSectionPage>
            </_DashboardLayout>
          }
        />

        <Route
          path="/servicecenter"
          element={
            <_DashboardLayout>
              <ServiceCenterPage />
            </_DashboardLayout>
          }
        />
        <Route
          path="/product"
          element={
            <_DashboardLayout>
              <ProductPage />
            </_DashboardLayout>
          }
        />
        <Route
          path="/campaign"
          element={
            <_DashboardLayout>
              <CampaignPage />
            </_DashboardLayout>
          }
        />
         <Route
          path="/complaint"
          element={
            <_DashboardLayout>
              <ComplaintPage />
            </_DashboardLayout>
          }
        />
        <Route
          path="/orderlist/:date?"
          element={
            <_DashboardLayout>
              <OrderListPage />
            </_DashboardLayout>
          }
        />
        <Route
          path="/ccorder"
          element={
            <_DashboardLayout>
              <CCOrderScreenPage />
            </_DashboardLayout>
          }
        />
        <Route
          path="/stock"
          element={
            <_DashboardLayout>
              <StockPage />
            </_DashboardLayout>
          }
        />
         <Route
          path="/user"
          element={
            <_DashboardLayout>
              <UserPage />
            </_DashboardLayout>
          }
        />
         <Route
          path="/meta"
          element={
            <_DashboardLayout>
              <MetaPage />
            </_DashboardLayout>
          }
          
        />
         <Route
          path="/blog"
          element={
            <_DashboardLayout>
              <BlogMasterPage />
            </_DashboardLayout>
          }
          />
         <Route
          path="/otp"
          element={
            <_LoginLayout>
              <OTPPage />
            </_LoginLayout>
          }
          />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
