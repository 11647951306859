import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetCampaignList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Campaign/GetCampaignList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Campaign/GetProductList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetCampaignById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Campaign/GetCampaignById?CampaignId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetCampaignProductMappingList = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Campaign/GetCampaignProductMappingList?CampaignId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetDayListById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Campaign/GetDayListById?CampaignId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const SaveCampaignDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Campaign/SaveCampaign', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateCampaignDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Campaign/UpdateCampaign', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActiveDeActiveCampaignDetail = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Campaign/ActiveInactiveCampaign?id=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

//Vocher

export const GetCampaignVocherMapping = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Voucher/GetVoucherByCampaignId?campaignId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveVoucher = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Voucher/SaveVoucher', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActiveDeActiveVocuherDetail = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Voucher/ActiveInactiveVoucher?id=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
