import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import * as LoginService from '../../Services/Login/LoginService'
import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
} from '@chakra-ui/react';
import { thunk_set_user } from '../../Action/action'
import useLoaderState from '../../Common/useLoaderState';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';

function LoginOTPComponent(props) {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    const [IsLoading] = useLoaderState();
    const history = useNavigate();

    const ValidateOTP = (value) => {
        debugger;
        LoginService.ValidateUserOTP(value.UserName, value.Password).then(data => {
            if (data.data.IsSuccess) {
                //debugger;
                props.dispatch(thunk_set_user(data.data.Data));
                toast.success("Login Success");
                setTimeout(() => {
                    history('/dashboard');
                }, 1000);

            } else {
                toast.error("Invalid User");
            }
        }).catch(error => {
            toast.error("Somthing went wrong. Please try again.");
        })
    }

    return (
        <Stack
            bg={'gray.50'}
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: 'lg' }}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Stack spacing={4}>
                <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                    OTP Verification
                    <Text
                        as={'span'}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        bgClip="text">
                        !
                    </Text>
                </Heading>
            </Stack>

            <Box as={'form'} mt={10}>
                <Stack spacing={4}>
                    <Input
                        placeholder="User Name"
                        bg={'gray.100'}
                        border={0}
                        color={'gray.500'}
                        _placeholder={{
                            color: 'gray.500',
                        }}
                        {...register("UserName")}
                    />
                    {errors.UserName && <p style={{ color: 'red' }}>User Name is required</p>}
                    <Input
                        type="password"
                        placeholder="Password"
                        bg={'gray.100'}
                        border={0}
                        color={'gray.500'}
                        _placeholder={{
                            color: 'gray.500',
                        }}
                        {...register("Password")}
                    />
                    {errors.Password && <p style={{ color: 'red' }}>Password is required</p>}
                </Stack>
                <Button
                    fontFamily={'heading'}
                    mt={8}
                    w={'full'}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    color={'white'}
                    _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl',
                    }} onClick={handleSubmit(ValidateOTP)}>
                    Login
                </Button>
            </Box>

        </Stack>
    )
}

const matchStateToPros = state => {
    return {
        data: state
    }
}
export default connect(matchStateToPros)(LoginOTPComponent);