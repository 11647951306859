import React, { useState, useRef } from 'react';
import { store } from '../../Store/store'
import { useNavigate } from 'react-router-dom';
import {
    IconButton, Avatar, Box, Flex, HStack, VStack, Icon, useColorModeValue, Text, useDisclosure, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Stack, FormControl, FormLabel, Center, ButtonGroup, Button, Input, Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react';
import { ArrowForwardIcon, Search2Icon } from '@chakra-ui/icons';
import { FiMenu, FiBell, FiChevronDown } from 'react-icons/fi';
import { thunk_reset_user } from '../../Action/action';
import { connect } from 'react-redux';
import ModalComponent from '../Modal/ModalComponent';
import * as UserService from '../../Services/User/UserService'
import * as moment from 'moment';
import * as OrderListService from '../../Services/OrderList/OrderListService'
import toast, { Toaster } from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import * as PincodeService from '../../Services/Pincode/PincodeService'

function TopMenuComponent(props) {

    const { isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose } = useDisclosure();
    const { isOpen: isSettingOpen, onOpen: onSettingOpen, onClose: onSettingClose } = useDisclosure();
    const { isOpen: isResetPasswordOpen, onOpen: onResetPasswordOpen, onClose: onResetPasswordClose } = useDisclosure();
    const { isOpen: isSearchOrderAddOpen, onOpen: onSearchOrderAddOpen, onClose: onSearchOrderAddClose } = useDisclosure();
    const userInfo = store.getState();
    const navigate = useNavigate();
    //console.log(userInfo);

    const newPassword = useRef();
    const search = useRef();
    const primaryHexCode = useRef();
    const secondaryHexCode = useRef();
    const [SearchOrderList, setSearchOrderList] = useState([]);

    const [DeliveryDateData, setDeliveryDateData] = useState('');
    const [DeliveryPartnerData, setDeliveryPartnerData] = useState('');
    const selectedPincode = useRef();

    const Logout = () => {
        props.dispatch(thunk_reset_user({}));
        navigate("/");
    }

    const Profile = () => {
        onProfileOpen();
    }

    const Settings = () => {
        onSettingOpen();

    }
    const ResetPassword = () => {
        onResetPasswordOpen();
    }

    const ResetUserPassword = () => {
        UserService.ResetUserPassword(newPassword.current.value, userInfo.userData.Email_Id, userInfo.userData.User_Id).then(data => {
            if (data.data.IsSuccess) {
                onResetPasswordClose();
                props.dispatch(thunk_reset_user({}));
                navigate("/");
            } else {
                toast.error(data.data.Response);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const SearchRecord = (e) => {
        e.preventDefault();
        if (search.current.value != "") {
            OrderListService.UniversalSearchByOrderNoMobileNo(search.current.value).then(data => {
                console.log(data)
                if (data.data.IsSuccess) {
                    console.log(data.data.Data);
                    onSearchOrderAddOpen()
                    setSearchOrderList(data.data.Data);
                } else {
                    toast.error("No Record Found")
                    setSearchOrderList([]);
                }
            }).catch(error => {
                toast.error("Somthing went wrong");
            })
        }
        else {
            toast.error("Please enter order/phoneno")
        }
    }

    const LogoFileUpload = (fileList) => {
        debugger;

        if (fileList != null && fileList.length > 0) {
            var confirmLogoUpload = window.confirm("Are you sure! You want to change Logo of Product Website ?");
            if (confirmLogoUpload) {
                var formdata = new FormData();
                formdata.append("Myfile", fileList[0]);
                const file = fileList[0];
                if (file.size <= 1000000) {
                    AttachmentService.UploadLogo(formdata)
                        .then((response) => {
                            if (response.data.IsSuccess) {
                                toast.success(response.data.ResponseMessage);
                            } else {
                                toast.error(response.data.ResponseMessage);
                            }
                        })
                        .catch((error) => {
                            toast.error("Something went wrong!");
                        });
                }
                else {
                    toast.error("File size exceeds 1MB.");
                }

            }
            else {
                toast.error("Upload Cancelled!");
            }


        } else {
            toast.error("Please choose Image First!");
        }




    };

    const UpdateWebsiteColor = () => {
        var confirmColor = window.confirm("Are you sure! you want to change website color?");

        if (confirmColor) {
            AttachmentService.UpdateWebsiteColor(primaryHexCode.current.value, secondaryHexCode.current.value, userInfo.userData.User_Id).then(data => {
                debugger;
                console.log(data);
                if (data.data.IsSuccess) {
                    toast.success(data.data.ResponseMessage);
                    onSettingClose();
                }
                else {
                    toast.error(data.data.ResponseMessage);
                }

            }).catch(error => {
                toast.error("Somthing went wrong");
            })
        }
        else {
            toast.error("Color Update Cancelled!")
        }
    }

    const ResetWebsiteColor = () => {
        var resetColor = window.confirm("Are you sure! you want to reset website color?");
        if (resetColor) {
            AttachmentService.UpdateWebsiteColor("00bc7a", "F2FCF8", userInfo.userData.User_Id).then(data => {
                debugger;
                console.log(data);
                if (data.data.IsSuccess) {
                    toast.success(data.data.ResponseMessage);
                    onSettingClose();
                }
                else {
                    toast.error(data.data.ResponseMessage);
                }

            }).catch(error => {
                toast.error("Somthing went wrong");
            })
        }
        else {
            toast.error("Reset Cancelled!");
        }
    }

    const ResetWebsiteLogo = () => {
        var resetLogo = window.confirm("Are you sure! you want to reset website Logo?");
        if (resetLogo) {
            debugger;
            AttachmentService.ResetLogo().then(data => {
                if (data.data.IsSuccess) {
                    debugger;
                    toast.success(data.data.ResponseMessage);
                    onSettingClose();
                }
                else {
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error("Somthing went wrong");
            })
        }
        else {
            toast.error("Logo Reset Cancelled!");
        }
    }

    const SearchPincode = () => {
        if (selectedPincode.current.value != '') {
            PincodeService.GetPincodeList(selectedPincode.current.value).then(data => {
                if (data.data.IsSuccess) {
                    // setDeliveryDateData((data.data.Data[0].Delivery_TAT==0 ?'': data.data.Data[0].Delivery_TAT+1)  +"| XpressBees_TAT : "+ (data.data.Data[0].XpressBees_TAT+1));
                    setDeliveryDateData((data.data.Data[0].Delivery_TAT==0 ?'': 'DTDC_TAT: '+ (data.data.Data[0].Delivery_TAT+1)) + ' ' +  (data.data.Data[0].XpressBees_TAT==0 ?'': 'XpressBees_TAT: ' + (data.data.Data[0].XpressBees_TAT+1)));
                    setDeliveryPartnerData(data.data.Data[0].Delivery_Partner)
                } else {
                    toast.error('Invalid Pincode');
                    setDeliveryDateData('');
                    setDeliveryPartnerData('');
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
        else {
            setDeliveryDateData('');
            setDeliveryPartnerData('');
            toast.error('Please enter pincode first.')
        }
    }

    return (
        <Flex
            ml={{ base: 0, md: 52 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={() => props.onOpen()}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            
            <HStack paddingBottom={4}>
                <Box width={"full"} paddingTop={5}>
                    <FormControl >
                        <Input placeholder='Enter Pincode to get DTA' ref={selectedPincode} />
                    </FormControl>
                </Box>&nbsp;
                <Box width={"30%"} paddingTop={5} paddingRight={5} border={1}>
                    <Popover>
                        <PopoverTrigger>
                            <ButtonGroup variant={"outline"} spacing="1">
                                <Button variant="solid" colorScheme='red' right={2} onClick={(e) => SearchPincode(e)}>
                                    <ArrowForwardIcon w={3} h={3} />
                                </Button>
                            </ButtonGroup>
                        </PopoverTrigger>
                        {DeliveryDateData!=''?
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverHeader><>Product will be Delivered in <span className="Free">{DeliveryDateData}</span> </></PopoverHeader>
                        </PopoverContent>
                        :<></>}
                    </Popover>
                </Box>
            </HStack>

            <HStack paddingBottom={4}>
                <Box width={"80%"} paddingTop={5}>
                    <FormControl >
                        <Input placeholder='Enter Order/PhoneNo' ref={search} />
                    </FormControl>
                </Box>&nbsp;
                <Box width={"30%"} paddingTop={5} paddingRight={5} border={1}>
                    <ButtonGroup variant={"outline"} spacing="1">
                        <Button variant="solid" colorScheme='red' right={2} onClick={(e) => SearchRecord(e)}>
                            <Search2Icon w={3} h={3} />
                        </Button>
                    </ButtonGroup>
                </Box>
            </HStack>

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                Logo
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>

                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">
                                        {userInfo.userData.First_Name} {userInfo.userData.Last_Name}
                                    </Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {userInfo.userData.Role_Name}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem onClick={() => Profile()}>Profile</MenuItem>
                            {(userInfo.userData.Role_Name == "Admin") ? <MenuItem onClick={() => Settings()}>Settings</MenuItem> : <></>}
                            <MenuItem onClick={() => ResetPassword()}>Reset Password</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => Logout()}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
            <ModalComponent Title="User Profile" isOpen={isProfileOpen} onOpen={onProfileOpen} onClose={onProfileClose}>
                <Flex
                    align={'center'}
                    justify={'center'}
                >
                    <Stack spacing={2} w={'full'} maxW={'md'} bg={useColorModeValue('white', 'gray.700')} rounded={'xl'} p={2} my={1}>
                        <FormControl id="userName">
                            <Center>
                                <Stack direction={"row"} spacing={6}>
                                    <Avatar size="xl" src="https://bit.ly/sage-adebayo">

                                    </Avatar>
                                </Stack>
                            </Center>
                        </FormControl>
                        <FormControl id="userName" isRequired>
                            <FormLabel>User name</FormLabel>
                            <Input
                                placeholder="UserName"
                                _placeholder={{ color: 'gray.500' }}
                                type="text"
                                value={userInfo.userData.First_Name + " " + userInfo.userData.Last_Name}
                                readOnly={true}
                            />
                        </FormControl>
                        <FormControl id="email" isRequired>
                            <FormLabel>Email address</FormLabel>
                            <Input
                                placeholder="your-email@example.com"
                                _placeholder={{ color: 'gray.500' }}
                                type="email"
                                value={userInfo.userData.Email_Id}
                                readOnly={true}
                            />
                        </FormControl>
                        <FormControl id="password" isRequired>
                            <FormLabel>Mobile</FormLabel>
                            <Input
                                placeholder="Mobile"
                                _placeholder={{ color: 'gray.500' }}
                                type="text"
                                value={userInfo.userData.Mobile_No}
                                readOnly={true}
                            />
                        </FormControl>
                    </Stack>
                </Flex>
            </ModalComponent>
            {(userInfo.userData.Role_Name == "Admin") ? (
                <ModalComponent Title="Settings" isOpen={isSettingOpen} onOpen={onSettingOpen} onClose={onSettingClose}>
                    <Flex
                        align={'center'}
                        justify={'center'}
                    >
                        <Stack spacing={2} w={'full'} maxW={'md'} rounded={'xl'} p={2} my={1}>

                            <FormControl id="logo">

                                <FormLabel>Upload Logo</FormLabel>
                                <HStack>
                                    <FilePicker
                                        onFileChange={(fileList) => {
                                            LogoFileUpload(fileList);
                                        }}
                                        placeholder="Upload Logo"
                                        clearButtonLabel="Clear"
                                        multipleFiles={false}
                                        accept="image/*"
                                        hideClearButton={false}
                                    />
                                    <Button onClick={() => ResetWebsiteLogo()} marginTop={8}>
                                        Reset Logo
                                    </Button>
                                </HStack>
                            </FormControl>
                            <span className="NoteImageSize" > Note : width * height = 172 * 81</span>

                        </Stack>
                    </Flex>
                    <hr></hr>
                    <Flex
                        align={'center'}
                        justify={'center'}
                    >
                        <Stack
                            spacing={2}
                            w={'full'}
                            maxW={'md'}
                            rounded={'xl'}
                            p={2}
                            my={1}>


                            <FormControl id="phexcode" isRequired>
                                <FormLabel>Primary Color</FormLabel>
                                <Input
                                    placeholder="Ex: 3b7c60"
                                    type="text"
                                    ref={primaryHexCode}
                                />
                            </FormControl>
                            <FormControl id="shexcode" isRequired>
                                <FormLabel>Secondary Color</FormLabel>
                                <Input placeholder="Ex: c01ef4" ref={secondaryHexCode} />
                            </FormControl>
                            <span className="NoteImageSize" > Note: Add Hexcode withcode '#'</span>

                            <Button onClick={() => UpdateWebsiteColor()} marginTop={8}>
                                Update Website Color
                            </Button>
                            <Button onClick={() => ResetWebsiteColor()} marginTop={8}>
                                Reset Website Color
                            </Button>
                        </Stack>
                    </Flex>
                </ModalComponent>
            ) : (<></>)}

            <ModalComponent Title="Reset Password" isOpen={isResetPasswordOpen} onOpen={onResetPasswordOpen} onClose={onResetPasswordClose}>
                <Flex
                    align={'center'}
                    justify={'center'}
                >
                    <Stack
                        spacing={2}
                        w={'full'}
                        maxW={'md'}
                        bg={useColorModeValue('white', 'gray.700')}
                        rounded={'xl'}
                        p={2}
                        my={1}>


                        <FormControl id="email" isRequired>
                            <FormLabel>Email address</FormLabel>
                            <Input
                                placeholder="your-email@example.com"
                                _placeholder={{ color: 'gray.500' }}
                                type="email"
                                value={userInfo.userData.Email_Id}
                                readOnly={true}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>New Password</FormLabel>
                            <Input placeholder='New Password' ref={newPassword} />
                        </FormControl>

                        <Button onClick={() => ResetUserPassword()} marginTop={8}>
                            Reset Password
                        </Button>
                    </Stack>
                </Flex>
            </ModalComponent>

            <ModalComponent scroll="inside" Title="Search Order" size={"6xl"} isOpen={isSearchOrderAddOpen} onOpen={onSearchOrderAddOpen} onClose={onSearchOrderAddClose}  >

                <div>
                    <span className='NoteImageSize'>Note : Copy AWB_No and click on Tracking URL to track order. </span>
                    {SearchOrderList.map((data, index) => {
                        return (
                            <ContainerComponent paddingTop={5}>
                                <Box key={index} marginLeft={3}>
                                    <Stack marginBottom={5}>
                                        <HStack>
                                            <Box width={'180%'}><b>Customer Name: </b> {data.Contact_Person_Name} </Box>
                                            <Box width={'70%'}><b>Mobile No: </b> {data.Contact_Person_Mobile} </Box>
                                            <Box width={'130%'}><b>Email Id: </b> {data.Contact_Person_Email} </Box>
                                        </HStack>
                                        <HStack>

                                            <Box width={'70%'}><b>Order No : </b> {data.OrderNumber} </Box>
                                            <Box width={'75%'}><b>Order Date : </b> {data.OrderDate} </Box>
                                            {data.Booking_Date != null ? <Box width={'75%'}><b>Booking Date : </b> {data.Booking_Date} </Box> : <></>}
                                            <Box width={'60%'}><b>Order Status : </b> {data.OrderStatus} </Box>
                                            <Box width={'60%'}><b>Order Value : </b>{parseFloat(data.OrderValuePostDiscount).toFixed(2)}</Box>

                                        </HStack>
                                        <HStack>
                                            <Box width={'200%'}><b>Product Name: </b> {data.Product_Name} </Box>
                                            <Box width={'70%'}><b>Quantity: </b> {data.Quantity} </Box>
                                            {(data.OrderStatus != "Draft" || data.OrderStatus != "Booked") ? <Box width={'130%'}><b>Delivery Partner: </b> {data.Delivery_Partner} </Box> : <></>}
                                        </HStack>
                                        {(data.OrderStatus != "Draft" || data.OrderStatus != "Booked") ?
                                            <HStack>
                                                <Box width={'full'}><b>Tracking URL: </b><a target="_blank" href={data.Trcacking_Url}> <span colorScheme='green'> {data.Trcacking_Url} </span></a></Box>
                                                <Box width={'full'}><b>AWB No: </b> {data.AWB_No} </Box>
                                            </HStack>
                                            : <></>}
                                    </Stack>
                                </Box>
                            </ContainerComponent>
                        )
                    })}
                </div>


            </ModalComponent>

        </Flex >
    )
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

export default connect(mapStateToProps)(TopMenuComponent);