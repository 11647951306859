import React from 'react';
import { Pie } from 'react-chartjs-2';

const options = {
  responsive: true,
};


const PieChartComponent = ({ data }) => {
  return<div style={{ width: '480px'}}>
  <Pie data={data} options={options} />
  </div>
   ;
};

export default PieChartComponent;