import React from 'react'
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-spinners/PulseLoader'
import "./spinner.css"

function LoadingSpinnerComponent() {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress && (
            <div className="spinner">
                <div>
                    <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
                </div>
            </div>
        )
    )
}

export default LoadingSpinnerComponent
