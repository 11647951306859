import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetProductConfigDetailByProductId = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductConfiguration/GetProductConfigurationByProductId?productId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveProductDiscountConfigDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductConfiguration/SaveProductDiscountConfiguration', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}