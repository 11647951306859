import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as UserService from '../../Services/User/UserService.js'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { FormControl, Select, FormLabel, HStack, FormErrorMessage, Input, Checkbox, useDisclosure, Stack, ButtonGroup, Tfoot, Box, Table as C_Table, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import { Table } from "react-chakra-pagination";
import Multiselect from 'multiselect-react-dropdown';
import * as ScService from '../../Services/ServiceCenter/ServiceCenter.js';
import { Tooltip } from '@chakra-ui/react'
import useLoaderState from '../../Common/useLoaderState';

function UserMasterComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const [UserList, setUserMasterList] = useState([]);
    const [UserRoleList, setRoleList] = useState([]);
    const [selecteduserid, setSelectedUser] = useState(0);
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose, onOpen: onScOpen, onClose: onScClose } = useDisclosure();
    const selectedUser = useRef();
    const [page, setPage] = useState(1);
    const [SelectedScUserId, SetSCUserId] = useState(0);
    const { isOpen: isServicecentereOpen, onOpen: onServicecenterOpen, onClose: onServicecenterClose, } = useDisclosure()
    // const { handleSubmit: handleServicecenterSubmit, register: registerServiceCenter, setValue: setValuePincode, reset: resetPincode, formState: { errors: errorPincode } } = useForm({ mode: "onBlur" });
    const [Servicecenterlist, setServicecenterlist] = useState([]);
    const [SelectedServicecenterList, setSelectedServicecenterList] = useState([]);

    const [IsLoading] = useLoaderState();

    const [SCMappingModel, setSCMappingModel] = useState({});//Payment

    useEffect(() => {
        GetRoleList();
        LoadUserMasterList();

    }, [])

    const tableData = UserList.map((user, index) => ({
        key: index,
        Id: user.User_Id,
        User_Name: user.First_Name + ' ' + user.Last_Name,
        Email_Id: user.Email_Id,
        Password: user.Password,
        Role_Name: user.Role_Name,
        Is_Active:
            (
                <Checkbox colorScheme={user.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                </Checkbox>
            ),
        Action: (
            <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit User' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditUserMaster(user.User_Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete User' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteUser(user.User_Id, user.Is_Active)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
                <ButtonGroup variant={"outline"} spacing="1">
                    <Tooltip label='View SC Mapping' placement='auto-start'>
                        <Button colorScheme='whatsapp' variant='solid' size={"xs"} onClick={() => ViewUserSCMapping(user.User_Id)}>
                            <HamburgerIcon />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </ButtonGroup >

        )

    }));

    const tableColumns = [
        {
            Header: "Id",
            accessor: "Id"
        },
        {
            Header: "User Name",
            accessor: "User_Name"
        },
        {
            Header: "Email Id",
            accessor: "Email_Id"
        },
        {
            Header: "Password",
            accessor: "Password"
        },
        {
            Header: "Role Name",
            accessor: "Role_Name"
        },
        {
            Header: "IsActive",
            accessor: "Is_Active"
        },
        {
            Header: "Action",
            accessor: "Action"
        }
    ];
    const LoadUserMasterList = () => {
        UserService.GetUserMasterList(selectedUser.current.value).then(data => {
            if (data.data.IsSuccess) {
                setUserMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const Searchuser = () => {
        debugger;
        UserService.GetUserDetailsByEmail(selectedUser.current.value).then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setUserMasterList(data.data.Data);

            } else {
                setUserMasterList([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong.");
        })
    }


    const AddUser = () => {
        reset({});
        onAddOpen();
        setSelectedUser(0);
    }

    const GetRoleList = () => {

        UserService.GetUserRoleList().then(data => {
            if (data.data.IsSuccess) {

                setRoleList(data.data.Data);
            }
        }).catch(error => {

        })
    }
    const SaveUserDetail = (value) => {

        value.Role_Id = parseInt(value.Role_Id);
        if (selecteduserid <= 0) {
            UserService.SaveUserDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadUserMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selecteduserid;
            UserService.UpdateUserDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadUserMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }

    const EditUserMaster = (id) => {
        setSelectedUser(id);
        UserService.GetUserDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteUser = (id, IsActive) => {
        debugger;
        var action = window.confirm('Do you want to delete User?');
        if (action == true) {
            UserService.ActivateDeactivateUser(id, !IsActive).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadUserMasterList();
                }
            }).catch(error => {

            })
        }
    }


    const DeleteScMappingById = (id, IsActive) => {
        debugger;
        var action = window.confirm('Do you want to delete Mapping?');
        if (action == true) {
            UserService.ActivateDeactivateScMapping(id, !IsActive).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    //GetUserScMapping();
                }
            }).catch(error => {

            })
        }
    }

    const updateServicecenterList = (data) => {
        debugger
        setSelectedServicecenterList(data);
        console.log(setSelectedServicecenterList);
    }

    const SaveServiceCenterDetails = () => {
        debugger;
        if (SelectedServicecenterList.length > 0) {
            const SCUserMappingResponseModel = SCMappingModel;
            SCUserMappingResponseModel.SC_List = SelectedServicecenterList;
            SCUserMappingResponseModel.User_Id = parseInt(SelectedScUserId);
            UserService.SaveSCUserMappingDetail(SCUserMappingResponseModel).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("User Mapped");
                    ViewUserSCMapping(SelectedScUserId);
                }
                else {
                    toast.error("Something went wrong")
                }
            }).catch(error => {

            });
        }
        else {
            toast.error("Select Service Center First!")
        }
    }
    const GetServicecenterList = () => {
        debugger;
        ScService.GetServiceCenterList().then(data => {
            if (data.data.IsSuccess) {
                setServicecenterlist(data.data.Data);
            } else {

            }
        }).catch(error => {

        })
    }

    const ViewUserSCMapping = (id) => {
        debugger;
        GetServicecenterList();
        SetSCUserId(parseInt(id));

        UserService.GetUserScMppingListById(parseInt(id)).then(data => {
            if (data.data.IsSuccess) {
                setSelectedServicecenterList(data.data.Data);
                onServicecenterOpen();
            }
            else {
                setServicecenterlist([]);
                onServicecenterOpen();
            }
        }).catch(error => {

        })
    }

    return (
        <ContainerComponent Title="User List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="User" AddNewAction={AddUser}>
            <HStack paddingLeft={5}>
                <Box width={"full"} paddingTop={5}>
                    <FormControl >
                        <Input placeholder='Enter Email' ref={selectedUser} />
                    </FormControl>
                </Box>
                <Box width={"full"} paddingBottom={3}>
                    <Button colorScheme='green' onClick={() => Searchuser()} marginTop={8}>
                        Search
                    </Button>
                </Box>
            </HStack>

            <Table
                size="sm"
                emptyData={{
                    text: "No Data Available."
                }}
                colorScheme="blue"
                totalRegisters={UserList.length}
                page={page}
                onPageChange={(page) => setPage(page)}
                columns={tableColumns}
                data={tableData}
            />
            <ModalComponent size="2xl" Title="Add/Update UserMaster" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveUserDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.First_Name}>
                                <FormLabel>First Name</FormLabel>
                                <Input placeholder='Enter First Name' {...register("First_Name", { required: "First Name is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.First_Name && errors.First_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Last_Name}>
                                <FormLabel>Last Name</FormLabel>
                                <Input placeholder='Enter Last Name' {...register("Last_Name", { required: "Last Name is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Last_Name && errors.Last_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Email_Id}>
                                <FormLabel>Email</FormLabel>
                                <Input placeholder='Enter Email' {...register("Email_Id", { required: "Email Id is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Email_Id && errors.Email_Id.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Password}>
                                <FormLabel>Password</FormLabel>
                                <Input placeholder='Enter Password' {...register("Password", { required: "Password is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Password && errors.Password.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Mobile</FormLabel>
                                <Input type={"mobile"} placeholder='Your Mobile' {...register("Mobile_No")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Role_Id}>
                                <FormLabel>Select Role</FormLabel>
                                <Select className="form-control" {...register("Role_Id", { required: "Role is Mandatory" })} >
                                    <option value="">Select Role</option>
                                    {
                                        UserRoleList.map((data, index) => {
                                            return (
                                                <option value={data.Role_Id} key={index}>{data.Role_Name}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.Role_Id && errors.Role_Id.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl  >
                                <FormLabel>Employee Code</FormLabel>
                                <Input placeholder='Your Employee Code' {...register("Emp_Code")} />

                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Select Region</FormLabel>
                                <Select className="form-control" {...register("Region")} >
                                    <option value="">Select Region</option>
                                    {
                                        CommonVariables.RegionName.map((data, index) => {

                                            return (
                                                <option value={data.Value} key={index}>{data.Key}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            {
                                selecteduserid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green' defaultChecked {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>
                </Stack>
            </ModalComponent>
            {/* mapping part starts */}

            <ModalComponent Title="User SC Mapping" scroll="inside" size={"xl"} isOpen={isServicecentereOpen} onOpen={onServicecenterOpen} onClose={onServicecenterClose}>
                <FormControl>
                    <FormLabel>Service Center</FormLabel>

                    <Multiselect
                        placeholder='Select Service Center'
                        options={Servicecenterlist}
                        selectedValues={SelectedServicecenterList}
                        onSelect={updateServicecenterList}
                        onRemove={updateServicecenterList}
                        displayValue="SC_Name">
                    </Multiselect>


                </FormControl>
                <span className='NoteImageSize'>Note :  After Adding and removing service center click on Add button else no action will perform.</span>
                <Divider />
                <Box width={"full"} paddingBottom={3}>
                    <Button disabled={IsLoading} colorScheme='red' onClick={() => SaveServiceCenterDetails()} marginTop={8}>
                        Add
                    </Button>
                </Box>
                <Divider />

                <C_Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>SC Code</Th>
                            <Th>SC Name</Th>
                            <Th>City</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {SelectedServicecenterList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.SC_Code}</Td>
                                <Td>{data.SC_Name}</Td>
                                <Td>{data.SC_City}</Td>
                                {/* <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => DeleteScMappingById(data.Id)}>
                                            <DeleteIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Td> */}
                            </Tr>)
                        })}
                    </Tbody>
                    {SelectedServicecenterList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </C_Table>

            </ModalComponent>
        </ContainerComponent >
    )
}

export default UserMasterComponent