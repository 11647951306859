import axios from "axios";
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetBannerList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Banner/GetBannerList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetBannerById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Banner/GetBannerById?bannerId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveBanner = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Banner/SaveBannerDetail`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdateBanner = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Banner/UpdateBannerDetail`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const ActiveDeActiveBannerDetail = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Banner/ActivateDeactivateBanner?bannerId=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
