import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as BlogMasterService from '../../Services/BlogMaster/BlogMasterService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { FormControl, Select, FormLabel, HStack, FormErrorMessage, Input, Checkbox, useDisclosure, Stack, Table, ButtonGroup, Tfoot, Box, TableContainer, Button, Thead, Tbody, Td, Tr, Th, Textarea } from '@chakra-ui/react'
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import FilePicker from 'chakra-ui-file-picker';
import * as AttachmentService from '../../Services/Attachment/AttachmentService';
import JoditEditor from "jodit-react";
import { Tooltip } from '@chakra-ui/react'

function BlogComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const [BlogList, setBlogList] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(0);
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const detaileditor = useRef('');
    const shorteditor = useRef('');
    const [detaildescription, setDetailDescription] = useState('');
    const [shortdescription, setShortDescription] = useState('');
    const [BlogLargeimage, setLargeImage] = useState('');
    const [BlogSmallImage, setShortImage] = useState('');
    const shortdescrip_config = { //Short_DetailDescription
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        height: 300
    }
    const detaildescrip_config = { //Product_DetailDescription
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        height: 300
    }

    useEffect(() => {
        LoadBlogMasterDetail();
    }, [])

    const LoadBlogMasterDetail = () => {
        BlogMasterService.GetBlogList().then(data => {
            if (data.data.IsSuccess) {
                setBlogList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const AddBlog = () => {
        debugger;
        reset({});
        setSelectedBlog(0);
        // detaileditor.current.value='';
        // shorteditor.current.value ='';
        setDetailDescription('');
        setShortDescription('');
        onAddOpen();
    }

    const EditBlog = (id) => {
        setSelectedBlog(id);
        BlogMasterService.GetBlogDetailById(id).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();

                setDetailDescription(data.data.Data.Blog_Long_Description);
                //detaileditor.current.value = data.data.Data.Blog_Long_Description;
                setShortDescription(data.data.Data.Blog_Short_Description);
                //shorteditor.current.value = data.data.Data.Blog_Short_Description;

                if (data.data.Data.Blog_Small_Image != '' && data.data.Data.Blog_Large_Image != '') {
                    setLargeImage(data.data.Data.Blog_Large_Image);
                    setShortImage(data.data.Data.Blog_Small_Image);
                }


            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const SaveBlog = (value) => {
        if (shorteditor.current.value == "" || shorteditor.current.value == null) {
            toast.error('Blog Short Description is mandatory!')
            return false;
        }

        if (detaileditor.current.value == "" || detaileditor.current.value == null) {
            toast.error('Blog Long Description is mandatory!')
            return false;
        }

        value.Blog_Long_Description = detaileditor.current.value;
        value.Blog_Short_Description = shorteditor.current.value;

        // if (value.Blog_Small_Image == undefined || value.Blog_Small_Image == null || value.Blog_Small_Image.length <= 0) {
        //     toast.error('Uploading Small Image is Mandatory!')
        //     return false;
        //     // setError('Blog_Small_Image', { types: { required: "Small Image is Mandatory" } })

        // }
        // if (value.Blog_Large_Image == undefined || value.Blog_Large_Image == null || value.Blog_Large_Image.length <= 0) {
        //     toast.error('Uploading Large Image is Mandatory!')
        //     return false;
        //     // setError('Blog_Large_Image', { types: { required: "Large Imge is Mandatory" } })

        // }
        debugger;
        console.log(value);
        if (selectedBlog <= 0) {
            BlogMasterService.SaveBlogDetail(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadBlogMasterDetail();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedBlog;
            BlogMasterService.UpdateBlogDetail(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadBlogMasterDetail();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
    const SmallImageFileUploadToS3 = (fileList) => {
        if (fileList != null && fileList.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', fileList[0])

            AttachmentService.FileUploadToS3(formdata).then(response => {
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                    setValue('Blog_Small_Image', response.data.Data)
                }
                else {
                    // setWhatsApp_Attachment("");
                    setValue('Blog_Small_Image', "")
                    setError('Blog_Small_Image', { types: { required: "Small Image is Mandatory" } })
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
                setError('Blog_Small_Image', { types: { required: "Small Image is Mandatory" } })
            });
        }
        else {
            toast.error('Please choose Image First!')
            setError('Blog_Small_Image', { types: { required: "Small Image is Mandatory" } })
        }
    }

    const LargeBlogFileUploadToS3 = (fileList) => {
        if (fileList != null && fileList.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', fileList[0])

            AttachmentService.FileUploadToS3(formdata).then(response => {
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                    setValue('Blog_Large_Image', response.data.Data)
                }
                else {
                    // setWhatsApp_Attachment("");
                    setValue('Blog_Large_Image', "")
                    setError('Blog_Large_Image', { types: { required: "Large Image is Mandatory" } })
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
                setError('Blog_Large_Image', { types: { required: "Large Image is Mandatory" } })
            });
        }
        else {
            toast.error('Please choose Image First!')
            setError('Blog_Large_Image', { types: { required: "Large Image is Mandatory" } })
        }
    }

    const DeleteBlog = (id, IsActive) => {
        debugger;
        var action = window.confirm(IsActive == true ? 'Do you want to DeActivate Blog?' : "Do you want to Activate the Blog?");
        if (action == true) {
            BlogMasterService.ActivateDeActivateBlog(id, !IsActive).then(data => {
                if (data.data.IsSuccess) {
                    toast.success(data.data.ResponseMessage);
                    LoadBlogMasterDetail();
                }
                else {
                    LoadBlogMasterDetail();
                }
            }).catch(error => {

            })
        }
    }

    return (
        <ContainerComponent Title="Blog List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Blog Master" AddNewAction={AddBlog}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Blog Title</Th>
                            <Th>Blog Category</Th>
                            <Th>Blog Slug</Th>
                            <Th>Is Active</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {BlogList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Blog_Title}</Td>
                                <Td>{data.Blog_Category}</Td>
                                <Td>{data.Blog_SEO_Title}</Td>
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Tooltip label='Edit Blog' placement='auto-start'>
                                            <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditBlog(data.Id)}>
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip label={data.Is_Active ? 'DeActivate Blog' : 'Activate Blog'} placement='auto-start'>
                                            <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteBlog(data.Id, data.Is_Active)}>
                                                <DeleteIcon />
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {BlogList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
            <ModalComponent size='full' scroll="inside" Title="Add/Update Blog " isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveBlog)}>
                <Stack spacing={4}>
                    <FormControl isRequired isInvalid={errors.Blog_Title}>
                        <FormLabel>Blog Title</FormLabel>
                        <Input placeholder='Blog Title' {...register("Blog_Title", { required: "Title is Mandatory" })} />
                        <FormErrorMessage>
                            {errors.Blog_Title && errors.Blog_Title.message}
                        </FormErrorMessage>
                    </FormControl>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Blog_SEO_Title}>
                                <FormLabel>Blog Slug</FormLabel>
                                <Input placeholder='Blog Slug' {...register("Blog_SEO_Title", { required: "Blog Slug is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Blog_SEO_Title && errors.Blog_SEO_Title.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Blog_Category}>
                                <FormLabel>Blog Category</FormLabel>
                                <Input placeholder='Blog Category' {...register("Blog_Category", { required: "Blog Category is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Blog_Category && errors.Blog_Category.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>

                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Blog_Small_Image}>
                                <FormLabel>Blog Thumbnail Image</FormLabel>
                                <FilePicker
                                    onFileChange={(fileList) => { SmallImageFileUploadToS3(fileList) }}
                                    placeholder="Blog Thumbnail Image"
                                    clearButtonLabel="Clear"
                                    multipleFiles={false}
                                    accept="application/png"
                                    hideClearButton={false}
                                />
                                <FormErrorMessage>
                                    {errors.Blog_Small_Image && errors.Blog_Small_Image.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Blog_Large_Image}>
                                <FormLabel>Blog Large Image</FormLabel>
                                <FilePicker
                                    onFileChange={(fileList) => { LargeBlogFileUploadToS3(fileList) }}
                                    placeholder="Blog Large Image"
                                    clearButtonLabel="Clear"
                                    multipleFiles={false}
                                    accept="application/png"
                                    hideClearButton={false}
                                />
                                <FormErrorMessage>
                                    {errors.Blog_Large_Image && errors.Blog_Large_Image.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>

                    <HStack spacing={"2"} paddingTop={"5"}>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Blog Short Description</FormLabel>
                                <JoditEditor
                                    ref={shorteditor}
                                    value={shortdescription}
                                    config={shortdescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newShortContent => setShortDescription(newShortContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newShortContent => { }}
                                />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack spacing={"2"} paddingTop={"5"}>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Blog Long Description</FormLabel>
                                <JoditEditor
                                    ref={detaileditor}
                                    value={detaildescription}
                                    config={detaildescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newDetailContent => setDetailDescription(newDetailContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newDetailContent => { }}
                                />
                            </FormControl>
                        </Box>
                    </HStack>

                </Stack>
            </ModalComponent>
        </ContainerComponent >
    )
}

export default BlogComponent

