import { useColorModeValue } from '@chakra-ui/color-mode'
import { Box } from '@chakra-ui/layout'
import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import LeftMenuComponent from '../Components/Menu/LeftMenuComponent'
import TopMenuComponent from '../Components/Menu/TopMenuComponent'
import { store } from '../Store/store'
import { useDisclosure } from '@chakra-ui/react';
import * as LoginService from '../Services/Login/LoginService'
import toast, { Toaster } from 'react-hot-toast';

function _DashboardLayout(props) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const userInfo = store.getState();
    const isAuthenticated = userInfo.isAuthenticated;
    const history = useNavigate();

    const IPAddress = userInfo.IP_Address;
    const [ClientIPAddress, setClientIPAddress] = useState("");


    // function GetIPAddressDetail() {
    //     debugger;
    //     LoginService.GetIPAddress().then(data => {
    //         if (data.data.IsSuccess) {
    //             if (isAuthenticated && IPAddress==data.data.Data) {
    //                 return (
    //                     <Box minH="100vh">
    //                         <LeftMenuComponent isOpen={isOpen} onOpen={onOpen} onClose={onClose}></LeftMenuComponent>
    //                         <TopMenuComponent isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    //                         <Box ml={{ base: 0, md: 48 }} p="2" backgroundColor={"white"}>
    //                             {props.children}
    //                         </Box>
    //                     </Box>
    //                 )
    //             }
    //             else {
    //                 history('/login');
    //             }
    //         } else {
    //             history('/login');
    //         }
    //     }).catch(error => {
    //         history('/login');
    //     })
    // }

    // GetIPAddressDetail();

    if (isAuthenticated) {
        return (
            <Box minH="100vh">
                <LeftMenuComponent isOpen={isOpen} onOpen={onOpen} onClose={onClose}></LeftMenuComponent>
                <TopMenuComponent isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
                <Box ml={{ base: 0, md: 48 }} p="2" backgroundColor={"white"}>
                    {props.children}
                </Box>
            </Box>
        )
    }
    else {
        return <Navigate to="/login" />
    }


}

export default _DashboardLayout