import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Textarea, HStack, Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as ServiceCenterService from '../../Services/ServiceCenter/ServiceCenter'
import * as PincodeService from '../../Services/Pincode/PincodeService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { Tooltip } from '@chakra-ui/react'

function ServiceCenterComponent() {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { handleSubmit: handlePincodeSubmit, register: registerPincode, setValue: setValuePincode, reset: resetPincode, formState: { errors: errorPincode } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isPinCodeOpen, onOpen: onPinCodeOpen, onClose: onPinCodeClose } = useDisclosure()
    const [ServiceCenterList, setServiceCenter] = useState([]);
    const [ServiceCenterDetail, setServiceCenterDetail] = useState({});
    const [IsLoading] = useLoaderState();
    const [SelectedServiceCenter, setServiceCenterId] = useState(0);
    const [SelectedPinCode, setPinCode] = useState(0);
    const [PinCodeDropDownValue, setPinCodeDropDown] = useState([]);
    const [PinCodeListById, setPinCodeListById] = useState([]);


    useEffect(() => {
        //debugger;
        GetServiceCenterList();
        GetPinCodeList();
    }, [])

    const AddServiceCenter = () => {
        reset({});
        onAddOpen();
        setValue('SC_Id', 0);
    }

    const GetServiceCenterList = () => {
        ServiceCenterService.GetServiceCenterList().then(data => {
            if (data.data.IsSuccess) {
                setServiceCenter(data.data.Data);
            } else {
                //toast.error("Somthing went wrong.");
            }
        }).catch(error => {
            //toast.error("Somthing went wrong.");
        })
    }

    const GetPinCodeList = () => {
        PincodeService.GetPincodeList().then(data => {
            if (data.data.IsSuccess) {
                setPinCodeDropDown(data.data.Data);
            } else {
                //toast.error("Somthing went wrong.");
            }
        }).catch(error => {
            //toast.error("Somthing went wrong.");
        })
    }

    const SaveServiceCenter = (value) => {

        if (parseInt(value.SC_Id) > 0) {
            ServiceCenterService.UpdateServiceCenterDetail(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("SC Update");
                    onAddClose();
                    GetServiceCenterList();
                }
                else {
                    toast.error("Something went wrong")
                }
            }).catch(error => {

            });
        }
        else {
            ServiceCenterService.SaveServiceCenterDetail(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("SC Save");
                    onAddClose();
                    GetServiceCenterList();
                }
                else {
                    toast.error("Something went wrong")
                }
            }).catch(error => {

            });
        }
    }

    const EditServiceCenter = (id) => {
        ServiceCenterService.GetServiceCenterById(id).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            }
        }).catch(error => {

        })
    }

    const ViewSCPinCodeMapping = (id) => {
        setServiceCenterId(id);
        onPinCodeOpen();
        PincodeService.GetPinocdeListBySc(id).then(data => {
            if (data.data.IsSuccess) {
                setPinCodeListById(data.data.Data);
                onPinCodeOpen();
            }
            else {
                setPinCodeListById([]);
                onPinCodeOpen();
            }
        }).catch(error => {

        })
    }

    const DeleteServiceCenter = (id, IsActive) => {
        ServiceCenterService.ActiveDeActiveServiceCenterDetail(id, !IsActive).then(data => {
            if (data.data.IsSuccess) {
                console.log(data.data)
                toast.success(data.data.ResponseMessage);
                GetServiceCenterList();
            }
        }).catch(error => {

        })
    }

    const onPinCodeChange = (e) => {
        e.preventDefault();
        setPinCode(e.target.value);
    }

    const savePinCodeDetails = () => {

        PincodeService.MapPincodeServiceCenter(SelectedServiceCenter, SelectedPinCode).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Pincode Save");
                resetPincode()
                ViewSCPinCodeMapping(SelectedServiceCenter);
            }
            else {
                toast.error("Something went wrong")
            }
        }).catch(error => {

        });
    }

    // const [pickerItems, setPickerItems] = React.useState(countries);
    // const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);

    // const handleCreateItem = (item: Item) => {
    //   setPickerItems((curr) => [...curr, item]);
    //   setSelectedItems((curr) => [...curr, item]);
    // };

    const DeletePinCodeMappingById = (id) => {
        PincodeService.DeletePinCodeMappingById(id).then(data => {
            if (data.data.IsSuccess) {
                console.log(data.data)
                toast.success(data.data.ResponseMessage);
                debugger;
                ViewSCPinCodeMapping(SelectedServiceCenter);
            }
        }).catch(error => {

        })
    }

    return (
        <ContainerComponent Title="Service Center" Icon={<FaCopyright />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Service Center" AddNewAction={AddServiceCenter}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>SC Name</Th>
                            <Th>SC Code</Th>
                            <Th>Pincode</Th>
                            <Th>SC GST No</Th>
                            <Th>Is Active</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ServiceCenterList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.SC_Id}</Td>
                                <Td>{data.SC_Name}</Td>
                                <Td>{data.SC_Code}</Td>
                                <Td>{data.Pincode}</Td>
                                <Td>{data.SC_GST_No}</Td>
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Edit Service Center' placement='auto-start'>
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditServiceCenter(data.SC_Id)}>
                                            <EditIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>&nbsp;&nbsp;
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Delete Service Center' placement='auto-start'>
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => DeleteServiceCenter(data.SC_Id, data.Is_Active)}>
                                            <DeleteIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>&nbsp;&nbsp;
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='View Sc Mapping' placement='auto-start'>
                                        <Button colorScheme='whatsapp' variant='solid' size={"xs"} onClick={() => ViewSCPinCodeMapping(data.SC_Id)}>
                                            <HamburgerIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {ServiceCenterList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>

            <ModalComponent scroll="inside" Title="Add/Update Service Center" size={"3xl"} isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveServiceCenter)}>
                <Input hidden  {...register("SC_Id")} />
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SC_Name}>
                                <FormLabel>Service Center Name</FormLabel>
                                <Input placeholder='Service Center Name' {...register("SC_Name", { required: "Service Center Name is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SC_Name && errors.SC_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Pincode}>
                                <FormLabel>Service Pincode</FormLabel>
                                <Input placeholder='Service Pincode' {...register("Pincode", { required: "Service Pincode is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Pincode && errors.Pincode.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SC_Code}>
                                <FormLabel>Service Center Code</FormLabel>
                                <Input placeholder='Service Center Code' {...register("SC_Code", { required: "Service Center Code is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SC_Code && errors.SC_Code.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Delivery_Charges}>
                                <FormLabel>Delivery Charges</FormLabel>
                                <Input placeholder='Delivery Charges' {...register("Delivery_Charges", { required: "Delivery Charges is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Delivery_Charges && errors.Delivery_Charges.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SC_City}>
                                <FormLabel>Service Center City</FormLabel>
                                <Input placeholder='Service Center City' {...register("SC_City", { required: "Service Center City is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SC_City && errors.SC_City.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SC_State}>
                                <FormLabel>Service Center State</FormLabel>
                                <Input placeholder='Service Center State' {...register("SC_State", { required: "Service Center State is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SC_State && errors.SC_State.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SC_Type}>
                                <FormLabel>Service Center Type</FormLabel>
                                <Input placeholder='Service Center Type' {...register("SC_Type", { required: "Service Center Type is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SC_Type && errors.SC_Type.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SC_GST_No}>
                                <FormLabel>Service Center GST No</FormLabel>
                                <Input placeholder='Service Center GST No' {...register("SC_GST_No", { required: "Service Center GST No is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SC_GST_No && errors.SC_GST_No.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                    <Box >
                            <FormControl isRequired isInvalid={errors.Sc_Phone}>
                                <FormLabel>Service Center Mobile</FormLabel>
                                <Input placeholder='Sc Mobile' {...register("Sc_Phone", { required: "Mobile is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Sc_Phone && errors.Sc_Phone.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SC_Address}>
                                <FormLabel>Service Center Address</FormLabel>
                                <Textarea placeholder='Service Center Address' {...register("SC_Address", { required: "Service Center Address is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SC_Address && errors.SC_Address.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>
                    <Checkbox hidden  {...register("Is_Active")} > </Checkbox >

                </Stack>

            </ModalComponent>


            <ModalComponent Title="PinCode SC Mapping" scroll="inside" size={"xl"} isOpen={isPinCodeOpen} onOpen={onPinCodeOpen} onClose={onPinCodeClose}>
                <FormControl isRequired isInvalid={errorPincode.PinCode}>
                    <FormLabel>PinCode</FormLabel>
                    <Select className="form-control" {...register("PinCode", { registerPincode: "Select PinCode is Mandatory" })} onChange={(e) => onPinCodeChange(e)} >
                        <option value="">Select PinCode</option>
                        {
                            PinCodeDropDownValue.map((data, index) => {
                                return (
                                    <option value={data.Id} key={index}>{data.Pincode}</option>
                                )
                            })
                        }
                    </Select>
                    <FormErrorMessage>
                        {errorPincode.PinCode && errorPincode.PinCode.message}
                    </FormErrorMessage>
                </FormControl>
                <Divider />
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center">
                    <ButtonGroup variant='outline' spacing='4' alignContent={"center"} paddingTop="2" paddingBottom="2">
                        <Button type='button' onClick={handlePincodeSubmit(savePinCodeDetails)} colorScheme="whatsapp">Add</Button>
                    </ButtonGroup>
                </Box>
                <Divider />
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Id</Th>
                                <Th>SC Name</Th>
                                <Th>City</Th>
                                <Th>PinCode</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {PinCodeListById.map((data, index) => {
                                return (<Tr key={index}>
                                    <Td>{data.Id}</Td>
                                    <Td>{data.SC_Name}</Td>
                                    <Td>{data.Pincode_City}</Td>
                                    <Td>{data.Pincode}</Td>
                                    <Td>
                                        <ButtonGroup variant={"outline"} spacing="1">
                                            <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => DeletePinCodeMappingById(data.Id)}>
                                                <DeleteIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </Td>
                                </Tr>)
                            })}
                        </Tbody>
                        {PinCodeListById.length <= 0 ? (
                            <Tfoot>
                                <Tr colSpan="3">
                                    <Td>
                                        No Data Available
                                    </Td>
                                </Tr>
                            </Tfoot>
                        ) : <></>
                        }

                    </Table>
                </TableContainer>
            </ModalComponent>

        </ContainerComponent >
    )
}

export default ServiceCenterComponent