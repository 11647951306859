import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as BannerSectionService from '../../Services/BannerSection/BannerSectionService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { FormControl, Select, FormLabel, HStack, FormErrorMessage, Input, Checkbox, useDisclosure, Stack, Table, ButtonGroup, Tfoot, Box, TableContainer, Button, Thead, Tbody, Td, Tr, Th } from '@chakra-ui/react'
import { EditIcon,DeleteIcon } from '@chakra-ui/icons'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { Tooltip } from '@chakra-ui/react'

function BannerSectionComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const [BannerSectionList, setBannerSectionList] = useState([]);
    const [selectedBannerSection, setSelectedBannerSection] = useState(0);
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();

    useEffect(() => {
        LoadBannerSection();
    }, [])

    const LoadBannerSection = () => {
        BannerSectionService.GetBannerSectionList().then(data => {
            if (data.data.IsSuccess) {
                setBannerSectionList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const AddBannerSection = () => {
        reset({});
        setSelectedBannerSection(0);
        onAddOpen();
    }

    const EditBannerSection = (id) => {
        setSelectedBannerSection(id);
        BannerSectionService.GetBannerSectionById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const SaveBannerSection = (value) => {
        if (selectedBannerSection <= 0) {
            BannerSectionService.SaveBannerSection(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadBannerSection();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedBannerSection;
            BannerSectionService.UpdateBannerSection(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadBannerSection();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }

    const DeleteBanner = (id, IsActive) => {
        BannerSectionService.ActiveDeActiveBannerSection(id, !IsActive).then(data => {
            if (data.data.IsSuccess) {
                toast.success(data.data.ResponseMessage);
                LoadBannerSection();
            } else{
                toast.error("Something went wrong")
            }
        }).catch(error => {

        })
    }

    
    return (
        <ContainerComponent Title="Banner Section" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Banner Section" AddNewAction={AddBannerSection}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Section</Th>
                            <Th>Screen</Th>
                            <Th>Is Active</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {BannerSectionList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>BSE-{data.Id}</Td>
                                <Td>{data.Banner_Section}</Td>
                                <Td>{data.Screen_Name}</Td>
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Edit Banner' placement='auto-start'>
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditBannerSection(data.Id)}>
                                            <EditIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>&nbsp;&nbsp;
                                    <ButtonGroup variant={"outline"} spacing="1">
                                    <Tooltip label='Delete Banner' placement='auto-start'>
                                        <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteBanner(data.Id, data.Is_Active)}>
                                            <DeleteIcon />
                                        </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {BannerSectionList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
            <ModalComponent Title="Add/Update Banner Section" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveBannerSection)}>
                <FormControl isRequired isInvalid={errors.Banner_Section}>
                    <FormLabel>Banner Section</FormLabel>
                    <Input placeholder='Banner Section Name' {...register("Banner_Section", { required: "Field is Mandatory" })} />
                    <FormErrorMessage>
                        {errors.Banner_Section && errors.Banner_Section.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.Screen_Name}>
                    <FormLabel>Screen Name</FormLabel>
                    <Input placeholder='Screen Name' {...register("Screen_Name", { required: "Field is Mandatory" })} />
                    <FormErrorMessage>
                        {errors.Screen_Name && errors.Screen_Name.message}
                    </FormErrorMessage>
                </FormControl>
                {
                    selectedBannerSection > 0 ? (
                        <FormControl isRequired isInvalid={errors.Is_Active}>
                            <FormLabel>Is Active</FormLabel>
                            <Checkbox colorScheme='green' defaultChecked {...register("Is_Active", { required: "Selection is mandatory" })}>
                            </Checkbox>
                            <FormErrorMessage>
                                {errors.Is_Active && errors.Is_Active.message}
                            </FormErrorMessage>
                        </FormControl>
                    ) : <></>
                }
            </ModalComponent>
        </ContainerComponent >
    )
}

export default BannerSectionComponent