import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChartComponent = ({ data }) => {
  return<div style={{ width: '500px'}}>
  <Doughnut data={data} />
  </div>
   ;
};

export default DoughnutChartComponent;