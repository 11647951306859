import React, { useState, useEffect, useRef } from "react";
import * as PincodeService from '../../Services/Pincode/PincodeService'
import * as DeliveryStatusService from '../../Services/OrderList/OrderListService'
import toast, { Toaster } from 'react-hot-toast';
import {
  FormControl,
  IconButton,
  Avatar,
  Input,
  Button,
  Box,
  CloseButton,
  Flex,
  Stack,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Grid,
  GridItem,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Divider,
  Container,
  Switch,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Spacer
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import * as StockService from "../../Services/Product/ProductService";
import BarChartComponent from "../Chart/BarChartComponent";
import LineChartComponent from "../Chart/LineChartComponent";
import RadarChartComponent from "../Chart/RadarChartComponent";
import PolarChartComponent from "../Chart/PolarChartComponent";
import PieChartComponent from "../Chart/PieChartComponent";
import DoughnutChartComponent from "../Chart/DoughnutChartComponent";
import * as CommonVariables from "../../Common/CommonVariables";
import ContainerComponent from '../Container/ContainerComponent';
import { store } from '../../Store/store'

function DashboardComponent(props) {
  const [RemainStockList, setRemainStockList] = useState([]);
  const [DateList, setDateList] = useState([]);
  const [CCDataList, setCCDataList] = useState([]);
  const [WebDataList, setWebDataList] = useState([]);
  const [MobDataList, setMobDataList] = useState([]);
  const [SalesDataList, setSalesDataList] = useState([]);
  const [ProductCodeList, setProductCodeList] = useState([]);
  const [OrderCountList, setOrderCountList] = useState([]);
  const [ProductPriceList, setProductPriceList] = useState([]);
  const [PaymentStatusList, setPaymentStatusList] = useState([]);
  const [PaymentMethodList, setPaymentMethodList] = useState([]);
  const [OrderValueList, setOrderValueList] = useState([]);
  const [PaymentMethodOrderValueList, setPaymentMethodOrderValueList] = useState([]);
  const [DeliveryDateData, setDeliveryDateData] = useState('');
  const selectedPincode = useRef();
  const [switchLabel, setSwitchLabel] = useState('Switch to MTD');

  const [DeliveryStatusData, setDeliveryStatusData] = useState([]);

  // const { isStockOpen, onStockOpen, onStockClose } = useDisclosure()

  //stock
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef();

  const userInfo = store.getState();

  //delivery status
  const { isOpen: isDeliveryPopupOpen, onOpen: onDeliveryPopupAddOpen, onClose: onDeliveryPopupAddClose } = useDisclosure()
  const DeliverybtnRef = React.useRef()

  useEffect(() => {
    GetDeliveryStatusDashboardData();
    LoadRemainingStock();
    getBarChartData(false);
    getLineChartData(false);
    getPieChartData(false);
    getDoughnutChartData(false);
  }, []);

  const GetDeliveryStatusDashboardData = () => {
    //debugger;
    DeliveryStatusService.GetDashboardDeliveryStatusReportData()
      .then((data) => {
        if (data.data.IsSuccess) {
          setDeliveryStatusData(data.data.Data);
        } else {
          setDeliveryStatusData([]);
        }
      })
      .catch((error) => {
        setDeliveryStatusData([]);
      });
  };

  const GetDelieveryStatusReportByStatusandDays = (status, days) => {
    debugger;
    window.open(CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=${status}&daydifference=${days}`, "_blank");
  }

  const LoadRemainingStock = () => {
    //debugger;
    StockService.GetRemainingStockCount()
      .then((data) => {
        if (data.data.IsSuccess) {
          setRemainStockList(data.data.Data);
        } else {
          setRemainStockList([]);
        }
      })
      .catch((error) => {
        setRemainStockList([]);
      });
  };

  const getBarChartData = (isMtd) => {
    debugger;
    StockService.GetDayWiseOrderValueForDashBoardTrend(isMtd).then((data) => {
      //console.log(data);
      if (data.data.IsSuccess) {
        debugger;
        setDateList(data.data.Data.Dates);
        setCCDataList(data.data.Data.CCData);
        setWebDataList(data.data.Data.WebData);
        setMobDataList(data.data.Data.MobData);
        setSalesDataList(data.data.Data.SalesData);
      }
    });
  };

  const getLineChartData = (isMtd) => {
    StockService.GetProductOrderCountForDashBoardTrend(isMtd).then((data) => {
      //console.log(data);
      if (data.data.IsSuccess) {
        setProductCodeList(data.data.Data.ProductCodeList);
        setOrderCountList(data.data.Data.OrderCountList);
        setProductPriceList(data.data.Data.ProductPriceList);
      }
    });
  };

  const getPieChartData = (isMtd) => {
    StockService.GetOrderStatusForDashBoardTrend(isMtd).then((data) => {
      //console.log(data);
      if (data.data.IsSuccess) {
        setPaymentStatusList(data.data.Data.PaymentStatusList);
        setOrderValueList(data.data.Data.OrderValueList);
      }
    });
  };

  const getDoughnutChartData = (isMtd) => {
    StockService.GetPaymentMethodStatusForDashBoardTrend(isMtd).then((data) => {
      //console.log(data);
      if (data.data.IsSuccess) {
        setPaymentMethodList(data.data.Data.PaymentMethodList);
        setPaymentMethodOrderValueList(data.data.Data.OrderValueList);
      }
    });
  };

  const chartData = {
    labels: DateList,
    datasets: [
      {
        label: "CC",
        data: CCDataList,
        backgroundColor: "rgba(255, 205, 86, 0.2)",
        borderColor: "rgb(255, 205, 86)",
        borderWidth: 1,
      },
      {
        label: "Website",
        data: WebDataList,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
      },
      {
        label: "Mobile",
        data: MobDataList,
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgb(153, 102, 255)",
        borderWidth: 1,
      },
      // {
      //   label: "Sales",
      //   data: SalesDataList,
      //   backgroundColor: "rgba(153, 102, 255, 0.2)",
      //   borderColor: "rgb(153, 102, 255)",
      //   borderWidth: 1,
      // },
    ],
  };

  const lineChartdata1 = {
    labels: ProductCodeList,
    datasets: [
      {
        label: "Orders Count",
        data: OrderCountList,
        borderColor: "rgb(153, 102, 255)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderWidth: 1,
      },
    ],
  };

  const lineChartdata2 = {
    labels: ProductCodeList,
    datasets: [
      {
        label: "Product Price",
        data: ProductPriceList,
        borderColor: "rgba(226, 0, 255,1)",
        backgroundColor: "rgba(226, 0, 255, 0.2)",
      },
    ],
  };

  const pieChartData = {
    labels: PaymentStatusList,
    datasets: [
      {
        label: "Value",
        data: OrderValueList,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(75, 192, 192)",
          "rgb(255, 205, 86)",
          "rgb(201, 203, 207)",
          "rgb(200, 128, 255)"
        ],
        hoverOffset: 4,
      },
    ],
  };

  const doughnutChartData = {
    labels: PaymentMethodList,
    datasets: [
      {
        label: "Value",
        data: PaymentMethodOrderValueList,
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(255, 128, 0)",
          "rgb(255, 255, 0)",
          "rgb(200, 187, 63)",
          "rgb(0, 200, 100)",
          "rgb(50, 255, 128)",
          "rgb(0, 255, 255)",
          "rgb(0, 0, 0)",
          "rgb(0, 0, 255)",
          "rgb(128, 0, 200)",
          "rgb(255, 0, 255)",
          "rgb(255, 0, 128)",
          "rgb(128, 128, 128)",
          "rgb(161, 98, 21)",

        ],
        hoverOffset: 4,
      },
    ],
  };

  const h2Style = {
    fontSize: '20px',
    fontWeight: '500',
    color: '#cb3c3c',
    marginBottom: '16px',
  }


  const SearchPincode = () => {
    if (selectedPincode.current.value != '') {
      PincodeService.GetPincodeList(selectedPincode.current.value).then(data => {
        if (data.data.IsSuccess) {
          debugger;
          setDeliveryDateData(data.data.Data[0].Delivery_TAT  +"|"+ data.data.Data[0].XpressBees_TAT);
        } else {
          toast.error('Invalid Pincode');
          setDeliveryDateData('');
        }
      }).catch(error => {
        toast.error("Somthing went wrong.");
      })
    }
    else {
      toast.error('Please enter pincode first.')
    }
  }

  const handleDurationChange = (e) => {
    debugger;
    let isChecked = e.target.checked;
    setSwitchLabel(isChecked ? 'Switch to Last 30 Days' : 'Switch to MTD');
    if (isChecked) {
      getBarChartData(isChecked);
      getLineChartData(isChecked);
      getPieChartData(isChecked);
      getDoughnutChartData(isChecked);
    }
    else {
      getBarChartData(isChecked);
      getLineChartData(isChecked);
      getPieChartData(isChecked);
      getDoughnutChartData(isChecked);
    }
  };

  return (
    <>
      {userInfo.userData.Role_Name == "Admin" || userInfo.userData.Role_Name == "Manager"?
        <>
          <HStack float="right">
            <Button ref={btnRef} colorScheme='teal' onClick={onOpen}>
              Show Stock Details
            </Button> &nbsp;
            <Button ref={DeliverybtnRef} colorScheme='teal' onClick={onDeliveryPopupAddOpen}>
              Show Delivery Details
            </Button>
          </HStack>
          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
            size={'xl'}
            isFullHeight={false}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Stock Details</DrawerHeader>
              <DrawerBody>

                <Table
                  variant="striped"
                  size="sm">
                  <Thead>
                    <Tr>
                      <Th
                        textAlign="center"
                        colSpan={5}>
                        <b>Remaining Stock Count</b>
                      </Th>
                    </Tr>
                  </Thead>
                  <Thead>
                    <Tr>
                      <Th>Product Name</Th>
                      <Th w="10%">Mumbai Count</Th>
                      <Th w="10%">Bangalore Count</Th>
                      <Th>Mumbai Stock Code</Th>
                      <Th>Bangalore Stock Code</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {RemainStockList.map((data, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{data.Product_Name}</Td>
                          <Td>
                            {parseInt(data.MUM_Product_Count) >= 51 ? (
                              data.MUM_Product_Count
                            ) : (
                              <span className="Free"> {data.MUM_Product_Count}</span>
                            )}
                          </Td>
                          <Td>
                            {parseInt(data.BANG_Product_Count) >= 51 ? (
                              data.BANG_Product_Count
                            ) : (
                              <span className="Free"> {data.BANG_Product_Count}</span>
                            )}
                          </Td>
                          <Td>{data.MUM_StockCode}</Td>
                          <Td>{data.BANG_StockCode}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>

              </DrawerBody>
            </DrawerContent>
          </Drawer>

          <Drawer
            isOpen={isDeliveryPopupOpen}
            placement='right'
            onClose={onDeliveryPopupAddClose}
            finalFocusRef={DeliverybtnRef}
            size={'xl'}
            isFullHeight={false}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Delivery Status Details</DrawerHeader>
              <DrawerBody>
                <Table
                  variant="striped"
                  size="sm">
                  <Thead>
                    <Tr>
                      <Th
                        textAlign="center"
                        colSpan={6}>
                        <b>Delivery Status Data</b>
                      </Th>
                      <Th
                        textAlign="center"
                        colSpan={1}>
                        <Link href={CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=&daydifference=`} isExternal>
                          <Button colorScheme="red" variant="solid" size={"xs"}>Download Report</Button>
                        </Link>

                      </Th>
                    </Tr>
                  </Thead>
                  <Thead>
                    <Tr>
                      <Th>Delivery Status</Th>
                      <Th>Day 1</Th>
                      <Th>Day 2</Th>
                      <Th>Day 3</Th>
                      <Th>Day 4</Th>
                      <Th>Day 5</Th>
                      <Th>Greater Day 5</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {DeliveryStatusData.map((data, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{data.Delivery_Status}</Td>
                          {/* <Td>
                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => GetDelieveryStatusReportByStatusandDays(data.Delivery_Status,'=1')}>
                            {data.Day_1}
                        </Button>
                        </Td> */}

                          <Td>
                            <Link href={CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=${data.Delivery_Status}&daydifference=${1}`} isExternal>
                              {data.Day_1}
                            </Link>
                          </Td>
                          <Td>
                            <Link href={CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=${data.Delivery_Status}&daydifference=${2}`} isExternal>
                              {data.Day_2}
                            </Link>
                          </Td>
                          <Td><Link href={CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=${data.Delivery_Status}&daydifference=${3}`} isExternal>
                            {data.Day_3}
                          </Link>
                          </Td>
                          <Td><Link href={CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=${data.Delivery_Status}&daydifference=${4}`} isExternal>
                            {data.Day_4}
                          </Link> </Td>
                          <Td><Link href={CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=${data.Delivery_Status}&daydifference=${5}`} isExternal>
                            {data.Day_5}
                          </Link> </Td>
                          <Td><Link href={CommonVariables.API_DOMAIN + `Dashboard/GetDelieveryStatusReportByStatusandDays?status=${data.Delivery_Status}&daydifference=${6}`} isExternal>
                            {data.Greaterthan_5}
                          </Link></Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>

              </DrawerBody>
            </DrawerContent>
          </Drawer>

          <br />
          <br />
          <HStack justifyContent="space-between" alignItems="center">
            <VStack align="flex-start" h="full" ><h2 style={h2Style}>Month Wise Order Value Summary</h2></VStack>
            <Spacer/>
            <VStack  style={{ marginBottom: "16px", display: "inline-block" }}> &nbsp; <span style={{ marginRight: "10px" }}>30 Days</span> <Switch style={{ marginTop: "-1px" }}
              defaultChecked={false} onChange={handleDurationChange}></Switch> &nbsp; <span style={{ marginLeft: "10px" }}>MTD</span></VStack>
          </HStack>


          <BarChartComponent data={chartData} ></BarChartComponent>
          <br />
          <h2 style={h2Style}>Monthly Product Status Summary</h2>
          <HStack>
            <BarChartComponent data={lineChartdata1}></BarChartComponent>
            {/* <LineChartComponent data={lineChartdata2}></LineChartComponent> */}
          </HStack>
          <br />
          <h2 style={h2Style}>Monthly Order Status and Payment Method Summary</h2>
          <HStack>
            <PieChartComponent data={pieChartData}></PieChartComponent>
            <DoughnutChartComponent data={doughnutChartData}></DoughnutChartComponent>
          </HStack>
        </> : <> Welcome To Dashboard</>}
    </>

  );
}

export default DashboardComponent;
