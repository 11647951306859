 //export const API_DOMAIN = "http://localhost:39933/api/";
export const API_DOMAIN = "https://productadminapi.hicare.in/product/api/";
// export const API_DOMAIN = "https://connect.hicare.in/product_uat/api/";   //UAT

export const API_HEADER = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
};

export const FILE_UPLOAD_API_HEADER = {
    headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    }
};

export const Discount_Type = [
    { Key: "Percentage", Value: "Percentage" },
    { Key: "Cash", Value: "Cash" }
];
export const Campaign_Type = [
    { Key: "Order Value", Value: "Order Value" },
    { Key: "Product", Value: "Product" }
];

export const Complaint_Action = [
    { Key: "Active", Value: "Active" },
    { Key: "In-Progress", Value: "In-Progress" },
    { Key: "Closed", Value: "Closed" },
    { Key: "Resolved", Value: "Resolved" }
];
export const Complaint_Category_Type = [
    { Key: "Delayed In Delivery", Value: "Delayed In Delivery" },
    { Key: "Machine Not Working", Value: "Machine Not Working" },
    { Key: "Effectiveness Issue", Value: "Effectiveness Issue" },
    { Key: "Missing Quantity", Value: "Missing Quantity" },
    { Key: "Marked Delivered But Not Delivered", Value: "Marked Delivered But Not Delivered" },
    { Key: "Damaged Product Recieved", Value: "Damaged Product Recieved" },
    { Key: "Nozzle Missing", Value: "Nozzle Missing" },
    { Key: "Duplicate Active Complaint", Value: "Duplicate Active Complaint" },
    { Key: "Other Complaint", Value: "Other Complaint" }
];

export const OrderDeliveryStatus = [
    { Key: "Booked", Value: "Booked" },
    { Key: "Draft", Value: "Draft" },
    { Key: "Packed", Value: "Packed" },
    { Key: "Invoice Prepared", Value: "Invoice Prepared" },
    { Key: "Shipped", Value: "Shipped" },
    { Key: "Delivered", Value: "Delivered" },
    { Key: "Cancelled", Value: "Cancelled" },
    { Key: "Return To Office", Value: "Return To Office" },
    { Key: "Delayed", Value: "Delayed" }
];
export const CCorderStatus = [
    { Key: "Booked", Value: "Booked" },
    { Key: "Draft", Value: "Draft" },
    { Key: "Cancelled", Value: "Cancelled" }
    
];

export const DeliveryPartner = [
    { Key: "DTDC Courier Pvt.Ltd", Value: "DTDC" },
    { Key: "Xpressbees", Value: "xpressBees" },
    { Key: "Delivered By HiCare/HE", Value: "Delivered By HiCare/HE" },
    { Key: "Shree Tirupati", Value: "Shree Tirupati" }
    // { Key: "Trackon Courier Pvt.Ltd", Value: "Trackon Courier" },
    // { Key: "Professional Courier", Value: "Professional Courier" }
];

export const PaymentMode = [
    { Key: "Card", Value: "card" },
    { Key: "Cash", Value: "cash" },
    { Key: "Cheque", Value: "cheque" },
    { Key: "Net Banking", Value: "netbanking" },
    { Key: "NEFT", Value: "neft" },
    { Key: "Other", Value: "other" },
    { Key: "UPI", Value: "upi" },
    { Key: "Wallet", Value: "wallet" }
];

export const UpdatePayStatus = [
    { Key: "Capture", Value: "captured" },
    { Key: "Cancel", Value: "cancelled" }
];

export const VoucherList = [
    { Key: "0% OFF", Value: "0% OFF" },
    // { Key: "25% OFF", Value: "25% OFF" },
    // { Key: "50% OFF", Value: "50% OFF" },
    { Key: "100% OFF", Value: "100% OFF" },
    // { Key: "99% OFF", Value: "99% OFF" }

];

export const AddressType = [
    { Key: "Home", Value: "Home" },
    { Key: "Office", Value: "Office" },
    { Key: "Other", Value: "Other" }
];

export const ProductConfType = [
    { Key: "Yes", Value: "Yes" },
    { Key: "No", Value: "No" }
];

export const RegionName = [
    { Key: "East", Value: "East" },
    { Key: "North 1", Value: "North 1" },
    { Key: "North 2", Value: "North 2" },
    { Key: "SC Test", Value: "SC Test" },
    { Key: "South 1", Value: "South 1" },
    { Key: "South 2", Value: "South 2" },
    { Key: "South 3", Value: "South 3" },
    { Key: "West 1", Value: "West 1" },
    { Key: "West 2", Value: "West 2" },
    { Key: "West 3", Value: "West 3" }
];

export const NoOfBoxList = [
    { Key: "1", Value: 1 }, { Key: "2", Value: 2 }, { Key: "3", Value: 3 }, { Key: "4", Value: 4 }, { Key: "5", Value: 5 },
    { Key: "6", Value: 6 }, { Key: "7", Value: 7 }, { Key: "8", Value: 8 }, { Key: "9", Value: 9 }, { Key: "10", Value: 10 }
];
