import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as LoginService from '../../Services/Login/LoginService'
import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
    HStack,
} from '@chakra-ui/react';
import { thunk_set_user } from '../../Action/action'
import useLoaderState from '../../Common/useLoaderState';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { useLocation,useParams,useNavigate } from 'react-router-dom';

function GAuthComponent(props) {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    const location = useLocation();
    const initialized = useRef(true)
    const history = useNavigate();

    useEffect(() => {
        if (initialized.current) {
            initialized.current = false
            GetOnLoadValidate();
        }
    }, []);

    const GetOnLoadValidate = () => {
        var code ="";
        debugger;
        if(window.location.search.includes('code')){
            debugger;
            var query = window.location.search.substring('code');
            var vars = query.split("=");
            code=vars[1];
        }
        debugger;
        if(code != null && code != '' && code != undefined){
            LoginService.GetOnLoadValidate(code).then(data => {
                if (data.data.IsSuccess) {
                    props.dispatch(thunk_set_user(data.data.Data));
                    setTimeout(() => {
                        history('/dashboard');
                    }, 1000);                 
                }
                else{
                    toast.error("You are not authorized user, contact admin!");
                    setTimeout(() => {
                    history('/');
                }, 1000);                 

                }
            }).catch(error => {
                toast.error("Something went wrong");
            })

        }
    }
  
    const LoadLogin = () => {
            history('/');    
    }


    return (
        <Stack
            bg={'gray.50'}
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: 'lg' }}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Stack spacing={4}>
                <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }}>
                    Product Admin Authentication
                    <Text
                        as={'span'}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        bgClip="text">
                        !
                    </Text>
                </Heading>
                <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                   Google Authentication for Product Admin
                </Text>
                <p className="blink"> Please wait, validating your credentials.</p>

                {/* <Button fontFamily={'heading'} mt={4} w={"100%"} bgGradient="linear(to-r, red.400,pink.400)" color={'white'}
                                _hover={{bgGradient: 'linear(to-r, red.400,pink.400)',boxShadow: 'xl',}} 
                                onClick={() => LoadLogin()}>
                                Login with Credentials
                            </Button> */}
            </Stack>
        </Stack>
    )
}

const matchStateToPros = state => {
    return {
        data: state
    }
}
export default connect(matchStateToPros)(GAuthComponent);
