import React from 'react'
// import PincodeComponent from '../Components/Pincode/PincodeComponent'
import PincodeComponentPage from '../Components/Pincode/PincodeListComponent'

function PincodePage() {
  return (
    // <PincodeComponent></PincodeComponent>
    <PincodeComponentPage/>
  )
}

export default PincodePage