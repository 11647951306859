import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetProductTestimonialList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductTestimonial/GetProductTestimonialList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductTestimonialById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductTestimonial/GetProductTestimonialById?productTestimonialId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveProductTestimonial = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductTestimonial/SaveProductTestimonial', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateProductTestimonial = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductTestimonial/UpdateProductTestimonial', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
export const DeleteProductTestimonial = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductTestimonial/DeleteProductTestimonial?productTestimonialId=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ApproveProductTestimonial = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductTestimonial/ApprovedProductTestimonial?testimonialId=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetProductTestimonialByProductId = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductTestimonial/GetProductTestimonialByProductId_Admin?productId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}