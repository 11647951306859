import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetCategoryList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductCategory/GetProductCategoryList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetCategoryById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductCategory/GetProductCategoryById?productCategoryId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveCategoryDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductCategory/SaveProductCategory', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateCategoryDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductCategory/UpdateProductCategory', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActiveDeActiveCategoryDetail = async (Id,IsActive) => {
    debugger;
    const response = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductCategory/ActivateDeactivateProductCategoryDetail?productCategoryId=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const SaveCategory = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductCategory/SaveProductCategory`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdateCategory = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductCategory/UpdateProductCategory`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}