import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, HStack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider, Textarea } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as ProductConfigService from '../../Services/Product/ProductConfigService'
import * as AttachmentService from '../../Services/Attachment/AttachmentService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import FilePicker from 'chakra-ui-file-picker';
import { getValue } from '@testing-library/user-event/dist/utils';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';

function ProductSpecificationComponent(props) {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { handleSubmit: handleProductSpecSubmit, register: registerProductSpec, setValue: setValueProductSpec, reset: resetProductSpec, formState: { errors: errorProductSpec } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isPinCodeOpen, onOpen: onPinCodeOpen, onClose: onPinCodeClose } = useDisclosure()
    const [ProductSpecificationList, setProductSpecification] = useState([]);
    const [IsLoading] = useLoaderState();
    const [SelectedProductSpecification, setProductSpecificationId] = useState(0);
    const [Product_Id, SetProductId] = useState(0);

    useEffect(() => {
        SetProductId(props.Product_Id);
        setValueProductSpec('Id', 0)
        if (props.Product_Id > 0) {
            GetProductSpecByProductId()
        }
    }, [])


    const GetProductSpecByProductId = () => {

        SetProductId(props.Product_Id);
        ProductConfigService.GetProductSpecificationById(props.Product_Id).then(data => {
            if (data.data.IsSuccess) {
                console.log(data.data.Data)
                setProductSpecification(data.data.Data);
            }
            else {
                setValueProductSpec('Id', 0)
            }
        }).catch(error => {
            setValueProductSpec('Id', 0)
        });
    }


    const ActivateDeactivateProductSpec = (id, isActive) => {
        debugger;
        SetProductId(props.Product_Id);
        ProductConfigService.ActivateDeactivateProductSpec(id, !isActive).then(data => {
            if (data.data.IsSuccess) {
                toast.success(data.data.ResponseMessage)
                GetProductSpecByProductId(props.Product_Id);
            }
            else {
                setValueProductSpec('Id', 0)
            }
        }).catch(error => {
            setValueProductSpec('Id', 0)
        });
    }

    const SaveProductSpec = (value) => {
        debugger;
        value.Product_Id = props.Product_Id;
        ProductConfigService.SaveProductSpecification(value).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Specification Save");
                resetProductSpec();
                setValueProductSpec('Id', 0)
                GetProductSpecByProductId(props.Product_Id);
            }
            else {
                toast.error("Something went wrong")
            }
        }).catch(error => {

        });

    }


    return (

        <Stack spacing={4}>
        <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Box as="form">
                <Input hidden  {...registerProductSpec("Id", { setValueAs: v => parseInt(v) })} />

                <HStack spacing={"2"} paddingTop={"5"}>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Specification_Key}>
                            <FormLabel>Specification_Key</FormLabel>
                            <Input placeholder='Specification_Key' {...registerProductSpec("Specification_Key", { required: "Specification_Key is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.Specification_Key && errors.Specification_Key.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Specification_Value}>
                            <FormLabel>Specification Value</FormLabel>
                            <Input placeholder='Specification_Value' {...registerProductSpec("Specification_Value", { required: "Specification_Value is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.Specification_Value && errors.Specification_Value.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Sequence_No}>
                            <FormLabel>Sequence_No</FormLabel>
                            <Input placeholder='Sequence No' {...registerProductSpec("Sequence_No", { required: "Sequence No is Mandatory", setValueAs: v => parseInt(v) })} />
                            <FormErrorMessage>
                                {errors.Sequence_No && errors.Sequence_No.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack>
                <Checkbox hidden  {...registerProductSpec("Is_Active")} > </Checkbox >

                <HStack spacing={"2"} paddingTop={"5"} float="right">
                    <ButtonGroup variant='outline' spacing='4' >
                        <Button disabled={IsLoading} type='submit' onClick={handleProductSpecSubmit(SaveProductSpec)} colorScheme="whatsapp">Save</Button>
                    </ButtonGroup>
                </HStack>

            </Box>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Product Name</Th>
                            <Th>Specification Key</Th>
                            <Th>Specification Value</Th>
                            <Th>Sequence No</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ProductSpecificationList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Product_Name}</Td>
                                <Td>{data.Specification_Key}</Td>
                                <Td>{data.Specification_Value}</Td>
                                <Td>{data.Sequence_No}</Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Button disabled={IsLoading} colorScheme="blue" variant="solid" size={"xs"} onClick={() => ActivateDeactivateProductSpec(data.Id, data.Is_Active)}>
                                            <DeleteIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {ProductSpecificationList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="12">
                                <Td colSpan="12">
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
        </Stack >

    )
}

export default ProductSpecificationComponent