import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetProductList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Product/GetProductList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductById = async (id) => {
    //debugger;
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Product/GetProductById?productId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveProduct = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Product/SaveProduct', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateProduct = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Product/UpdateProduct', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactivateProduct = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Product/ActivateDeactivateProduct?id=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


export const GetRemainingStockCount = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Stock/GetRemainingStockCount`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetDayWiseOrderValueForDashBoardTrend = async (isMtd) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `DashBoard/GetDayWiseOrderValueForDashBoardTrend?IsMtd=${isMtd}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductOrderCountForDashBoardTrend = async (isMtd) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `DashBoard/GetProductOrderCountForDashBoardTrend?IsMtd=${isMtd}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetOrderStatusForDashBoardTrend = async (isMtd) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `DashBoard/GetOrderStatusForDashBoardTrend?IsMtd=${isMtd}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetPaymentMethodStatusForDashBoardTrend = async (isMtd) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `DashBoard/GetPaymentMethodStatusForDashBoardTrend?IsMtd=${isMtd}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}