import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as OrderListService from '../../Services/OrderList/OrderListService'
import * as CCOrderService from '../../Services/CCOrder/CCOrderService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Link, FormControl, Divider, Select, FormLabel, HStack, FormErrorMessage, Input, Textarea, useDisclosure, Stack, Table, ButtonGroup, Tfoot, Box, TableContainer, Button, Thead, Tbody, Td, Tr, Th, Grid, GridItem, VStack, Flex, Spacer } from '@chakra-ui/react'
import { EditIcon, ViewIcon, ArrowForwardIcon, HamburgerIcon, ExternalLinkIcon, BellIcon, StarIcon, DownloadIcon, Search2Icon ,TimeIcon} from '@chakra-ui/icons'
import { FaTruck } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import * as CommonVariables from '../../Common/CommonVariables'
import { getValue } from '@testing-library/user-event/dist/utils';
import * as moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { Tooltip } from '@chakra-ui/react'
import * as AttachmentService from '../../Services/Attachment/AttachmentService'
import FilePicker from 'chakra-ui-file-picker';
import useLoaderState from '../../Common/useLoaderState';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import { store } from "../../Store/store";
import { useParams } from 'react-router-dom';

function OrderListComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { register: stockregister, getValues: stockgetValue, setValue: stocksetValue, reset: stockreset, setError: stocksetError, handleSubmit: stockhandleSubmit, formState: { errors: stockerrors } } = useForm({ mode: "onBlur" });
    const { register: payregister, getValues: paygetValue, setValue: paysetValue, reset: payreset, setError: paysetError, handleSubmit: payhandleSubmit, formState: { errors: payerrors } } = useForm({ mode: "onBlur" });
    const { isOpen: isUpdatePayStatusOpen, onOpen: onUpdatePayStatusOpen, onClose: onUpdatePayStatusClose } = useDisclosure();
    const { register: addressregister, getValues: addressgetValue, setValue: addresssetValue, reset: addressreset, setError: addressError, handleSubmit: addresshandleSubmit, formState: { errors: addresserrors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddAddressOpen, onOpen: onAddAddressOpen, onClose: onAddAddressClose } = useDisclosure();
    const { isOpen: isAddUploadProductOrderOpen, onOpen: onAddUploadProductOrderOpen, onClose: onAddUploadProductOrderClose } = useDisclosure();
    const userInfo = store.getState();
    const [OrderList, setOrderList] = useState([]);
    const [OrderItemList, setOrderItemList] = useState([]);
    const [PayOrderItemList, setPayOrderItemList] = useState([]);
    const [OrderStockList, setOrderStockList] = useState([]);
    const [OrderId, setCurrentOrderId] = useState(0);
    const [StockOrderId, setStockOrderId] = useState(0);
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const { isOpen: isAddStockOpen, onOpen: onAddStockOpen, onClose: onAddStockClose } = useDisclosure();
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const orderStatus = useRef();
    const orderNumber = useRef();
    const [PayCurrentOrderId, setPayCurrentOrderId] = useState(0);
    const [PaymentLinkRequestForm, setPaymentLinkRequestForm] = useState({});//Payment
    const [Invoicedate, setInvoiceDate] = useState(new Date());
    const [RazorPayPaymentModel, setRazorPayPaymentModel] = useState({});
    const [InvoiceFile, setInvoiceFile] = useState('');
    const [REOrderUploaderFileURL, SetREOrderUploaderFileURL] = useState('');
    const [ShowInvoiceFile, setShowInvoiceFile] = useState(false);
    const [IsLoading] = useLoaderState();

    const [SelectedFile, setSelectedFile] = useState([]);
    const [SelectedInvoiceFile, setSelectedInvoiceUploaderFile] = useState([]);
    const [SelectedDeliveryFile, setSelectedDeliveryUploaderFile] = useState([]);

    const [ShowInvoiceUploader, setShowInvoiceUploader] = useState(false);
    const [ShowDeliveryUploader, setShowDeliveryUploader] = useState(false);
    
    const [Expected_DeliveryPartner, setExpected_DeliveryPartner] = useState('');
    const { date } = useParams();

    useEffect(() => {
        LoadOrderList();
    }, [])


    const LoadOrderList = () => {
        if(date != null && date != '' && date != undefined){
            setStartDate(new Date(date));
            setEndDate(new Date(date));
            OrderListService.GetFilteredOrderList('', '', moment(date).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD")).then(data => {
                if (data.data.IsSuccess) {
                    setOrderList(data.data.Data);
                } else {
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error("Something went wrong");
            })

        }
        else{
            OrderListService.GetFilteredOrderList('', '', moment(StartDate).format("YYYY-MM-DD"), moment(EndDate).format("YYYY-MM-DD")).then(data => {
                if (data.data.IsSuccess) {
                    setOrderList(data.data.Data);
                } else {
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error("Something went wrong");
            })

        }
        
    }


    const ViewOrderItemList = (id) => {
        setCurrentOrderId(id);
        onAddOpen();
        OrderListService.GetOrderItemListByOrderId(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data.OrderSummary)
                setOrderItemList(data.data.Data.OrderItemList)
                onAddOpen();
            }
            else {
                reset([])
                setOrderItemList([])
                onAddOpen();
            }
        }).catch(error => {

        })
    }

    const getsubscriptionrowcolor = (data) => {
        if (data > 0)
            return '#ab2f2f78'
        else
            return ''
    }

    const ViewOrderDetailsforAddressEdit = (id) => {
        setCurrentOrderId(id);
        onAddAddressOpen();
        OrderListService.GetOrderDetailsforAddressEdit(id).then(data => {
            if (data.data.IsSuccess) {
                addresssetValue('OrderId', id);
                addressreset(data.data.Data)
                onAddAddressOpen();
            }
            else {
                toast.error("Something went wrong.");
            }
        }).catch(error => {
            toast.error("Something went wrong.")
        })
    }

    const ViewOrderStockDetail = (id,) => {
        stockreset([]);
        onAddStockOpen();
        setStockOrderId(id);
        OrderListService.ViewOrderStockDetail(id).then(data => {
            if (data.data.IsSuccess) {
                debugger;
                // stocksetValue('InvoiceFile')
                if (data.data.Data[0].InvoiceFile != null && data.data.Data[0].InvoiceFile != '') {
                    setShowInvoiceFile(true);
                    setInvoiceFile(data.data.Data[0].InvoiceFile);
                }

                stocksetValue('Status_Reason', data.data.Data[0].Status_Reason)
                stocksetValue('Order_Number', data.data.Data[0].OrderNumber)
                stocksetValue('CustomerDetail', data.data.Data[0].CustomerDetail)
                stocksetValue('Tracking_URL', data.data.Data[0].Tracking_URL)
                stocksetValue('AWB_No', data.data.Data[0].AWB_No)
                stocksetValue('Delivery_Partner', data.data.Data[0].Delivery_Partner)
                stocksetValue('Invoice_No', data.data.Data[0].Invoice_No)
                onDeliveryPartnerChange(data.data.Data[0].Delivery_Partner, data.data.Data[0].Tracking_URL);
                stocksetValue('No_Of_Box', data.data.Data[0].No_Of_Box);
                clickHandler(data.data.Data[0].Invoice_Date);
                setExpected_DeliveryPartner(data.data.Data[0].Expected_Delivery_Partner);
                setOrderStockList(data.data.Data)
                onAddStockOpen();
            }
            else {
                reset()
                setOrderStockList([])
                onAddStockOpen();
            }
        }).catch(error => {

        })
    }

    function clickHandler(iDate) {
        if (iDate != '0001-01-01T00:00:00') {
            const invoiceDate = new Date(iDate);
            setInvoiceDate(invoiceDate);
        }
        else
            setInvoiceDate(new Date())
    }


    const SearchOrder = () => {
        //debugger;
        OrderListService.GetFilteredOrderList(orderNumber.current.value, orderStatus.current.value, moment(StartDate).format("YYYY-MM-DD"), moment(EndDate).format("YYYY-MM-DD")).then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setOrderList(data.data.Data);
            } else {
                //toast.error("Somthing went wrong");
                setOrderList([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const Download = () => {
        debugger;
        window.open(CommonVariables.API_DOMAIN + `ProductOrder/GetFilteredDownloadOrder?orderDate=${moment(StartDate).format("YYYY-MM-DD")}&endDate=${moment(EndDate).format("YYYY-MM-DD")}`, "_blank");

    }

    const Navigen_Download = () => {
        debugger;
        window.open(CommonVariables.API_DOMAIN + `ProductOrder/GetNavigenOrderReport?orderDate=${moment(StartDate).format("YYYY-MM-DD")}&endDate=${moment(EndDate).format("YYYY-MM-DD")}`, "_blank");

    }

    const UpdateOrderStockStatus = () => {
        debugger;

        const data = stockgetValue();
        data.OrderId = StockOrderId;
        data.InvoiceFile = InvoiceFile;
        data.InvoiceDate = moment(Invoicedate).format("YYYY-MM-DD");
        console.log(data);

        var status = stockgetValue('OrderDeliveryStatus');
        // var trackingURL = stockgetValue('Tracking_URL') ?? "";
        // var deliveryPartner = stockgetValue('Delivery_Partner') ?? "";
        // var awbNo = stockgetValue('AWB_No') ?? "";
        // var invoiceNo = stockgetValue('Invoice_No') ?? "";
        // var noOfBox = stockgetValue('No_Of_Box');
        // var statusreason = stockgetValue('Status_Reason');

        debugger;
        if (status == '') {
            alert('Select Order Status')
            return false;
        }

        if (status == 'Packed') {
            if (data.Invoice_No == null || data.Invoice_No == '') {
                alert('Invoice No and Invoice Date is Mandatory')
                return false;
            }
            if (data.No_Of_Box == null || data.No_Of_Box == '') {
                alert('No Of Box is Mandatory')
                return false;
            }
            if ((data.No_Of_Box != null || data.No_Of_Box != '') && data.No_Of_Box != 1 && data.No_Of_Box > OrderStockList.length) {
                alert('No of box cannot be greater than no of items and not more than 10')
                return false;
            }
        }
        if (status == 'Invoice Prepared' && (data.Invoice_No == null || data.Invoice_No == '')) {
            alert('Invoice No and Invoice Date is Mandatory')
            return false;
        }
        data.No_Of_Box = parseInt(data.No_Of_Box);

        if (status != "") {
            var action = window.confirm('Do you want to Update Order status as ' + status + ' ?');
            if (action == true) {
                OrderListService.ChangeOrderStatusByOrderId(data).then(data => {
                    if (data.data.IsSuccess) {
                        ViewOrderStockDetail(StockOrderId);
                        LoadOrderList();
                        stocksetValue('Tracking_URL', '');
                        toast.success("Updated Status Successfully")
                        onAddStockOpen();
                    }
                    else {
                        toast.error("Something Went Wrong!")
                        onAddStockOpen();
                    }
                }).catch(error => {
                    toast.error("Something Went Wrong!")
                })
            }
        }
    }

    const PrintOrderInvoice = (e) => {
        debugger;
        e.preventDefault();
        if (StockOrderId > 0 && InvoiceFile != '') {

            window.open(InvoiceFile, "_blank");

            // OrderListService.PrintOrderInvoice(parseInt(StockOrderId)).then(data => {
            //     if (data.data.IsSuccess) {
            //         console.log(data.data.Data)
            //         window.open(data.data.Data);
            //         toast.success("Order Invoice Generated!")
            //     }
            //     else {
            //         toast.error("Something Went Wrong!")
            //     }
            // }).catch(error => {
            //     toast.error("Something Went Wrong!")
            // })
        }
        else {
            toast.error("Invalid OrderId")
        }

    }

    const PrintOrderReceipt = (e) => {
        debugger;
        e.preventDefault();
        if (StockOrderId > 0) {
            OrderListService.PrintOrderReceipt(parseInt(StockOrderId)).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data.Data)
                    window.open(data.data.Data);
                    toast.success("Order Invoice Generated!")

                }
                else {
                    toast.error("Something Went Wrong!")
                }
            }).catch(error => {
                toast.error("Something Went Wrong!")
            })
        }
        else {
            toast.error("Invalid OrderId")
        }

    }

    const ViewPaymentDetail = (id) => {
        setPayCurrentOrderId(id)
        OrderListService.GetOrderItemListByOrderId(id).then(data => {
            if (data.data.IsSuccess) {
                payreset(data.data.Data.OrderSummary)
                setPayOrderItemList(data.data.Data.OrderItemList)
                onUpdatePayStatusOpen();
            }
            else {
                payreset([])
                setPayOrderItemList([])
                onUpdatePayStatusOpen();
            }
        }).catch(error => {

        })
    }


    const UpdatePaymentDetail = (value) => {
        debugger;

        const data = paygetValue();
        if (data.Payment_Mode != "cash") {
            if (data.Payment_Status == "captured" && data.Payment_Id == "") {
                toast.error("Payment / Transaction Id cannot be empty")
                return false;
            }
            if (data.Payment_Status == "captured" && data.Payment_Mode == "") {
                toast.error("Payment Mode cannot be empty")
                return false;
            }
        }

        data.OrderId = PayCurrentOrderId;
        var action = window.confirm('Do you want to Update Order status as ' + data.Payment_Status + ' ?');
        if (action == true) {
            CCOrderService.UpdatePaymentDetail(data).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Payment Updated Succesfully")
                    onUpdatePayStatusClose();
                    LoadOrderList();
                }
                else {
                    toast.error(data.data.ResponseMessage)
                    onUpdatePayStatusOpen();
                }
            }).catch(error => {

            })
        }
    }


    const UpdateAddressDetail = (value) => {
        debugger;
        console.log(value);
        var action = window.confirm('Do you want to Update Order Address ?');
        if (action == true) {
            OrderListService.UpdateCustomerAddressAdmin(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success(data.data.ResponseMessage)
                    onAddAddressClose();
                    LoadOrderList();
                }
                else {
                    toast.error("Something went wrong.")
                    onUpdatePayStatusOpen();
                }
            }).catch(error => {
                toast.error("Something went wrong.")
            })
        }
    }


    const SendPaymentLink = (e) => {
        e.preventDefault();
        debugger;

        const values = paygetValue();
        console.log(values);
        const PaymentLinkRequestModel = PaymentLinkRequestForm;
        PaymentLinkRequestModel.Amount = values.OrderValuePostDiscount;
        PaymentLinkRequestModel.ProductName = "HiCare Products";
        PaymentLinkRequestModel.CustomerName = values.Contact_Person_Name;
        PaymentLinkRequestModel.CustomerMobile = values.Contact_Person_Mobile;
        PaymentLinkRequestModel.CustomerEmail = values.Contact_Person_Email;
        PaymentLinkRequestModel.OrderId = PayCurrentOrderId;

        console.log(PaymentLinkRequestModel);
        CCOrderService.CancelAndCreatePaymentLinkAsync(PaymentLinkRequestModel).then(data => {
            if (data.data.IsSuccess && data.data.Data.id != null) {
                debugger;
                toast.success(data.data.ResponseMessage);
                UpdateRazorpayPaymentLinkId(data.data.Data.id, PayCurrentOrderId)
            }
            else if (data.data.IsSuccess && data.data.Data == "Success") {
                toast.success(data.data.ResponseMessage);
            }
            else {
                toast.error("Something went worng!")
            }
        }).catch(error => {
            debugger;
            toast.error("Something went worng!")
        })

    }

    const UpdateRazorpayPaymentLinkId = (paymentlinkId, orderid) => {
        debugger;
        const values = RazorPayPaymentModel;
        values.OrderId = orderid;
        values.Payment_LinkId = paymentlinkId;
        OrderListService.UpdateRazorpayPaymentLinkId(values).then(data => {
            if (data.data.IsSuccess) {

            }
            else {
                toast.error('Something went wrong')
            }
        }).catch(error => {

        })
    }

    const onDeliveryPartnerChange = (value, url) => {

        if (value == 'Trackon Courier')
            stocksetValue('Tracking_URL', 'https://trackon.in/')
        else if (value == 'Professional Courier')
            stocksetValue('Tracking_URL', 'https://trackcourier.io/professional-courier-tracking')
        else
            stocksetValue('Tracking_URL', '')
        if (url != "") {
            stocksetValue('Tracking_URL', url)
        }
    }

    const InvoiceFileUploadToS3 = (fileList) => {
        if (fileList != null && fileList.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', fileList[0])
            setShowInvoiceFile(false);
            setInvoiceFile("");
            AttachmentService.FileUploadToS3(formdata).then(response => {
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                    stockregister('Order_Invoice', response.data.Data)
                    setShowInvoiceFile(true);
                    setInvoiceFile(response.data.Data);
                    console.log(response.data.Data);
                }
                else {
                    stockregister('Order_Invoice', "")
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
            });
        }
        else {
            setShowInvoiceFile(false);
            setInvoiceFile("");
            toast.error('Please choose File First!')
        }
    }

    const OpenModalForOrderUploader = () => {
        onAddUploadProductOrderOpen();
    }

    const onProductUploaderFileChange = (fileList) => {
        setSelectedFile(fileList);
    }

    const onProductInvoivoiceUploaderFileChange = (fileList) => {
        setSelectedInvoiceUploaderFile(fileList);
    }

    const onProductDeliveryUploaderFileChange = (fileList) => {
        setSelectedDeliveryUploaderFile(fileList);
    }


    const DownloadOrderUploaderFile = () => {

        CCOrderService.DownloadOrderUploaderFileFormat().then(response => {
            if (response.data.IsSuccess) {
                window.open(response.data.Data)
            }
            else {

            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    const DownloadOrderInvoiceUploaderFile = () => {

        CCOrderService.DownloadOrderInvoiceUploaderFile().then(response => {
            if (response.data.IsSuccess) {
                window.open(response.data.Data)
                setShowInvoiceUploader(false);
                setShowDeliveryUploader(false);
            }
            else {
                setShowInvoiceUploader(false);
                setShowDeliveryUploader(false);
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }


    const UploadFile = () => {
        debugger;
        if (SelectedFile != null && SelectedFile.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', SelectedFile[0])

            CCOrderService.UploadProductOrder(formdata).then(response => {
                debugger;
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                    SetREOrderUploaderFileURL('');
                }
                else {
                    SetREOrderUploaderFileURL(response.data.Data);
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
            });
        }
        else {
            toast.error('Please select File first First!')
        }
    }

    const InvoiceUploadFile = () => {
        debugger;
        if (SelectedInvoiceFile != null && SelectedInvoiceFile.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', SelectedInvoiceFile[0])

            CCOrderService.UploadProductOrderInvoice(formdata).then(response => {
                debugger;
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                }
                else {
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
            });
        }
        else {
            toast.error('Please select File first First!')
        }
    }


    const DeliveryUploadFile = () => {
        debugger;
        if (SelectedDeliveryFile != null && SelectedDeliveryFile.length > 0) {
            var formdata = new FormData();
            formdata.append('Myfile', SelectedDeliveryFile[0])

            CCOrderService.UploadProductDelieveryUploader(formdata).then(response => {
                debugger;
                if (response.data.IsSuccess) {
                    toast.success(response.data.ResponseMessage);
                }
                else {
                    toast.error(response.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.message);
            });
        }
        else {
            toast.error('Please select File first First!')
        }
    }


    return (
        <ContainerComponent Title="Order List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="false" size="100" >

            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <HStack>
                <Box width={"250"} paddingTop={2} paddingLeft={5} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>Order Status</FormLabel>
                        <Select className="form-control" ref={orderStatus}>
                            <option value="">Select Order Status</option>
                            {
                                CommonVariables.OrderDeliveryStatus.map((data, index) => {
                                    return (
                                        <option value={data.Value} key={index}>{data.Key}</option>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingLeft={2} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>Start Date</FormLabel>

                        <ReactDatePicker
                            valueName="selected" // DateSelect value's name is selected
                            selected={StartDate}
                            onChange={(date) => setStartDate(date)}
                            name="ReactDatepicker"
                            className="text-center border-l-4 border-red-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                            placeholderText="Select start date"
                            dateFormat='dd-MM-yyyy'
                            popperPlacement=""
                            value={StartDate}
                        />
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>End Date</FormLabel>

                        <ReactDatePicker
                            valueName="selected" // DateSelect value's name is selected
                            selected={EndDate}
                            onChange={(date) => setEndDate(date)}
                            name="ReactDatepicker"
                            className="text-center border-l-4 border-red-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                            placeholderText="Select start date"
                            dateFormat='dd-MM-yyyy'
                            value={EndDate}
                        />
                    </FormControl>
                </Box>
                <Box width={"150"} paddingTop={2} paddingBottom={10}>
                    <FormControl >
                        <FormLabel>Search Order/Phone No</FormLabel>
                        <Input placeholder='Enter Order/Phone No' ref={orderNumber} />
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button colorScheme='red' onClick={() => SearchOrder()} marginTop={8}>
                        <Search2Icon />
                    </Button>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button colorScheme='green' onClick={() => Download()} marginTop={8}>
                        <DownloadIcon />
                    </Button>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button colorScheme='green' onClick={() => Navigen_Download()} marginTop={8}>
                        <ExternalLinkIcon />
                    </Button>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button colorScheme='red' onClick={() => OpenModalForOrderUploader()} marginTop={8}>
                        Uploader
                    </Button>
                </Box>
            </HStack>
            <TableContainer>

                <Spacer />
                <Table size="sm" variant='simple' >
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Role Name</Th>
                            <Th>Order No</Th>
                            <Th>Customer Name</Th>
                            <Th>City</Th>
                            <Th>Pincode</Th>
                            <Th>Payment</Th>
                            <Th>Date</Th>
                            <Th>AMNT</Th>
                            <Th>SC Name</Th>
                            <Th>O-Status</Th>
                            <Th>D-Status</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {OrderList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{(data.FailSync_Count > 4 && data.OrderStatus =='Packed') ? <>
                                    <Tooltip label={data.FailSync_ToDelivery_Reason} placement='top-start'>
                                        <BellIcon top={2} w={3} h={3} color='red.500' marginTop={-15} marginLeft={-4} />
                                    </Tooltip>
                                    {data.OrderId}</> : data.OrderId}
                                </Td>

                                <Td>{(data.DelayedDelivery > 0) ? <>
                                    <Tooltip label={data.DelayedDelivery_Text} placement='top-start'>
                                        <TimeIcon top={2} w={3} h={3} color='red.500' marginTop={-15} marginLeft={-5} />
                                    </Tooltip>
                                    &nbsp;{data.Role_Name}</> : data.Role_Name}
                                </Td>
                                <Td>{(data.Subscription_Id > 0) ? <>
                                    <Tooltip label='Subscription Order' placement='top-start'>
                                        <StarIcon top={2} w={3} h={3} Tooltip="Subscription Order" color='red.500' marginTop={-15} marginLeft={-5} />
                                    </Tooltip>
                                    &nbsp;{data.OrderNumber}</> : data.OrderNumber}

                                </Td>
                                <Td>{data.CustomerName}</Td>
                                <Td>{data.City}</Td>
                                {/* <Td>({data.Pincode_Delivery_Partner}) {data.Pincode}</Td> */}
                                <Td>{data.Pincode}</Td>
                                <Td>{(data.OrderStatus != "Draft" && data.OrderValuePostDiscount == 0) ? ' - ' : data.PaymentId}</Td>
                                <Td>{data.OrderDate_String}</Td>
                                <Td>{(data.OrderValuePostDiscount > 0) ? data.OrderValuePostDiscount : <span className='Free'>Free</span>}</Td>
                                <Td>{data.SC_Name}</Td>
                                <Td>{data.OrderStatus}</Td>
                                <Td>{data.Delivery_Status}</Td>
                                <Td>

                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Tooltip label='View Order Item' placement='auto-start'>
                                            <Button colorScheme='whatsapp' variant='solid' size={"xs"} onClick={() => ViewOrderItemList(data.OrderId)}>
                                                <ViewIcon />
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                    &nbsp;&nbsp;
                                    {data.OrderStatus == "Draft" ? (
                                        <ButtonGroup variant={"outline"} spacing="1">
                                            <Tooltip label='Edit Payment Details ' placement='auto-start'>
                                                <Button colorScheme='red' variant='solid' size={"xs"} onClick={() => ViewPaymentDetail(data.OrderId)}>
                                                    <ArrowForwardIcon />
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                    ) : (<></>)}
                                    &nbsp;&nbsp;
                                    {(userInfo.userData.Role_Name != "Admin" && data.OrderStatus != "Cancelled" && data.OrderStatus != "Draft" && data.OrderStatus != "Delivered") ? (
                                        <ButtonGroup variant={"outline"} spacing="1">
                                            <Tooltip label='Update Order Status' placement='auto-start'>
                                                <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => ViewOrderStockDetail(data.OrderId)}>
                                                    <EditIcon />
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                    ) : (<></>)}
                                    {(userInfo.userData.Role_Name == "Admin") ? (<ButtonGroup variant={"outline"} spacing="1">
                                        <Tooltip label='Update Order Status' placement='auto-start'>
                                            <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => ViewOrderStockDetail(data.OrderId)}>
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>) : (<></>)}
                                    &nbsp;&nbsp;
                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Tooltip label='Update Order Address' placement='auto-start'>
                                            <Button disabled={IsLoading} colorScheme='whatsapp' variant='solid' size={"xs"} onClick={() => ViewOrderDetailsforAddressEdit(data.OrderId)}>
                                                <HamburgerIcon />
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {OrderList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>

            <ModalComponent Title="Order Summary" scroll="inside" size={"6xl"} isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} >
                <Input hidden  {...register("OrderId")} />
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Customer Name</FormLabel>
                                <Input readOnly {...register("Contact_Person_Name")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Customer Mobile</FormLabel>
                                <Input readOnly {...register("Contact_Person_Mobile")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Customer Email</FormLabel>
                                <Input readOnly {...register("Contact_Person_Email")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>SFDC Order No</FormLabel>
                                <Input readOnly {...register("SFDC_OrderNo")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Order Value</FormLabel>
                                <Input readOnly {...register("OrderValue")} />

                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Discounted Value</FormLabel>
                                <Input readOnly {...register("OrderValuePostDiscount")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Customer Address</FormLabel>
                                <Textarea readOnly placeholder='Customer Address' {...register("CustomerAddress")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Bill To Address</FormLabel>
                                <Textarea readOnly {...register("BillToAddress")} />

                            </FormControl>
                        </Box>
                    </HStack>
                </Stack>
                <Divider />
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Sr.No</Th>
                                <Th>Product Name</Th>
                                <Th>Quantity</Th>
                                <Th>InstallationCharge</Th>
                                <Th>Price</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {OrderItemList.map((data, index) => {
                                return (<Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{data.Product_Name}</Td>
                                    <Td>{data.Quantity}</Td>
                                    <Td>{data.InstallationCharge}</Td>
                                    <Td>{data.Price}</Td>
                                </Tr>)
                            })}
                        </Tbody>
                        {OrderItemList.length <= 0 ? (
                            <Tfoot>
                                <Tr colSpan="3">
                                    <Td>
                                        No Data Available
                                    </Td>
                                </Tr>
                            </Tfoot>
                        ) : <></>
                        }

                    </Table>
                </TableContainer>
            </ModalComponent>

            <ModalComponent Title="Order Stock Summary" scroll="inside" size={"6xl"} isOpen={isAddStockOpen} onOpen={onAddStockOpen} onClose={onAddStockClose} >
                <Input hidden  {...register("OrderId")} />
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"50%"}>
                            <FormControl >
                                <FormLabel>Order Number</FormLabel>
                                <Input readOnly {...stockregister("Order_Number")} />
                            </FormControl>
                        </Box>
                        <Box width={"50%"}>
                            <FormControl >
                                <FormLabel>CustName_Number</FormLabel>
                                <Input readOnly {...stockregister("CustomerDetail")} />
                            </FormControl>
                        </Box>
                        {
                            (stockgetValue('OrderDeliveryStatus') != 'DTDC' && stockgetValue('AWB_No') != '') ?
                                <Box width={"20%"} paddingTop={9}>
                                    <Button type='button' onClick={(e) => PrintOrderReceipt(e)} colorScheme="whatsapp">Print Receipt</Button>
                                </Box> : <></>
                        }
                        {
                            ShowInvoiceFile ?
                                <Box width={"20%"} paddingTop={9}>
                                    <Button type='button' onClick={(e) => PrintOrderInvoice(e)} colorScheme="whatsapp">Print Invoice</Button>
                                </Box> : <></>
                        }
                    </HStack>
                    <HStack>

                        <Box width={"80%"}>
                            <FormControl >
                                <FormLabel>Invoice No</FormLabel>
                                <Input placeholder='Invoice No' {...stockregister("Invoice_No")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"} >
                            <FormControl>
                                <FormLabel>Invoice Date</FormLabel>
                                <ReactDatePicker
                                    valueName="selected" // DateSelect value's name is selected
                                    selected={Invoicedate}
                                    onChange={(date) => setInvoiceDate(date)}
                                    name="ReactDatepicker"
                                    className="text-center border-l-4 border-red-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                                    placeholderText="Select Invoice date"
                                    dateFormat='dd-MM-yyyy'
                                    value={Invoicedate}
                                />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Upload Order Invoice</FormLabel>
                                <FilePicker
                                    onFileChange={(fileList) => { InvoiceFileUploadToS3(fileList) }}
                                    placeholder="Upload Order Invoice"
                                    clearButtonLabel="Clear"
                                    multipleFiles={false}
                                    accept="application/png"
                                    hideClearButton={false}
                                />
                            </FormControl>
                        </Box>
                        {ShowInvoiceFile ?
                            <Link href={InvoiceFile} isExternal>
                                <ExternalLinkIcon mx='2px' />
                            </Link> : <></>
                        }
                    </HStack>
                    <HStack>
                        <Box width={"40%"}>
                            <FormControl >
                                <FormLabel>Delivery Partner</FormLabel>
                                <Select className="form-control" {...stockregister("Delivery_Partner")} onChange={(e) => onDeliveryPartnerChange(e.target.value)}>
                                    <option value="">Select Delivery Partner</option>
                                    {
                                        CommonVariables.DeliveryPartner.map((data, index) => {
                                            if (data.Value != 'Booked') {
                                                return (
                                                    <option value={data.Value} key={index}>{data.Key}</option>
                                                )
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <span className="NoteImageSize">* As per pincode preferred Delivery partner - {Expected_DeliveryPartner}</span>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Tracking URL</FormLabel>
                                <Input placeholder='Enter Tracking URL' {...stockregister("Tracking_URL")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"80%"}>
                            <FormControl >
                                <FormLabel>AWB No</FormLabel>
                                <Input placeholder='AWB No' {...stockregister("AWB_No")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Order Status</FormLabel>
                                <Select className="form-control" {...stockregister("OrderDeliveryStatus")} >
                                    <option value="">Select Order Status</option>
                                    {
                                        CommonVariables.OrderDeliveryStatus.map((data, index) => {
                                            if (data.Value != 'Booked') {
                                                return (
                                                    <option value={data.Value} key={index}>{data.Key}</option>
                                                )
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box width={"100%"}>
                            <FormControl >
                                <FormLabel>No Of Box</FormLabel>
                                <Select className="form-control" {...stockregister("No_Of_Box")}>
                                    <option value="0">Select No Of Box</option>
                                    {
                                        CommonVariables.NoOfBoxList.map((data, index) => {
                                            return (
                                                <option value={data.Value} key={index}>{data.Key}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"80%"}>
                            <FormControl >
                                <FormLabel>Status Reason</FormLabel>
                                <Input placeholder='Status Reason' {...stockregister("Status_Reason")} />
                            </FormControl>
                        </Box>

                        <Box width={"full"} paddingTop={30}>
                            <Button disabled={IsLoading} colorScheme='red' onClick={() => UpdateOrderStockStatus()}>
                                Update Status
                            </Button>
                        </Box>
                    </HStack>
                </Stack>
                <br />
                <Divider />
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Id</Th>
                                <Th>Stock Code</Th>
                                <Th>Product Name</Th>
                                {/* <Th>Price</Th> */}
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {OrderStockList.map((data, index) => {
                                return (<Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{data.Stock_Code}</Td>
                                    <Td>{data.Product_Name}</Td>
                                    {/* <Td>{data.Price}</Td> */}
                                    <Td>{data.Stock_Status}</Td>
                                </Tr>)
                            })}
                        </Tbody>
                        {OrderStockList.length <= 0 ? (
                            <Tfoot>
                                <Tr colSpan="3">
                                    <Td>
                                        No Data Available
                                    </Td>
                                </Tr>
                            </Tfoot>
                        ) : <></>
                        }

                    </Table>
                </TableContainer>
            </ModalComponent>

            <ModalComponent scroll="inside" Title="Update Payment Status" isOpen={isUpdatePayStatusOpen} onOpen={onUpdatePayStatusOpen} onClose={onUpdatePayStatusClose} size="4xl" >

                <HStack>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Customer Name</FormLabel>
                            <Input readOnly {...payregister("Contact_Person_Name")} />
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Customer Mobile</FormLabel>
                            <Input readOnly {...payregister("Contact_Person_Mobile")} />
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Customer Email</FormLabel>
                            <Input readOnly {...payregister("Contact_Person_Email")} />
                        </FormControl>
                    </Box>
                </HStack>
                <HStack>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Order Value</FormLabel>
                            <Input readOnly {...payregister("OrderValue")} />

                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Discounted Value</FormLabel>
                            <Input readOnly {...payregister("OrderValuePostDiscount")} />

                        </FormControl>
                    </Box>
                </HStack>

                <HStack>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={payerrors.Payment_Status}>
                            <FormLabel>Payment Status</FormLabel>
                            <Select className="form-control" {...payregister("Payment_Status", { required: "Payment Status is Mandatory" })} >
                                <option value="">Select Payment Status</option>
                                {
                                    CommonVariables.UpdatePayStatus.map((data, index) => {
                                        return (
                                            <option value={data.Value} key={index}>{data.Key}</option>
                                        )
                                    })
                                }
                            </Select>
                            <FormErrorMessage>
                                {payerrors.Payment_Status && payerrors.Payment_Status.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    {/* <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Status Reason</FormLabel>
                            <Input placeholder='Enter Status Reason' {...payregister("Status_Reason")} />

                        </FormControl>
                    </Box>  */}

                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Payment / Transaction Id</FormLabel>
                            <Input autoComplete='Off' placeholder='Enter Payment / Transaction Id' {...payregister("Payment_Id")} />
                        </FormControl>
                    </Box>
                </HStack>
                <HStack >
                    <Box width={"full"}>
                        <FormControl >
                            <FormLabel>Payment Mode</FormLabel>
                            <Select className="form-control" {...payregister("Payment_Mode")} >
                                <option value="">Select Payment Mode</option>
                                {
                                    CommonVariables.PaymentMode.map((data, index) => {
                                        return (
                                            <option value={data.Value} key={index}>{data.Key}</option>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width={"full"} spacing={"2"} paddingTop={"5"}>
                        <ButtonGroup variant='outline' spacing='4'  >
                            <Button disabled={IsLoading} type='submit' onClick={handleSubmit(UpdatePaymentDetail)} colorScheme="whatsapp">Update</Button>
                        </ButtonGroup> {''} &nbsp;
                        <ButtonGroup spacing='4'  >
                            <Button disabled={IsLoading} type='button' onClick={(e) => SendPaymentLink(e)} colorScheme="whatsapp">Send Payment Link</Button>
                        </ButtonGroup>
                    </Box>
                </HStack>

                {/* <HStack spacing={"2"} paddingTop={"5"} >
                    <ButtonGroup variant='outline' spacing='4'  >
                        <Button type='submit' disabled={IsLoading} onClick={payhandleSubmit(UpdatePaymentDetail)} colorScheme="whatsapp">Update</Button>
                    </ButtonGroup>
                    <ButtonGroup spacing='4'  >
                        <Button type='button' disabled={IsLoading} onClick={(e) => SendPaymentLink(e)} colorScheme="whatsapp">Send Payment Link</Button>
                    </ButtonGroup>
                </HStack> */}
                <br />
                <Divider />
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Sr.No</Th>
                                <Th>Product Name</Th>
                                <Th>Quantity</Th>
                                <Th>InstallationCharge</Th>
                                <Th>Price</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {PayOrderItemList.map((data, index) => {
                                return (<Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{data.Product_Name}</Td>
                                    <Td>{data.Quantity}</Td>
                                    <Td>{data.InstallationCharge}</Td>
                                    <Td>{data.Price}</Td>
                                </Tr>)
                            })}
                        </Tbody>
                        {PayOrderItemList.length <= 0 ? (
                            <Tfoot>
                                <Tr colSpan="3">
                                    <Td>
                                        No Data Available
                                    </Td>
                                </Tr>
                            </Tfoot>
                        ) : <></>
                        }

                    </Table>
                </TableContainer>
            </ModalComponent>

            <ModalComponent Title="Order Address Edit " scroll="inside" size={"5xl"} isOpen={isAddAddressOpen} onOpen={onAddAddressOpen} onClose={onAddAddressClose} >

                <Input hidden {...addressregister("OrderId", { setValueAs: v => parseInt(v) })} />
                <Input hidden {...addressregister("Id", { setValueAs: v => parseInt(v) })} />
                <Stack>

                    <HStack >
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Customer Name</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Name' {...addressregister("Contact_Person_Name")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Email Id</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Email Id' {...addressregister("Contact_Person_Email")} />
                            </FormControl>
                        </Box>
                        <Box width={500}>
                            <FormControl isRequired >
                                <FormLabel>Mobile No</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Mobile No' {...addressregister("Contact_Person_Mobile")} />
                            </FormControl>
                        </Box>
                        <Box width={500}>
                            <FormControl >
                                <FormLabel>Alt. Mobile No</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Alt. Mobile No' {...addressregister("Contact_Alternate_Mobile")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack >
                        <Box width={400}>
                            <FormControl>
                                <FormLabel>Address Type</FormLabel>
                                <Select className="form-control" {...addressregister("AddressType")} >
                                    <option value="">Select AddressType</option>
                                    {
                                        CommonVariables.AddressType.map((data, index) => {
                                            return (
                                                <option value={data.Value} key={index}>{data.Key}</option>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Flat No</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Flat No' {...addressregister("Flat_No")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Building Name</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Building Name' {...addressregister("Building_Name")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack paddingTop={"3"}>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Street Name</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Street Name' {...addressregister("Street")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Locality</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Locality' {...addressregister("Locality")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack paddingTop={"3"}>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>LandMark</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter Landmark' {...addressregister("Landmark")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack paddingTop={"3"}>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>City</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter City' {...addressregister("City")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Pincode</FormLabel>
                                <Input readOnly autoComplete='Off' placeholder='Enter Pincode' {...addressregister("Pincode")} />
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>GST No</FormLabel>
                                <Input autoComplete='Off' placeholder='Enter GST No' {...addressregister("GST_No")} />
                            </FormControl>
                        </Box>
                    </HStack>

                </Stack>
                {/* 
                <HStack spacing={"2"} paddingTop={"5"} >
                    <ButtonGroup variant='outline' spacing='4'  >
                        <Button type='submit' float="right" onClick={addresshandleSubmit(UpdateAddressDetail)} colorScheme="whatsapp">Update</Button>
                    </ButtonGroup>
                </HStack> */}

                <HStack float="right" paddingTop={"5"}>
                    <ButtonGroup variant='outline' spacing='4' >
                        <Button disabled={IsLoading} type='submit' onClick={addresshandleSubmit(UpdateAddressDetail)} colorScheme="red">Update</Button>
                    </ButtonGroup>
                </HStack>
            </ModalComponent>

            {/* <ModalComponent Title="Uploaders" scroll="inside" size={"3xl"} isOpen={isAddUploadProductOrderOpen} onOpen={onAddUploadProductOrderOpen} onClose={onAddUploadProductOrderClose} >
                <HStack >
                    <Box width={"full"} paddingLeft='5'>
                        <ButtonGroup variant='outline' spacing='4' >
                            <Button type='button' disabled={IsLoading} onClick={(e) => DownloadOrderUploaderFile(e)} colorScheme="whatsapp">Order Format</Button>
                        </ButtonGroup>
                    </Box>
                    <Box width={"full"} paddingLeft='5'>
                        <ButtonGroup variant='outline' spacing='4' >
                            <Button type='button' disabled={IsLoading} onClick={(e) => DownloadOrderInvoiceUploaderFile(e)} colorScheme="whatsapp">Invoice Format</Button>
                        </ButtonGroup>
                    </Box>

                    <Box width={"full"} paddingLeft='5'>
                        <ButtonGroup variant='outline' spacing='4' >
                            <Button type='button' disabled={IsLoading} onClick={(e) => DownloadDeliveryUploaderFile(e)} colorScheme="whatsapp">Delivery Format</Button>
                        </ButtonGroup>
                    </Box>
                    {
                        ShowOrderUploader ?

                            <HStack>
                                <Box width={"120%"}>
                                    <FormControl isRequired>
                                        <FormLabel>Select Product Order Uploader File</FormLabel>

                                        <FilePicker
                                            onFileChange={(fileList) => { onProductUploaderFileChange(fileList) }}
                                            placeholder="Select File"
                                            clearButtonLabel="Clear"
                                            multipleFiles={false}
                                            accept=".xls,.xlsx,.csv"
                                            hideClearButton={false}
                                        />

                                    </FormControl>
                                </Box>
                                <Box width={"20%"} paddingTop='8' paddingLeft='5'>
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='button' disabled={IsLoading} onClick={(e) => UploadFile(e)} colorScheme="whatsapp">Order Upload</Button>
                                    </ButtonGroup>
                                </Box>

                            </HStack>
                            : <></>
                    }

                </HStack>
            </ModalComponent> */}

            <ModalComponent Title="Uploaders" scroll="inside" size={"3xl"} isOpen={isAddUploadProductOrderOpen} onOpen={onAddUploadProductOrderOpen} onClose={onAddUploadProductOrderClose} >

                <Tabs >
                    <TabList>
                        <Tab>Order Uploader</Tab>
                        <Tab>Invoice Uploader</Tab>
                        <Tab>Delivery Uploader</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <HStack>
                                <Box width={"120%"}>
                                    <FormControl isRequired>
                                        <FormLabel>Select Product Order Uploader File</FormLabel>

                                        <FilePicker
                                            onFileChange={(fileList) => { onProductUploaderFileChange(fileList) }}
                                            placeholder="Select File"
                                            clearButtonLabel="Clear"
                                            multipleFiles={false}
                                            accept=".xls,.xlsx,.csv"
                                            hideClearButton={false}
                                        />

                                    </FormControl>
                                </Box>
                                <Box width={"20%"} paddingTop='8' paddingLeft='5'>
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='button' disabled={IsLoading} onClick={(e) => UploadFile(e)} colorScheme="whatsapp">Order Upload</Button>
                                    </ButtonGroup>
                                </Box>
                                <Box width={"full"} paddingTop='8' paddingLeft='5'>
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='button' disabled={IsLoading} onClick={(e) => DownloadOrderUploaderFile(e)} colorScheme="whatsapp">Order Format</Button>
                                    </ButtonGroup>
                                </Box>

                            </HStack>
                            <HStack>
                                {(REOrderUploaderFileURL != '') ?
                                    <Link href={REOrderUploaderFileURL} isExternal>
                                        <ExternalLinkIcon mx='2px' />Click here to download failed records.
                                    </Link> : <></>
                                }
                            </HStack>
                        </TabPanel>
                        <TabPanel>
                            <HStack>
                                <Box width={"120%"}>
                                    <FormControl isRequired>
                                        <FormLabel>Select Invoice Uploader File</FormLabel>

                                        <FilePicker
                                            onFileChange={(fileList) => { onProductInvoivoiceUploaderFileChange(fileList) }}
                                            placeholder="Select File"
                                            clearButtonLabel="Clear"
                                            multipleFiles={false}
                                            accept=".xls,.xlsx,.csv"
                                            hideClearButton={false}
                                        />

                                    </FormControl>
                                </Box>
                                <Box width={"20%"} paddingTop='8' paddingLeft='5'>
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='button' disabled={IsLoading} onClick={(e) => InvoiceUploadFile(e)} colorScheme="whatsapp">Upload Invoice </Button>
                                    </ButtonGroup>
                                </Box>
                                <Box width={"full"} paddingTop='8' paddingLeft='5'>
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='button' disabled={IsLoading} onClick={(e) => DownloadOrderInvoiceUploaderFile(e)} colorScheme="whatsapp">Invoice Format</Button>
                                    </ButtonGroup>
                                </Box>

                            </HStack>
                        </TabPanel>

                        <TabPanel>
                            <HStack>
                                <Box width={"120%"}>
                                    <FormControl isRequired>
                                        <FormLabel>Select Delivery Uploader File</FormLabel>

                                        <FilePicker
                                            onFileChange={(fileList) => { onProductDeliveryUploaderFileChange(fileList) }}
                                            placeholder="Select File"
                                            clearButtonLabel="Clear"
                                            multipleFiles={false}
                                            accept=".xls,.xlsx,.csv"
                                            hideClearButton={false}
                                        />

                                    </FormControl>
                                </Box>
                                <Box width={"full%"} paddingTop='8' paddingLeft='5'>
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='button' disabled={IsLoading} onClick={(e) => DeliveryUploadFile(e)} colorScheme="whatsapp">Upload Delivery File </Button>
                                    </ButtonGroup>
                                </Box>
                                <Box width={"full"} paddingTop='8' paddingLeft='5'>
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='button' disabled={IsLoading} onClick={(e) => DownloadOrderInvoiceUploaderFile(e)} colorScheme="whatsapp">Delivery Format</Button>
                                    </ButtonGroup>
                                </Box>

                            </HStack>
                        </TabPanel>

                    </TabPanels>
                </Tabs>
            </ModalComponent>

        </ContainerComponent>
    )
}

export default OrderListComponent