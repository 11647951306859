import axios from "axios";
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetBlogList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BlogMaster/GetBlogList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetBlogDetailById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BlogMaster/GetBlogDetailById?id=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveBlogDetail = async (data) => {
    debugger;
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BlogMaster/SaveBlogDetail`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdateBlogDetail = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BlogMaster/UpdateBlogDetail`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const ActivateDeActivateBlog = async (Id,IsActive) => {
    debugger;
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BlogMaster/ActivateDeactivateBlog?blogid=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return result;
}