import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for MetaProductMaster list
export const GetMetaMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `MetaProductMaster/GetAllMetaProductList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetMetaDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `MetaProductMaster/GetMetaDetailsById?metaid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new MetaProductMaster detail
export const SaveMetaDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `MetaProductMaster/SaveMetaProduct`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing Meta detail
export const UpdateMetaDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `MetaProductMaster/UpdateMetaProduct`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const ActivateDeactivateMeta = async (id,isActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `MetaProductMaster/DeleteMetaProductById?id=${id}&status=${isActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
