import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as MetaService from '../../Services/Meta/MetaService.js'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { FormControl, Select, FormLabel, HStack, FormErrorMessage, Input, Checkbox, useDisclosure, Stack, Textarea, ButtonGroup, Tfoot, Box, TableContainer, Button, Thead, Tbody, Td, Tr, Th } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import { Table } from "react-chakra-pagination";
import { Tooltip } from '@chakra-ui/react'
import useLoaderState from '../../Common/useLoaderState';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';

function MetaMasterComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const [MetaList, setMetaMasterList] = useState([]);
    const [selectedmetaid, setSelectedMeta] = useState(0);
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const selectedMeta = useRef();
    const [page, setPage] = useState(1);
    const [IsLoading] = useLoaderState();

    useEffect(() => {
        LoadMetaMasterList();
    }, [])

    const tableData = MetaList.map((meta, index) => ({
        "key": "index",
        Id: meta.Id,
        PageURL: meta.PageURL,
        Meta_Title: meta.Meta_Title,
        Is_Active:
            (
                <Checkbox colorScheme={meta.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                </Checkbox>
            ),
        Action: (
            <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Meta' placement='auto-start'>
                    <Button disabled={IsLoading} colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditMetaMaster(meta.Id)} >
                        <EditIcon />

                    </Button >
                </Tooltip>
                <Tooltip label={meta.Is_Active ? 'DeActivate Meta' : 'Activate Meta'} placement='auto-start'>
                    <Button disabled={IsLoading} colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteMeta(meta.Id, meta.Is_Active)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </ButtonGroup >

        )

    }));

    const tableColumns = [
        {
            Header: "Id",
            accessor: "Id"
        },
        {
            Header: "PageURL",
            accessor: "PageURL"
        },

        {
            Header: "Meta Title",
            accessor: "Meta_Title"
        },

        {
            Header: "IsActive",
            accessor: "Is_Active"
        },
        {
            Header: "Action",
            accessor: "Action"
        }
    ];
    const LoadMetaMasterList = () => {
        MetaService.GetMetaMasterList().then(data => {
            if (data.data.IsSuccess) {
                setMetaMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }


    const AddMeta = () => {
        reset({});
        onAddOpen();
        setSelectedMeta(0);
    }


    const SaveMetaDetail = (value) => {

        if (selectedmetaid <= 0) {
            MetaService.SaveMetaDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadMetaMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedmetaid;
            MetaService.UpdateMetaDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadMetaMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }

    const EditMetaMaster = (id) => {
        setSelectedMeta(id);
        MetaService.GetMetaDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteMeta = (id, isActive) => {
        debugger;
        var action = isActive ? window.confirm('Do you want to DeActivate Meta?') : window.confirm('Do you want to Activate Meta?');
        if (action == true) {
            MetaService.ActivateDeactivateMeta(id, !isActive).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadMetaMasterList();
                }
            }).catch(error => {

            })
        }
    }


    return (
        <ContainerComponent Title="Meta List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Meta" AddNewAction={AddMeta}>

            <Table
                emptyData={{
                    text: "No Data Available."
                }}
                colorScheme="blue"
                totalRegisters={MetaList.length}
                page={page}
                onPageChange={(page) => setPage(page)}
                columns={tableColumns}
                data={tableData}
            />
        <LoadingSpinnerComponent></LoadingSpinnerComponent>

            <ModalComponent size="5xl" scroll="inside" Title="Add/Update Meta Master" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveMetaDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.PageURL}>
                                <FormLabel>Page Url</FormLabel>
                                <Input placeholder='Page Url' {...register("PageURL", { required: "PageUrl is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.PageURL && errors.PageURL.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl>
                                <FormLabel>Meta Author</FormLabel>
                                <Input placeholder='Meta Author' {...register("Meta_Author")} />

                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Meta_Title}>
                                <FormLabel>Meta Title</FormLabel>
                                <Textarea placeholder='Meta Title'  {...register("Meta_Title", { required: "Title is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Meta_Title && errors.Meta_Title.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl >
                                <FormLabel>Meta Keywords</FormLabel>
                                <Textarea placeholder='Meta Keywords'  {...register("Meta_Keywords")} />
                            </FormControl>
                        </Box>
                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isInvalid={errors.Meta_Description}>
                                <FormLabel>Meta Description</FormLabel>

                                <Textarea placeholder='Meta Description'  {...register("Meta_Description")} />

                            </FormControl>
                        </Box>

                    </HStack>

                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.OtherMetaData}>
                                <FormLabel>OtherMetaData</FormLabel>
                                <Textarea placeholder='Other Meta Data'  {...register("OtherMetaData", { required: "Other Metadata is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.OtherMetaData && errors.OtherMetaData.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>

                    </HStack>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Canonical_Tag}>
                                <FormLabel>Canonical Tag</FormLabel>
                                <Input placeholder='Canonical Tag' {...register("Canonical_Tag", { required: "Canonical Tag is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Canonical_Tag && errors.Canonical_Tag.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>

                    </HStack>

                </Stack>
            </ModalComponent>
        </ContainerComponent >
    )
}

export default MetaMasterComponent