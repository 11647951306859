import React from 'react'
import { useForm } from 'react-hook-form'
import { Box, ModalCloseButton, ButtonGroup, Modal, ModalFooter, ModalOverlay, ModalBody, ModalHeader, ModalContent, VStack, FormControl, Button } from '@chakra-ui/react'
function ModalComponent(props) {
    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false} onClose={props.onClose} isOpen={props.isOpen} size={props.size ? props.size : "lg"} scrollBehavior={props.scroll ? props.scroll : ""} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.Title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={5}>
                        {props.onSave ?
                            <Box as="form" width={"full"}>
                                {props.children}
                            </Box> :
                            <Box width={"full"}>
                                {props.children}
                            </Box>
                        }
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    {props.onSave ? <ButtonGroup variant='outline' spacing='4'>
                        {props.onSave ? <Button type='submit' onClick={props.onSave} colorScheme="whatsapp">Save</Button> : <></>}
                        <Button onClick={props.onClose}>Close</Button>
                    </ButtonGroup> : <></>
                    }
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalComponent