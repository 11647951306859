import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { HStack, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as ProductFAQService from '../../Services/Product/ProductFAQService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';

function ProductFAQComponent(props) {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { handleSubmit: handleProductFAQSubmit, register: registerProductFAQ, setValue: setValueProductFAQ, reset: resetProductFAQ, formState: { errors: errorProductFAQ } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isPinCodeOpen, onOpen: onPinCodeOpen, onClose: onPinCodeClose } = useDisclosure()
    const [ProductFAQList, setProductFAQList] = useState([]);
    const [IsLoading] = useLoaderState();
    const [Product_Id, SetProductId] = useState(0);

    useEffect(() => {
        SetProductId(props.Product_Id)
        if (parseInt(props.Product_Id) > 0)
            GetProductFAQListByProductId();
    }, [])


    const SaveProductFAQ = (value) => {
        value.FAQ_Id = 0;
        value.Product_Id = props.Product_Id;
        value.Sequence_No = parseInt(value.Sequence_No);

        ProductFAQService.SaveProductFAQ(value).then(data => {
            if (data.data.IsSuccess) {
                setValueProductFAQ('FAQ_Title','');
                setValueProductFAQ('FAQ_Detail','');
                setValueProductFAQ('FAQ_Type','');
                setValueProductFAQ('Sequence_No',0);
                toast.success("FAQ Save");
                GetProductFAQListByProductId(props.Product_Id);
            }
        }).catch(error => {

        });
    }


    const EditProductFAQ = (id) => {
        ProductFAQService.GetProductFAQByProductId(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
            }
        }).catch(error => {

        })
    }

    const ActivateDeactivateProductFAQ = (id, Is_Active) => {
        ProductFAQService.ActivateDeactivateProductFAQ(id, !Is_Active).then(data => {
            if (data.data.IsSuccess) {
                toast.success('Deleted Successfully')
                GetProductFAQListByProductId(props.Product_Id);
            }
        }).catch(error => {

        })
    }

    const GetProductFAQListByProductId = () => {
        SetProductId(props.Product_Id);
        ProductFAQService.GetProductFAQByProductId(props.Product_Id).then(data => {
            if (data.data.IsSuccess) {
                setProductFAQList(data.data.Data);
            }
            else {
                setProductFAQList([])
            }
        }).catch(error => {

        });
    }

    return (
        <Stack spacing={4}>
        <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Box as="form">
                <Input hidden  {...register("FAQ_Id")} />

                <Input type="number" hidden  {...register("Product_Id")} />

                <HStack paddingTop={"5"}>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.FAQ_Title}>
                            <FormLabel>FAQ Title</FormLabel>
                            <Input placeholder='FAQ Title' {...registerProductFAQ("FAQ_Title", { required: "FAQ Title is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.FAQ_Title && errors.FAQ_Title.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.FAQ_Detail}>
                            <FormLabel>FAQ Detail</FormLabel>
                            <Input placeholder='FAQ Detail' {...registerProductFAQ("FAQ_Detail", { required: "FAQ Detail is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.FAQ_Detail && errors.FAQ_Detail.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack>

                <HStack paddingTop={"5"}>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.FAQ_Type}>
                            <FormLabel>FAQ Type</FormLabel>
                            <Input placeholder='FAQ Type' {...registerProductFAQ("FAQ_Type", { required: "FAQ Type is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.FAQ_Type && errors.FAQ_Type.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errors.Sequence_No}>
                            <FormLabel>Sequence No</FormLabel>
                            <Input type="number" placeholder='Sequence No' {...registerProductFAQ("Sequence_No", { required: "Sequence No is Mandatory" })} />
                            <FormErrorMessage>
                                {errors.Sequence_No && errors.Sequence_No.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack>

                <Checkbox hidden  {...register("Is_Active")} > </Checkbox >
                <HStack spacing={"2"} paddingTop={"5"} float="right">
                    <ButtonGroup variant='outline' spacing='4' >
                        <Button type='submit' disabled={IsLoading} onClick={handleProductFAQSubmit(SaveProductFAQ)} colorScheme="whatsapp">Save</Button>
                    </ButtonGroup>
                </HStack>
            </Box>

            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Product Name</Th>
                            <Th>FAQ Title</Th>
                            <Th>Sequence_No</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ProductFAQList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.FAQ_Id}</Td>
                                <Td>{data.Product_Name}</Td>
                                <Td>{data.FAQ_Title}</Td>
                                <Td>{data.Sequence_No}</Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Button disabled={IsLoading} colorScheme="blue" variant="solid" size={"xs"} onClick={() => ActivateDeactivateProductFAQ(data.FAQ_Id, data.Is_Active)}>
                                            <DeleteIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {ProductFAQList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="12">
                                <Td colSpan="12">
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>

        </Stack >

    )
}





export default ProductFAQComponent