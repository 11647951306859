import { store } from "../Store/store";

export const set_user_details = (data) => {
    
    return {
        type: "set-user-info",
        user: data
    }
}

export const reset_user_details = (data) => {
    return {
        type: "logout",
        user: data
    }
}

export const thunk_set_user = (userInfo) => {
    return function (dispatch, getstate) {
        dispatch(set_user_details(userInfo))
    }
}

export const thunk_reset_user = (userInfo) => {
    return function (dispatch, getstate) {
        dispatch(reset_user_details(userInfo))
    }
}