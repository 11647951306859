import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetSubCategoryListByCategoryId = async (categoryId) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductSubCategory/GetProductSubCategoryByCategoryId?categoryId=${categoryId}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetSubCategoryById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductSubCategory/GetProductSubCategoryById?ProductSubCategoryId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const DeleteSubCategoryById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductSubCategory/DeleteProductSubCategoryDetail?productSubCategoryId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveSubCategory = async (data) => {
    debugger;
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductSubCategory/SaveProductSubCategory`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdateSubCategory = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductCategory/UpdateProductSubCategory`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}