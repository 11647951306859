import React from 'react';
import { Bar } from 'react-chartjs-2';
import * as moment from 'moment';

const BarChartComponent = ({ data }) => {

 

  const handleOnClick = (event) => {
    debugger;
    const barIndex = event.chart.tooltip.$context.tooltipItems[0].dataIndex;
      debugger;
      const label = data.labels[barIndex];
      const date = moment(label).format("YYYY-MM-DD")
      console.log(date);
      window.location.replace("https://productadmin.hicare.in/orderlist/" + date);
  };

  const options = {
  scales: {
    x: { stacked: true },
    y: { stacked: true },
  },
  responsive: true,
  maintainAspectRatio: false,
  onClick: handleOnClick
};

  return<div style={{ width: '100%', height: '400px' , cursor: 'pointer'}}>
  <Bar data={data} options = {options} />
  </div>
   ;
};

export default BarChartComponent;