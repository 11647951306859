import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetProductFAQList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductFAQ/GetProductFAQList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductFAQById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductFAQ/GetProductFAQById?productFAQId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveProductFAQ = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductFAQ/SaveProductFAQ', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateProductFAQ = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductFAQ/UpdateProductFAQ', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactivateProductFAQ = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductFAQ/ActivateDeactivateProductFAQ?ProductFAQId=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetProductFAQByProductId = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductFAQ/GetProductFAQByProductId?productId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}