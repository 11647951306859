import React, { useState, useRef, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { List, ListItem, Grid, GridItem, HStack, Select, FormControl, FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider, Textarea } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import * as ComplaintService from '../../Services/Complaint/ComplaintService'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaLeaf, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import * as CommonVariables from '../../Common/CommonVariables'
import * as moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { store } from '../../Store/store';
import { overflow } from 'ui-box';
import { Tooltip } from '@chakra-ui/react'


function ComplaintComponent() {
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
    const { register: complaintregister, getValues: complaintgetValue, setValue: complaintsetValue, reset: complaintreset, setError: complaintsetError, handleSubmit: complainthandleSubmit, formState: { errors: complainterrors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const { isOpen: isComplaintAddOpen, onOpen: onComplaintAddOpen, onClose: onComplaintAddClose } = useDisclosure();
    const [ComplaintList, setComplaintList] = useState([]);
    const [SerchByOrderNoMobileNoList, setSerchByOrderNoMobileNo] = useState([]);
    const [AddComplaintList, setAddComplaint] = useState([]);
    const [IsLoading] = useLoaderState();
    const [SelectedComplaintId, setComplaintId] = useState(0);
    const [SelectedComplaintOrder, setSelectedComplaintOrder] = useState({});
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const Complaint_Action = useRef();
    const seacrh = useRef();
    const userInfo = store.getState();
    const searchorderno = useRef();
    const [ComplainthistoryList, setComplainthistoryList] = useState([]);

    useEffect(() => {
        LoadComplaintList();
    }, [])


    const LoadComplaintList = () => {
        debugger;
        ComplaintService.GetComplaintList('', moment(StartDate).format("YYYY-MM-DD"), moment(EndDate).format("YYYY-MM-DD"), '').then(data => {
            if (data.data.IsSuccess) {
                setComplaintList(data.data.Data);
            } else {
                setComplaintList([]);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const SearchComplaint = () => {
        ComplaintService.GetComplaintList(Complaint_Action.current.value, moment(StartDate).format("YYYY-MM-DD"), moment(EndDate).format("YYYY-MM-DD"), searchorderno.current.value).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                setComplaintList(data.data.Data);
            } else {
                setComplaintList([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const GetSerachRecordByOrderNoMobileNo = () => {
        ComplaintService.GetSerachRecordByOrderNoMobileNo(seacrh.current.value).then(data => {
            if (data.data.IsSuccess) {
                setSerchByOrderNoMobileNo(data.data.Data);
                setSelectedComplaintOrder({});
            } else {
                toast.error(data.data.ResponseMessage)
                setSerchByOrderNoMobileNo([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    const Download = () => {
        debugger;
        window.open(CommonVariables.API_DOMAIN + `Complaint/GetFilteredDownloadCOmplaint?userId=${parseInt(userInfo.userData.User_Id)}&complaintstatus=${Complaint_Action.current.value}&startDate=${moment(StartDate).format("YYYY-MM-DD")}&endDate=${moment(EndDate).format("YYYY-MM-DD")}`, "_blank");

    }
    const ViewComplaint = (id) => {
        reset({})
        setComplaintId(SelectedComplaintId);
        ComplaintService.GetComplaintById(id).then(data => {
            debugger;
            if (data.data.IsSuccess) {
                reset(data.data.Data.Complaint)
                setComplainthistoryList(data.data.Data.ComplaintHistory)
                onAddOpen();
            }
            else {
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {

        })
    }

    const AddOrderIdToComplaint = (e, index) => {
        e.preventDefault();
        setSelectedComplaintOrder({});
        var tempData = SerchByOrderNoMobileNoList;

        // tempData.forEach(a => {
        //     a.Is_SelectedComplaint = false;
        // });

        setSerchByOrderNoMobileNo(tempData)
        debugger;
        // tempData[index] = { ...tempData[index], Is_SelectedComplaint: true };
        setSelectedComplaintOrder(tempData[index]);
        // setSerchByOrderNoMobileNo(tempData)
        toast.success("Selected Complaint is: " + tempData[index].OrderNumber);
    }
    const AddComplaint = () => {
        complaintreset({})
        setSelectedComplaintOrder({})
        setSerchByOrderNoMobileNo([]);
        onComplaintAddOpen();

    }

    const UpdateComplaint = (values) => {
        console.log(values);
        debugger;
        values.HiCare_Remark = values.Remark;
        ComplaintService.UpdateComplaintDetail(values).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Updated Succesffully!");
                reset({});
                LoadComplaintList();
                onAddClose();
            }
            else {
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {

        })
    }

    const SaveComplaint = (values) => {
        console.log(values);

        if (SelectedComplaintOrder.OrderNumber != undefined) {
            values.OrderNumber = SelectedComplaintOrder.OrderNumber;
            values.Order_Id = SelectedComplaintOrder.Id;
            values.Product_Id = SelectedComplaintOrder.Product_Id;
            values.Customer_Id = SelectedComplaintOrder.Customer_Id;
        }
        else {
            toast.error('Select Order first')
            return false;
        }
        ComplaintService.SaveComplaintDetail(values).then(data => {
            if (data.data.IsSuccess) {
                toast.success("Save Complaint Succesffully!");
                complaintreset({});
                LoadComplaintList();
                onComplaintAddClose();
            }
            else {
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {

        })
    }

    return (
        <ContainerComponent Title="Complaints" Icon={<FaCopyright />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Complaint" AddNewAction={AddComplaint} >
            <HStack>
                <Box width={"250"} paddingTop={2} paddingLeft={5} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>Complaint Status</FormLabel>
                        <Select className="form-control" ref={Complaint_Action}>
                            <option value="">Select Complaint Action</option>
                            {
                                CommonVariables.Complaint_Action.map((data, index) => {
                                    return (
                                        <option value={data.Value} key={index}>{data.Key}</option>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingLeft={5} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>Start Date</FormLabel>

                        <ReactDatePicker
                            valueName="selected" // DateSelect value's name is selected
                            selected={StartDate}
                            onChange={(date) => setStartDate(date)}
                            name="ReactDatepicker"
                            className="text-center border-l-4 border-red-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                            placeholderText="Select start date"
                            dateFormat='dd-MM-yyyy'
                            popperPlacement=""
                            value={StartDate}
                        />
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>End Date</FormLabel>

                        <ReactDatePicker
                            valueName="selected" // DateSelect value's name is selected
                            selected={EndDate}
                            onChange={(date) => setEndDate(date)}
                            name="ReactDatepicker"
                            className="text-center border-l-4 border-red-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                            placeholderText="Select start date"
                            dateFormat='dd-MM-yyyy'
                            value={EndDate}
                        />
                    </FormControl>
                </Box>
                <Box width={"20%"} paddingTop={2} paddingLeft={2} paddingBottom={10}>
                    <FormControl>
                        <FormLabel>Search OrderNo</FormLabel>
                        <Input placeholder='Enter Order No ' ref={searchorderno} />
                    </FormControl>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button disabled={IsLoading} colorScheme='red' onClick={() => SearchComplaint()} marginTop={8}>
                        Search
                    </Button>
                </Box>
                <Box width={"100"} paddingTop={2} paddingBottom={10} >
                    <Button colorScheme='red' onClick={() => Download()} marginTop={8}>
                        Download
                    </Button>
                </Box>
            </HStack>
            <TableContainer>

                <Spacer />
                <Table size="sm" variant='simple' >
                    <Thead>
                        <Tr>
                            <Th>Id</Th>
                            <Th>Date</Th>
                            <Th>Subject</Th>
                            <Th>Order No</Th>
                            <Th>Product</Th>
                            <Th>Status</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ComplaintList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{moment(data.Created_On).format("DD-MMM-YYYY hh:mm A")}</Td>
                                <Td>{data.Complaint_Subject}</Td>
                                <Td>{data.OrderNumber}</Td>
                                <Td>{data.Product_Name}</Td>
                                <Td>{data.Complaint_Status}</Td>
                                <Td>
                                    {data.Complaint_Status != "Closed" ? <ButtonGroup variant={"outline"} spacing="1">
                                        <Tooltip label='View Complaint' placement='auto-start'>
                                            <Button colorScheme='whatsapp' variant='solid' size={"xs"} onClick={() => ViewComplaint(data.Id)}>
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup> : <></>}
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {ComplaintList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>

            <ModalComponent scroll="inside" Title="Complaint Details" size='{7xl}' isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(UpdateComplaint)} >
                <Grid templateColumns='repeat(3, 2fr)'>
                    <GridItem colSpan={2} >
                        <Box>
                            <Input hidden  {...register("Id")} />
                            <HStack>
                                <Box width={"80%"}>
                                    <FormControl >
                                        <FormLabel>Complaint Subject</FormLabel>
                                        <Input readOnly {...register("Complaint_Subject")} />
                                    </FormControl>
                                </Box>
                                <Box width={"40%"}>
                                    <FormControl >
                                        <FormLabel>Order No</FormLabel>
                                        <Input readOnly {...register("OrderNumber")} />
                                    </FormControl>
                                </Box>
                                <Box width={"30%"}>
                                    <FormControl >
                                        <FormLabel>Booking Source </FormLabel>
                                        <Input readOnly {...register("Booking_Source")} />
                                    </FormControl>
                                </Box>
                                <Box width={"30%"}>
                                    <FormControl >
                                        <FormLabel>Order Date </FormLabel>
                                        <Input readOnly {...register("OrderDate")} />
                                    </FormControl>
                                </Box>
                            </HStack>
                            <HStack>
                                <Box width={"full"}>
                                    <FormControl >
                                        <FormLabel>Contact Name</FormLabel>
                                        <Input readOnly {...register("Contact_Person_Name")} />
                                    </FormControl>
                                </Box>
                                <Box width={"full"}>
                                    <FormControl >
                                        <FormLabel>Contact Mobile</FormLabel>
                                        <Input readOnly {...register("Contact_Person_Mobile")} />
                                    </FormControl>
                                </Box>
                                {/* <Box width={"full"}>
                                    <FormControl >
                                        <FormLabel>Complaint Category</FormLabel>
                                        <Input readOnly {...register("Product_Name")} />
                                    </FormControl>
                                </Box> */}
                            </HStack>
                            <HStack>
                                <Box width={"full"}>
                                    <FormControl >
                                        <FormLabel>Complaint Description</FormLabel>
                                        <Textarea readOnly placeholder='Enter Remark' {...register("Complaint_Description")} />
                                    </FormControl>
                                </Box>
                            </HStack>
                            <HStack>
                                <Box width={"full"}>
                                    <FormControl >
                                        <FormLabel>Last Interaction</FormLabel>
                                        <Textarea height={20} readOnly {...register("Last_Interaction")} />
                                    </FormControl>
                                </Box>
                            </HStack>
                            <HStack>
                                <Box width={"full"}>
                                    <FormControl isRequired isInvalid={errors.Complaint_Status}>
                                        <FormLabel>Complaint Category</FormLabel>
                                        <Select className="form-control" {...register("Complaint_Category")} >
                                            <option value="">Select Complaint Category</option>
                                            {
                                                CommonVariables.Complaint_Category_Type.map((data, index) => {
                                                    return (
                                                        <option value={data.Value} key={index}>{data.Key}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormErrorMessage>
                                            {errors.Campaign_Type && errors.Campaign_Type.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                                <Box width={"full"}>
                                    <FormControl isRequired isInvalid={errors.Complaint_Status}>
                                        <FormLabel>Complaint Action</FormLabel>
                                        <Select className="form-control" {...register("Complaint_Status")} >
                                            <option value="">Select Complaint Action</option>
                                            {
                                                CommonVariables.Complaint_Action.map((data, index) => {
                                                    return (
                                                        <option value={data.Value} key={index}>{data.Key}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormErrorMessage>
                                            {errors.Campaign_Type && errors.Campaign_Type.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                            </HStack>
                            <HStack>
                                <Box width={"full"}>
                                    <FormControl isRequired isInvalid={errors.Remark}>
                                        <FormLabel>HiCare Remark</FormLabel>
                                        <Textarea placeholder='HiCare Remark' {...register("Remark", { required: "HiCare Remark is Mandatory" })} />
                                        <FormErrorMessage>
                                            {errors.Remark && errors.Remark.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Box>
                            </HStack>
                        </Box>

                    </GridItem>

                    <GridItem colSpan={1} paddingLeft={2}>

                        <HStack>
                            <Box width={"full"}>
                                <FormControl >
                                    <FormLabel>Product Name</FormLabel>
                                    <Input readOnly {...register("Product_Name")} />
                                </FormControl>
                            </Box>
                        </HStack>
                        <div overflow>{ComplainthistoryList.map((data, index) => {
                            return (
                                <ContainerComponent >
                                    <Box key={index} >
                                        <Box width={'full'}><b>Created By: </b> {data.UserName} </Box>
                                        <Box width={'full'}><b>Created On: </b> {moment(data.Created_On).format("DD-MMM-YYYY hh:mm A")} </Box>
                                        <Box width={'full'}><b>Complaint Status : </b> {data.Complaint_Status} </Box>
                                        <Box width={'full'}><b>Remark : </b> {data.Remark} </Box>
                                    </Box>
                                </ContainerComponent>
                            )
                        })}
                        </div>

                    </GridItem>
                </Grid>
            </ModalComponent>

            <ModalComponent scroll="inside" Title="New Complaint" size={"6xl"} isOpen={isComplaintAddOpen} onOpen={onComplaintAddOpen} onClose={onComplaintAddClose}  >

                <Stack >
                    <HStack>
                        <Box width={"80%"} paddingTop={2} paddingLeft={5} paddingBottom={10}>
                            <FormControl>
                                <FormLabel>Order Status</FormLabel>
                                <Input placeholder='Enter Order No / Phone No' ref={seacrh} />
                            </FormControl>
                        </Box>
                        <Box width={"20%"} paddingTop={2} paddingBottom={10} >
                            <Button disabled={IsLoading} colorScheme='red' onClick={() => GetSerachRecordByOrderNoMobileNo()} marginTop={8}>
                                Search
                            </Button>
                        </Box>
                    </HStack>
                </Stack>
                <Grid templateColumns='repeat(2, 1fr)'>

                    <GridItem w='95%' >

                        <div>
                            {SerchByOrderNoMobileNoList.map((data, index) => {
                                return (
                                    <ContainerComponent paddingTop={5}>
                                        <Box key={index} marginLeft={3}>
                                            <Stack marginBottom={5}>
                                                <HStack>
                                                    <Box width={'full'}><b>Order No : </b> {data.OrderNumber} </Box>
                                                    <Box width={'full'}><b>Order Status : </b> {data.OrderStatus} </Box>
                                                </HStack>
                                                <HStack>
                                                    <Box width={'full'}><b>Order Date : </b> {moment(data.OrderDate).format("DD-MMM-YYYY")} </Box>
                                                    <Box width={'full'}><b>Order Value : </b>{parseFloat(data.OrderValuePostDiscount).toFixed(2)}</Box>
                                                </HStack>
                                                <HStack>
                                                    <Box width={'full'}><b>Product Name: </b> {data.Product_Name} </Box>
                                                </HStack>
                                                <HStack>
                                                    <Box width={'full'}><b>Quantiity : </b> {data.Quantity} </Box>
                                                    <Box width={'full'}>  <Button colorScheme='red' variant='outline' onClick={(e) => AddOrderIdToComplaint(e, index)} > Press to Apply complain</Button> </Box>
                                                </HStack>
                                            </Stack>
                                        </Box>
                                    </ContainerComponent>
                                )
                            })}
                        </div>

                    </GridItem>
                    <GridItem w='100%' >
                        {SerchByOrderNoMobileNoList.length > 0 ?
                            <Box>
                                <HStack>
                                    {
                                        (SelectedComplaintOrder.OrderNumber != undefined && SelectedComplaintOrder.OrderNumber != "") ?
                                            <Box width={"full"} >
                                                <b>Current Selected Order : </b>{SelectedComplaintOrder.OrderNumber}
                                            </Box> :
                                            <></>}
                                </HStack>
                                <HStack>
                                    <Box width={"full"}>
                                        <FormControl isRequired isInvalid={complainterrors.Complaint_Subject}>
                                            <FormLabel>Complaint Subject</FormLabel>
                                            <Input {...complaintregister("Complaint_Subject", { required: "Complaint Subject is Mandatory" })} />
                                            <FormErrorMessage>
                                                {complainterrors.Complaint_Subject && complainterrors.Complaint_Subject.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                </HStack>
                                <HStack>
                                    <Box width={"full"}>
                                        <FormControl isRequired isInvalid={complainterrors.Complaint_Description}>
                                            <FormLabel>Complaint Description</FormLabel>
                                            <Textarea {...complaintregister("Complaint_Description", { required: "Complaint Description is Mandatory" })} />
                                            <FormErrorMessage>
                                                {complainterrors.Complaint_Description && complainterrors.Complaint_Description.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                </HStack>
                                <br />
                                <HStack float="right">
                                    <ButtonGroup variant='outline' spacing='4' >
                                        <Button type='submit' onClick={complainthandleSubmit(SaveComplaint)} colorScheme="whatsapp">Save</Button>
                                    </ButtonGroup>
                                </HStack>
                            </Box> : <></>}
                    </GridItem>

                </Grid>
            </ModalComponent>
        </ContainerComponent >
    )
}

export default ComplaintComponent