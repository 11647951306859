import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetComplaintList = async (status,date,endDate,orderno) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Complaint/GetComplaintList?complaintstatus=${status}&startDate=${date}&endDate=${endDate}&orderno=${orderno}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetSerachRecordByOrderNoMobileNo = async (search) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Complaint/GetSerachRecordByOrderNoMobileNo?search=${search}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetComplaintById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Complaint/GetComplaintById?ComplaintId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const UpdateComplaintDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Complaint/UpdateComplaint', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const SaveComplaintDetail = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Complaint/SaveComplaint', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
