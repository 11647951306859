import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const GetProductConfigurationList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductConfiguration/GetProductConfigurationList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductConfigurationById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductConfiguration/GetProductConfigurationById?productConfigurationId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveProductConfiguration = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductConfiguration/SaveProductConfiguration', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateProductConfiguration = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductConfiguration/UpdateProductConfiguration', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactivateProductConfiguration = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductConfiguration/ActivateDeactivateProductConfiguration?id=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetProductConfigurationByProductId = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductConfiguration/GetProductConfigurationByProductId?productId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetProductSpecificationById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ProductConfiguration/GetProductSpecificationListByProductId?productId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveProductSpecification = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'ProductConfiguration/SaveProductSpecification', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactivateProductSpec = async (Id,IsActive) => {
    debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ProductConfiguration/ActivateDeactivateProductSpec?productspecid=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}