import React, { useState, useRef, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Flex,
  Stack,
  HStack,
  Heading,
  Table,
  ButtonGroup,
  Tfoot,
  Box,
  TableContainer,
  TableCaption,
  Spacer,
  Button,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  Divider,
  Textarea,
} from "@chakra-ui/react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import * as ProductConfigService from "../../Services/Product/ProductConfigService";
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import useLoaderState from "../../Common/useLoaderState";
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import ContainerComponent from "../Container/ContainerComponent";
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from "../Modal/ModalComponent";
import * as CommonVariables from "../../Common/CommonVariables";
import FilePicker from "chakra-ui-file-picker";
import { getValue } from "@testing-library/user-event/dist/utils";
import JoditEditor from "jodit-react";
import LoadingSpinnerComponent from "../Loader/LoadingSpinnerComponent";

function ProductConfigurationComponent(props) {
  const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleProductConfigSubmit,
    register: registerProductConfig,
    setValue: setValueProductConfig,
    reset: resetProductConfig,
    formState: { errors: errorProductConfig },
  } = useForm({ mode: "onBlur" });
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isPinCodeOpen,
    onOpen: onPinCodeOpen,
    onClose: onPinCodeClose,
  } = useDisclosure();
  const [ProductConfigurationList, setProductConfiguration] = useState([]);
  const [IsLoading] = useLoaderState();
  const [SelectedProductConfiguration, setProductConfigurationId] = useState(0);
  const [Product_Id, SetProductId] = useState(0);
  const [HotProduct, SetIsHotProduct] = useState(false);
  const [ToSellingProduct, SetIsToSellingProduct] = useState(false);
  const [WebImage, setWebImage] = useState("");
  const [MobileImage, setMobileImage] = useState("");
  const [ThumbnailImage, setThumbnailImage] = useState("");

  const detaileditor = useRef("");
  const shorteditor = useRef("");
  const [detaildescription, setDetailDescription] = useState("");
  const [shortdescription, setShortDescription] = useState("");

  const shortdescrip_config = {
    //Short_DetailDescription
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 300,
  };
  const detaildescrip_config = {
    //Product_DetailDescription
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 300,
  };
  useEffect(() => {
    SetProductId(props.Product_Id);
    if (props.Product_Id > 0) {
      GetProductConfigByProductId();
    }
  }, []);

  const GetProductConfigByProductId = () => {
    SetProductId(props.Product_Id);
    ProductConfigService.GetProductConfigurationByProductId(props.Product_Id)
      .then((data) => {
        if (data.data.IsSuccess) {
          console.log(data.data.Data);
          resetProductConfig(data.data.Data);
          setValueProductConfig(
            "Is_HotProduct",
            data.data.Data.Is_HotProduct ? "Yes" : "No"
          );
          setValueProductConfig(
            "Is_ToSellingProduct",
            data.data.Data.Is_ToSellingProduct ? "Yes" : "No"
          );
          setValueProductConfig(
            "Is_ShowInMenu",
            data.data.Data.Is_ShowInMenu ? "Yes" : "No"
          );

          setDetailDescription(data.data.Data.Product_Detail_Description);
          detaileditor.current.value =
            data.data.Data.Product_Detail_Description;
          setShortDescription(data.data.Data.Product_Short_Description);
          shorteditor.current.value = data.data.Data.Product_Short_Description;

          if (
            data.data.Data.Product_Thumbnail != "" &&
            data.data.Data.Product_Mobile_Banner != "" &&
            data.data.Data.Product_Web_Banner != ""
          ) {
            setWebImage(data.data.Data.Product_Web_Banner);
            setMobileImage(data.data.Data.Product_Mobile_Banner);
            setThumbnailImage(data.data.Data.Product_Thumbnail);
          }
        } else {
          setValueProductConfig("Id", 0);
        }
      })
      .catch((error) => {
        setValueProductConfig("Id", 0);
      });
  };

  const SaveProductConfig = (value) => {
    debugger;
    value.Product_Id = props.Product_Id;
    value.Is_ToSellingProduct =
      value.Is_ToSellingProduct == "Yes" ? true : false;
    value.Is_HotProduct = value.Is_HotProduct == "Yes" ? true : false;
    value.Is_ShowInMenu = value.Is_ShowInMenu == "Yes" ? true : false;

    if (shorteditor.current.value == "" || shorteditor.current.value == null) {
      toast.error("Product Short Description is mandatory!");
      return false;
    }

    if (
      detaileditor.current.value == "" ||
      detaileditor.current.value == null
    ) {
      toast.error("Product Detail Description is mandatory!");
      return false;
    }

    value.Product_Detail_Description = detaileditor.current.value;
    value.Product_Short_Description = shorteditor.current.value;
    ProductConfigService.SaveProductConfiguration(value)
      .then((data) => {
        if (data.data.IsSuccess) {
          toast.success("Config Save");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {});
  };

  const EditProductConfiguration = (id) => {
    ProductConfigService.GetProductConfigurationById(id)
      .then((data) => {
        if (data.data.IsSuccess) {
          reset(data.data.Data);
          onAddOpen();
        }
      })
      .catch((error) => {});
  };

  const ThumbnailFileUploadToS3 = (fileList) => {
    debugger;
    const file = fileList[0];
    if (file.size <= 1024 * 1024) {
      if (fileList != null && fileList.length > 0) {
        var formdata = new FormData();
        formdata.append("Myfile", fileList[0]);

        AttachmentService.FileUploadToS3(formdata)
          .then((response) => {
            if (response.data.IsSuccess) {
              toast.success(response.data.ResponseMessage);
              setValueProductConfig("Product_Thumbnail", response.data.Data);
            } else {
              setValueProductConfig("Product_Thumbnail", "");
              // setError("Product_Thumbnail", {
              //   types: { required: "Product_Thumbnail is Mandatory" },
              // });
              toast.error(response.data.ResponseMessage);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            // setError("Product_Thumbnail", {
            //   types: { required: "Product_Thumbnail is Mandatory" },
            // });
          });
      } else {
        toast.error("Please choose Image First!");
        // setError("Product_Thumbnail", {
        //   types: { required: "Product_Thumbnail is Mandatory" },
        // });
      }
    } else {
      toast.error("File size exceeds 1MB.");
    }
  };

  const MobileFileUploadToS3 = (fileList) => {
    const file = fileList[0];
    if (file.size <= 1024 * 1024) {
      if (fileList != null && fileList.length > 0) {
        var formdata = new FormData();
        formdata.append("Myfile", fileList[0]);

        AttachmentService.FileUploadToS3(formdata)
          .then((response) => {
            if (response.data.IsSuccess) {
              toast.success(response.data.ResponseMessage);
              setValueProductConfig(
                "Product_Mobile_Banner",
                response.data.Data
              );
            } else {
              setValueProductConfig("Product_Mobile_Banner", "");
              // setError("Product_Mobile_Banner", {
              //   types: { required: "Product_Mobile_Banner is Mandatory" },
              // });
              toast.error(response.data.ResponseMessage);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            // setError("Product_Mobile_Banner", {
            //   types: { required: "Product_Mobile_Banner is Mandatory" },
            // });
          });
      } else {
        toast.error("Please choose Image First!");
        // setError("Product_Mobile_Banner", {
        //   types: { required: "Product_Mobile_Banner is Mandatory" },
        // });
      }
    } else {
      toast.error("File size exceeds 1MB.");
    }
  };

  const WebFileUploadToS3 = (fileList) => {
    const file = fileList[0];
    if (file.size <= 1024 * 1024) {
      if (fileList != null && fileList.length > 0) {
        var formdata = new FormData();
        formdata.append("Myfile", fileList[0]);

        AttachmentService.FileUploadToS3(formdata)
          .then((response) => {
            if (response.data.IsSuccess) {
              toast.success(response.data.ResponseMessage);
              setValueProductConfig("Product_Web_Banner", response.data.Data);
            } else {
              setValueProductConfig("Product_Web_Banner", "");
              // setError("Product_Web_Banner", {
              //   types: { required: "Product_Web_Banner is Mandatory" },
              // });
              toast.error(response.data.ResponseMessage);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            // setError("Product_Web_Banner", {
            //   types: { required: "Product_Web_Banner is Mandatory" },
            // });
          });
      } else {
        toast.error("Please choose Image First!");
        // setError("Product_Web_Banner", {
        //   types: { required: "Product_Web_Banner is Mandatory" },
        // });
      }
    } else {
      toast.error("File size exceeds 1MB.");
    }
  };
  return (
    <Stack spacing={4}>
      <LoadingSpinnerComponent></LoadingSpinnerComponent>
      <Box as="form">
        <Input
          hidden
          {...registerProductConfig("Id", { setValueAs: (v) => parseInt(v) })}
        />
        <HStack spacing={"2"}>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errorProductConfig.Product_Thumbnail}>
              <FormLabel>Product_Thumbnail</FormLabel>
              <FilePicker
                onFileChange={(fileList) => {
                  ThumbnailFileUploadToS3(fileList);
                }}
                placeholder="Product_Thumbnail"
                clearButtonLabel="Clear"
                multipleFiles={false}
                accept="application/png"
                hideClearButton={false}
              />
              <FormErrorMessage>
                {errorProductConfig.Product_Thumbnail &&
                  errorProductConfig.Product_Thumbnail.message}
              </FormErrorMessage>
            </FormControl>
            <span className="NoteImageSize"> Note : Size 490*490</span>
          </Box>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errorProductConfig.Product_Web_Banner}>
              <FormLabel>Product_Web_Banner</FormLabel>
              {/* {WebImage != "" ?? <img src={WebImage} width='50px' height='50px' />} */}
              <FilePicker
                onFileChange={(fileList) => {
                  WebFileUploadToS3(fileList);
                }}
                placeholder="Product_Web_Banner"
                clearButtonLabel="Clear"
                multipleFiles={false}
                accept="application/png"
                hideClearButton={false}
              />
              <FormErrorMessage>
                {errorProductConfig.Product_Web_Banner &&
                  errorProductConfig.Product_Web_Banner.message}
              </FormErrorMessage>
            </FormControl>
            <span className="NoteImageSize"> Note : Size 0*0</span>
          </Box>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errorProductConfig.Product_Mobile_Banner}>
              <FormLabel>Product_Mobile_Banner</FormLabel>

              <FilePicker
                onFileChange={(fileList) => {
                  MobileFileUploadToS3(fileList);
                }}
                placeholder="Product_Mobile_Banner"
                clearButtonLabel="Clear"
                multipleFiles={false}
                accept="application/png"
                hideClearButton={false}
              />
              <FormErrorMessage>
                {errorProductConfig.Product_Mobile_Banner &&
                  errorProductConfig.Product_Mobile_Banner.message}
              </FormErrorMessage>
            </FormControl>
            <span className="NoteImageSize"> Note : Size 0*0</span>
          </Box>
        </HStack>

        <HStack
          spacing={"2"}
          paddingTop={"5"}>
          <Box width={"full"}>
            <FormControl isInvalid={errorProductConfig.Video_URL}>
              <FormLabel>Video URL</FormLabel>
              <Input
                type="text"
                placeholder="Video_URL"
                {...registerProductConfig("Video_URL")}
              />
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl
              isRequired
              isInvalid={errorProductConfig.Product_Rating}>
              <FormLabel>Product_Rating</FormLabel>
              <Input
                type="number"
                placeholder="Product_Rating"
                {...registerProductConfig("Product_Rating", {
                  required: "Product Rating is Mandatory",
                  setValueAs: (v) => parseFloat(v),
                })}
              />
              <FormErrorMessage>
                {errorProductConfig.Product_Rating &&
                  errorProductConfig.Product_Rating.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Custom Rating Message</FormLabel>
              <Input
                type="text"
                placeholder="Custom_Rating_Message"
                {...registerProductConfig("Custom_Rating_Message")}
              />
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Is HotProduct</FormLabel>
              {/* <Checkbox colorScheme='green'  {...registerProductConfig("Is_HotProduct")}>
                            </Checkbox> */}
              <Select
                className="form-control"
                {...registerProductConfig("Is_HotProduct")}>
                <option value="">Select Type</option>
                {CommonVariables.ProductConfType.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box width={"full"}>
            <FormControl>
              <FormLabel>Is TopSellingProduct</FormLabel>
              <Select
                className="form-control"
                {...registerProductConfig("Is_ToSellingProduct")}>
                <option value="">Select Type</option>
                {CommonVariables.ProductConfType.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
              {/* <Checkbox colorScheme='green' {...registerProductConfig("Is_ToSellingProduct")}>                            
                            </Checkbox> */}
            </FormControl>
          </Box>
          <Box width={"full"}>
            <FormControl>
              <FormLabel>Is ShowInMenu</FormLabel>
              {/* <Checkbox colorScheme='green'  {...registerProductConfig("Is_HotProduct")}>
                            </Checkbox> */}
              <Select
                className="form-control"
                {...registerProductConfig("Is_ShowInMenu")}>
                <option value="">Select Type</option>
                {CommonVariables.ProductConfType.map((data, index) => {
                  return (
                    <option
                      value={data.Value}
                      key={index}>
                      {data.Key}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </HStack>

        {/* <HStack spacing={"2"} paddingTop={"5"}>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errorProductConfig.Product_Short_Description}>
                            <FormLabel>Product_Short_Description</FormLabel>
                            <Textarea placeholder='Product_Short_Description' {...registerProductConfig("Product_Short_Description", { required: "Field is Mandatory" })} />
                            <FormErrorMessage>
                                {errorProductConfig.Product_Short_Description && errorProductConfig.Product_Short_Description.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box width={"full"}>
                        <FormControl isRequired isInvalid={errorProductConfig.Product_Detail_Description}>
                            <FormLabel>Product_Detail_Description</FormLabel>
                            <Textarea placeholder='Product_Detail_Description' {...registerProductConfig("Product_Detail_Description", { required: "Field is Mandatory" })} />
                            <FormErrorMessage>
                                {errorProductConfig.Product_Detail_Description && errorProductConfig.Product_Detail_Description.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                </HStack> */}

        <HStack
          spacing={"2"}
          paddingTop={"5"}>
          <Box width={"full"}>
            <FormControl isRequired>
              <FormLabel>Product_Short_Description</FormLabel>
              <JoditEditor
                ref={shorteditor}
                value={shortdescription}
                config={shortdescrip_config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newShortContent) =>
                  setShortDescription(newShortContent)
                } // preferred to use only this option to update the content for performance reasons
                onChange={(newShortContent) => {}}
              />
            </FormControl>
          </Box>
        </HStack>
        <HStack
          spacing={"2"}
          paddingTop={"5"}>
          <Box width={"full"}>
            <FormControl isRequired>
              <FormLabel>Product_Detail_Description</FormLabel>
              <JoditEditor
                ref={detaileditor}
                value={detaildescription}
                config={detaildescrip_config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newDetailContent) =>
                  setDetailDescription(newDetailContent)
                } // preferred to use only this option to update the content for performance reasons
                onChange={(newDetailContent) => {}}
              />
            </FormControl>
          </Box>
        </HStack>
        <Checkbox
          hidden
          colorScheme="green"
          {...registerProductConfig("Is_Active")}>
          {" "}
        </Checkbox>

        <HStack
          spacing={"2"}
          paddingTop={"5"}
          float="right">
          <ButtonGroup
            variant="outline"
            spacing="4">
            <Button
              type="submit"
              disabled={IsLoading}
              onClick={handleProductConfigSubmit(SaveProductConfig)}
              colorScheme="whatsapp">
              Save
            </Button>
          </ButtonGroup>
        </HStack>
      </Box>
    </Stack>
  );
}

export default ProductConfigurationComponent;
