import axios from "axios";
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const FileUploadToS3 = async (image) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Attachment/UploadImage', image, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UploadStock = async (file) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Stock/UploadStock', file, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const UploadLogo = async (image) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Attachment/UploadLogo', image, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
    
}

export const UpdateWebsiteColor = async (primaryHexCode,secondaryHexCode,userId) => {
    debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `Attachment/UpdateWebsiteColor?primaryColor=${primaryHexCode}&secondaryColor=${secondaryHexCode}&userId=${userId}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ResetLogo = async () => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + "Attachment/ResetLogo", CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
