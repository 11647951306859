import React, { useState, useEffect, useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { HStack, FormControl, FormLabel, FormErrorMessage, TableContainer, Input, ButtonGroup, Box, Checkbox, Modal, Avatar, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import useLoaderState from '../../Common/useLoaderState';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import ContainerComponent from '../Container/ContainerComponent';
import { FaCopyright, FaPlus } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi"
import ModalComponent from '../Modal/ModalComponent';
import * as PincodeService from '../../Services/Pincode/PincodeService'
import { Table } from "react-chakra-pagination";
import { Tooltip } from '@chakra-ui/react'

function PincodeListComponent() {
    const { handleSubmit, reset, setValue, register, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [PincodeList, setPincodeList] = useState([]);
    const [selectedPincodeId, setPincodeId] = useState(0);
    const [page, setPage] = useState(1);
    const selectedPincode = useRef();

    useEffect(() => {
        LoadPincode();
    }, [])

    const tableData = PincodeList.map((pincode, index) => ({
        "key": "index",
        Id: pincode.Id,
        Pincode: pincode.Pincode,
        Delivery_TAT: pincode.Delivery_TAT +"|"+ pincode.XpressBees_TAT,
        Delivery_Partner: pincode.Delivery_Partner,
        City: pincode.City,
        State: pincode.State,
        Is_Active:
            (
                <Checkbox colorScheme={pincode.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                </Checkbox>
            ),
        Action: (
            <ButtonGroup variant={"outline"} spacing="1">
                   <Tooltip label='Edit Pincode' placement='auto-start'>
                <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditPincode(pincode.Id)} >
                    <EditIcon />
                </Button>
                </Tooltip>
                <Tooltip label='Delete Pincode' placement='auto-start'>
                <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeletePincode (pincode.Id,pincode.Is_Active)} >
                    <DeleteIcon />
                </Button>
                </Tooltip>
            </ButtonGroup>
        )
    }));

    const tableColumns = [
        {
            Header: "Id",
            accessor: "Id"
        },
        {
            Header: "Pincode",
            accessor: "Pincode"
        },
        {
            Header: "Delivery Partner",
            accessor: "Delivery_Partner"
        },
        {
            Header: "Delivery(in Days)",
            accessor: "Delivery_TAT"
        },
        {
            Header: "City",
            accessor: "City"
        },
        {
            Header: "State",
            accessor: "State"
        },
        {
            Header: "IsActive",
            accessor: "Is_Active"
        },
        {
            Header: "Action",
            accessor: "Action"
        }
    ];

    const LoadPincode = () => {
        PincodeService.GetPincodeList(selectedPincode.current.value).then(data => {
            if (data.data.IsSuccess) {
                setPincodeList(data.data.Data);
            } else {
                setPincodeList([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong.");
        })
    }

    const SearchPincode = () => {
        PincodeService.GetPincodeList(selectedPincode.current.value).then(data => {
            if (data.data.IsSuccess) {
                setPincodeList(data.data.Data);
            } else {
                setPincodeList([]);
            }
        }).catch(error => {
            toast.error("Somthing went wrong.");
        })
    }

    const AddCategory = () => {
        reset({});
        setPincodeId(0);
        onAddOpen();
    }

    const SavePincode = (value) => {
        if (selectedPincodeId <= 0) {
            PincodeService.SavePincode(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Pincode created.");
                    onAddClose();
                    LoadPincode();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedPincodeId;
            PincodeService.UpdatePincode(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Pincode update.");
                    onAddClose();
                    LoadPincode();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }

    const EditPincode = (id) => {
        setPincodeId(id);
        reset({});
        PincodeService.GetPincodeDetail(id).then(data => {
            //debugger;
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            }
            else
                toast.error("Somthing went wrong.")
        }).catch(error => {
            toast.error("Somthing went wrong.")
        })
    }

    const DeletePincode = (id, IsActive) => {
        var action = window.confirm(IsActive == true ?'Do you want to delete PinCode?' : "Do You Want to Activate the PinCode?");
    
        if (action == true) {
            PincodeService.ActivateDeactivatePinCode(id, !IsActive).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadPincode();
                }
            }).catch(error => {

            })
        }
    }

    return (
        <ContainerComponent Title="Pincode" Icon={<FiMapPin />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Pincode" AddNewAction={AddCategory}>
            <HStack paddingLeft={5}>
                <Box width={"full"} paddingTop={5}>
                    <FormControl >
                        <Input placeholder='Enter Pincode' ref={selectedPincode} />
                    </FormControl>
                </Box>
                <Box width={"full"} paddingBottom={3}>
                    <Button colorScheme='red' onClick={() => SearchPincode()} marginTop={8}>
                        Search
                    </Button>
                </Box>
            </HStack>
            <>
                <Table
                    emptyData={{
                        text: "No Data Available."
                    }}
                    colorScheme="blue"
                    totalRegisters={PincodeList.length}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    columns={tableColumns}
                    data={tableData}
                />

                {/* <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Pincode Id</Th>
                            <Th>Pincode</Th>
                            <Th>City</Th>
                            <Th>State</Th>
                            <Th>Is Active</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {PincodeList.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Pincode}</Td>
                                <Td>{data.City}</Td>
                                <Td>{data.State}</Td>
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                    <ButtonGroup variant={"outline"} spacing="1">
                                        <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditPincode(data.Id)}>
                                            <EditIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {PincodeList.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table> */}
            </>
            <ModalComponent Title="Add/Update Pincode" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SavePincode)}>
                <FormControl isRequired isInvalid={errors.Pincode}>
                    <FormLabel>Pincode</FormLabel>
                    <Input placeholder='Pincode' {...register("Pincode", { required: "Pincode is Mandatory" })} />
                    <FormErrorMessage>
                        {errors.Pincode && errors.Pincode.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.City}>
                    <FormLabel>City</FormLabel>
                    <Input placeholder='City' {...register("City", { required: "City is Mandatory" })} />
                    <FormErrorMessage>
                        {errors.City && errors.City.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.City}>
                    <FormLabel>State</FormLabel>
                    <Input placeholder='State' {...register("State", { required: "State is Mandatory" })} />
                    <FormErrorMessage>
                        {errors.State && errors.State.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl >
                    <FormLabel>Expected Delivery(in Days)</FormLabel>
                    <Input placeholder='Expected Delivery' {...register("Delivery_TAT" , {setValueAs: (v) => parseInt(v) })} />
                </FormControl>
                {
                    selectedPincodeId > 0 ? (
                        <FormControl isRequired isInvalid={errors.Is_Active}>
                            <FormLabel>Is Active</FormLabel>
                            <Checkbox colorScheme='green' defaultChecked {...register("Is_Active", { required: "Selection is mandatory" })}>
                            </Checkbox>
                            <FormErrorMessage>
                                {errors.Is_Active && errors.Is_Active.message}
                            </FormErrorMessage>
                        </FormControl>
                    ) : <></>
                }
            </ModalComponent>
        </ContainerComponent >
    )
}

export default PincodeListComponent